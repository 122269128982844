import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import LocationOn from '@material-ui/icons/LocationOn';
import CircularProgress from '@material-ui/core/CircularProgress';

import { OuterWrapper, InnerWrapper } from './styledComponents';
import { Colors } from './../../../../aquaplot/colors';

const StyledButton = styled(IconButton)`
  && {
    padding: 0px;
    height: 36px;
    width: 36px;
    position: absolute;
    bottom: 0px;
    left: -18px;
  }
`

const StyledIcon = styled(LocationOn)`
  && {
    font-size: 36px;
    color: ${({customcolor}) => customcolor};
  }
`

const BaseMarkerPure = ({ 
    backgroundColor, 
    color, 
    Icon, 
    onClick, 
    number, 
    loading 
}) => {

    return (
        <OuterWrapper>
            <StyledButton
                onClickCapture={onClick}
            >
                <StyledIcon customcolor={backgroundColor || Colors.aqp_deep_sea} />
            </StyledButton>
            <InnerWrapper 
                backgroundColor={backgroundColor} 
                color={color}
            >
            { Icon && <Icon className="styledIcon"/> }
            { number &&  <span>{number}</span> }
            { loading && <CircularProgress className="styledIcon" size={14} thickness={3} /> }
            </InnerWrapper>
        </OuterWrapper>
    );
}

export default BaseMarkerPure;