import axios from 'axios';
import { functionWithApiEndpoints } from './../store/withApiEndpoints';

const axiosConfig = {
  mode: 'no-cors',
  withCredentials: true,
  // credentials: 'same-origin',
};
// axios.defaults.baseURL = Const.baseUrl;

export const getUsers = functionWithApiEndpoints(({accountEndpoint}) => {
  const url = `${accountEndpoint}/company/users`;
  return axios
    .get(url, axiosConfig)
    .then(res => res.data)
    .catch(err => {
      throw err;
    });
});

export const addMember = functionWithApiEndpoints(({ accountEndpoint, memberObj, primaryEmail }) => {
  const url = `${accountEndpoint}/company/${primaryEmail}`;
  return axios
    .post(url, memberObj, axiosConfig)
    .then(res => {
      if (res.data.status === 'ok') {
        return res.data.users;
      } else {
        throw res.data.reason;
      }
    })
    .catch(err => {
      throw err;
    });
});

export const deleteMembers = functionWithApiEndpoints(({ accountEndpoint, members }) => {
  let deleteMemberPromises = [];
  members.forEach(member => {
    const url = `${accountEndpoint}/company/${member.primary_email}?role=${member.role}`;
    deleteMemberPromises.push(axios.delete(url, axiosConfig));
  });
  return Promise.all(deleteMemberPromises)
    .then(res => {
      if (res[0].data.status === 'ok') {
        return res[res.length - 1].data.users;
      } else {
        throw res[res.length - 1].data.reason;
      }
    })
    .catch(err => {
      if (process.env.REACT_APP_SHOW_DEBUG === 'true') console.log(err);
      throw err;
    });
});

export const getCompanyProfile = functionWithApiEndpoints(({ accountEndpoint }) => {
  const url = `${accountEndpoint}/company/profile`;
  return axios
    .get(url, axiosConfig)
    .then(res => res.data )
    .catch(err => {
      throw err;
    });
});

export const addCompanyProfile = functionWithApiEndpoints(({ accountEndpoint, companyInfo }) => {
  //console.log(companyInfo)
  const url = `${accountEndpoint}/company/profile`;
  return axios
    .post(url, companyInfo, axiosConfig)
    .then(res => {
      if (res.data.status === 'error') {
        throw res.data.reason;
      }
    })
    .catch(err => {
      throw err;
    });
});

export const getCountries = functionWithApiEndpoints(({ accountEndpoint }) => {
  const url = `${accountEndpoint}/countries`;
  return axios
    .get(url, axiosConfig)
    .then(res => {
      if (res.data.status === 'ok') {
        return res.data.countries;
      } else {
        throw res.data.reason;
      }
    })
    .catch(err => {
      throw err;
    });
});

export const contactUs = functionWithApiEndpoints(({ accountEndpoint, title, comment }) => {
  const url = `${accountEndpoint}/contact_message`;
  const payload = {
    title: title,
    comment: comment,
  };
  return axios.post(url, payload, axiosConfig);
});
