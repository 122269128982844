import React from 'react';
import Grid from '@material-ui/core/Grid';

import {
    StyledGrid
} from './styledComponents';

const Pure = ({
    data
}) => {
    return (
        <React.Fragment>
            <Grid container direction={'row'}>
                <Grid item xs={4} container direction={'column'} wrap="nowrap" alignItems="flex-start">
                    {
                        Object.keys(data).map((key, idx) => (
                            <StyledGrid key={`key-${idx}`} item xs={12}>
                                { key }
                            </StyledGrid>
                        ))
                    }
                </Grid>
                <Grid item xs={8} container direction={'column'} wrap="nowrap" alignItems="flex-end">
                    {
                        Object.keys(data).map((key, idx) => (
                            <StyledGrid key={`val-${idx}`} item xs={12}>
                                { data[key] }
                            </StyledGrid>
                        ))
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default Pure;