import React from 'react';
import SymbolIcon from './../../SymbolIcon';
import {
    StyledSelect,
    Wrapper,
    IconWrapper
} from './styledComponents';

const TypeFieldPure = ({
    typeoptions,
    type,
    onTypeChange,
    typeLabel,
    typeStr,
    typeObj
}) => {
    // console.log(type)
    return (
        <Wrapper>
            <StyledSelect
                name="waypoint-type-select"
                value={typeObj}
                onChange={onTypeChange}
                multi={false}
                isClearable
                options={typeoptions}
                placeholder="Set Type..."
            />
            <IconWrapper>
                <SymbolIcon
                    tooltip={typeLabel}
                    iconClassName={'aqp-type-icon-' + typeStr}
                />
            </IconWrapper>
        </Wrapper>
    );
}

export default TypeFieldPure;