import React from 'react';
import Grid from '@material-ui/core/Grid';
import KeyValuesList from './KeyValuesList';
import Nodes from './Nodes';
import MatrixStatus from './../MatrixStatus';

import {
    StyledOuterGrid,
    StyledPaper
} from './styledComponents';

const Pure = ({
    matrix
}) => {
    return (
        <StyledOuterGrid spacing={2} container direction={'column'}>
            <Grid item xs={12}>
                <StyledPaper>
                    <KeyValuesList 
                        data={{
                            Name: matrix.name,
                            'Unique ID': matrix.id,
                            'Created At': matrix.createdAt ? (new Date(matrix.createdAt)).toDateString() : 'Pending...'
                        }} 
                    />
                </StyledPaper>
            </Grid>
            <Grid item spacing={2} container direction={'row'}>
                <Grid item xs={12} md={8}>
                <StyledPaper>
                    <Nodes data={matrix.nodes || []} />
                </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                <StyledPaper>
                    <MatrixStatus />
                </StyledPaper>
                </Grid>
            </Grid>
        </StyledOuterGrid>
    )
}

export default Pure;