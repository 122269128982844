import React from 'react';
import { StyledAction } from './../styledComponents';

const Pure = ({
    variant,
    label,
    icon,
    to,
    wrapper,
    component,
    Component,
    Wrapper,
    ...other
}) => {
    return (
        <Wrapper>
            <StyledAction 
                variant={variant}
                label={label} 
                icon={icon} 
                component={Component}
                to={to}
                {...other}
            />
        </Wrapper>
    )
}

export default Pure;