import { takeLatest, put, select, takeEvery } from 'redux-saga/effects';
import {
  requireFirebase,
  setUserDoc,
  anyAuth,
  firebaseAuth,
  ItineraryAdded,
  ItineraryRemoved,
  setHasFirebasePrivilege,
  requireBunkerPrices
} from './../../redux/actions';
import { aquaplotStore } from './../../index';
import firebaseConfig from '../../../config/firebase.json';
import { bootstrapCargo } from './cargo';
import { bootstrapVessels } from './vessels';
import { bootstrapFleets } from './fleets';
import { bootstrapLocations } from './locations';
import { bootstrapBunkerEx } from './bunker';
import { bootstrapMatrix } from './matrices';
import { bootstrapItinerariesMetadata } from './itinerariesMetadata';
import {
    addItinerary,
    removeItinerary
} from './itineraries';

function* reAuth(token){
    const firebase = yield import(/* webpackChunkName: "firebase-app" */ 'firebase/app');
    yield import('firebase/auth');
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(function() {
            return firebase.auth()
                .signInWithCustomToken(token)
                .then(() => {
                    if (process.env.REACT_APP_SHOW_DEBUG === 'true') console.log('signed in to firebase again')
                    aquaplotStore.dispatch({
                        type: firebaseAuth.SUCCEEDED,
                        payload: null, 
                    });
                });
        })
        .catch(function({code, message}) {
            console.log(code, message)
            put({
                type: requireFirebase.FAILED,
                payload: `${code} ${message}`, 
            });
        });
}

function* bootstrapFirebase(token) {
    if(!token){
        console.log('cannot bootstrap firebase without access token');
        yield put({
            type: requireFirebase.FAILED,
            payload: null, 
        });
        return;
    }
    const firebase = yield import(/* webpackChunkName: "firebase-app" */ 'firebase/app');
    yield import('firebase/firestore');
    yield import('firebase/auth');
    firebase.initializeApp(firebaseConfig);
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(function() {
            return firebase.auth().signInWithCustomToken(token)
                .then(() => {
                    if (process.env.REACT_APP_SHOW_DEBUG === 'true') console.log('signed in to firebase')
                    aquaplotStore.dispatch({
                        type: firebaseAuth.SUCCEEDED,
                        payload: null, 
                    });
                });
        })
        .catch(function({code, message}) {
            console.log(code, message)
            put({
                type: requireFirebase.FAILED,
                payload: `${code} ${message}`, 
            });
        });
    const db = firebase.firestore();
    db.settings({ timestampsInSnapshots: true });
    db.enablePersistence()
        .catch(function(err) {
            if (err.code === 'failed-precondition') {
                // Multiple tabs open, persistence can only be enabled
                // in one tab at a a time.
                // ...
            } else if (err.code === 'unimplemented') {
                // The current browser does not support all of the
                // features required to enable persistence
                // ...
            }
        });
    yield put({
        type: requireFirebase.SUCCEEDED,
        payload: db, 
    });
}

const decodeJwtPayload = (jwt) => JSON.parse(window.atob(jwt.split('.')[1]))

function* handleFirebase({payload}){
    // fetch active token from state
    const { aqpToken, db } = yield select(state => 
        Object.assign({}, {
            aqpToken: state.user.aqpToken,
            db: state.firestore.db
        })
    );
    if(!aqpToken){
        console.log('cannot initialize db. not logged in');
        return;
    }
    // check if token contains a firebase token and id
    if(!window || !window.atob){
        console.log('browser/environment not supported');
        yield put({
            type: requireFirebase.FAILED,
            payload: 'browser/environment not supported', 
        });
        return;
    }
    const {
        firebase_uuid,
        firebase_token
    } = decodeJwtPayload(aqpToken);

    if(!firebase_uuid || !firebase_token){
        if (process.env.REACT_APP_SHOW_DEBUG === 'true') console.log('no firebase')
        yield put({
            type: requireFirebase.FAILED,
            payload: 'no firebase', 
        });
        return;
    }

    yield put(setHasFirebasePrivilege.call(true, firebase_uuid))

    // if token exists, check if firebase db has been bootstrapped. do so if no
    if(!db){
        // yield put(updateSnackbarMessage.call('Initializing database connection...'));
        if (process.env.REACT_APP_SHOW_DEBUG === 'true') console.log('bootstrapping firebase')
        yield bootstrapFirebase(firebase_token);
        // yield put(updateSnackbarMessage.call('Initializing database connection... DONE'));
    }
    else{
        //re-authorize
        if (process.env.REACT_APP_SHOW_DEBUG === 'true') console.log('reauth')
        yield reAuth(firebase_token)
    }

    
}

export default function* firebaseSaga() {
  yield takeLatest(anyAuth.SUCCEEDED, handleFirebase);
}

function* bootstrapData() {
    const { db: database, userDoc, aqpToken } = yield select(state => 
        Object.assign({}, {
            db: state.firestore.db,
            userDoc: state.firestore.userDoc,
            aqpToken: state.user.aqpToken,
        })
    );

    if(!database){
        //this should never happen but let's be cautious
        console.log('somehow db not available even after bootstrapping')
        yield put({
            type: requireFirebase.FAILED,
            payload: 'somehow db not available even after bootstrapping', 
        });
        return;
    }

    const {
        firebase_uuid,
    } = decodeJwtPayload(aqpToken);

    // initialize data listeners
    if(!userDoc){
        // yield put(updateSnackbarMessage.call('Loading data...'));
        const userDocRef = database.collection('users').doc(firebase_uuid);

        yield put(setUserDoc.call(userDocRef));

        yield bootstrapLocations(userDocRef);
        yield bootstrapItinerariesMetadata(userDocRef);
        yield bootstrapVessels(userDocRef);
        yield bootstrapFleets(userDocRef);
        yield bootstrapCargo(userDocRef);
        yield bootstrapBunkerEx();
        // yield put(updateSnackbarMessage.call('Loading data... DONE'));

        yield bootstrapMatrix(database, firebase_uuid);
    }   

}

export function* bootstrapDataSaga() {
    yield takeLatest(firebaseAuth.SUCCEEDED, bootstrapData)
}


export function* itineraryAddedSaga() {
    yield takeEvery(ItineraryAdded.REQUESTED, addItinerary);
}

export function* itineraryRemovedSaga() {
    yield takeEvery(ItineraryRemoved.REQUESTED, removeItinerary);
}

export function* bootstrapBunkerExSaga() {
    yield takeLatest(requireBunkerPrices.REQUESTED, bootstrapBunkerEx);
}