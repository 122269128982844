import { ReduxAction } from './helper';

export const updateSnackbarMessage = new ReduxAction('STATUS_SNACKBAR_MESSAGE_UPDATE', (msg, {
    autohide,
    with_close_action,
    with_confirm_to_close,
    with_page_refresh_to_close,
    installingWorker
  } = {}) => Object.assign(
    {
      snack_message: msg,
      snack_open: true,
    },
    autohide ? { snack_autohide: autohide } : {},
    with_close_action ? { snack_with_close_action: with_close_action } : {},
    with_confirm_to_close ? { snack_with_confirm_to_close: with_confirm_to_close } : {},
    with_page_refresh_to_close ? { snack_with_page_refresh_to_close: with_page_refresh_to_close } : {},
    installingWorker ? { snack_installingWorker: installingWorker } : {}
  ));
  
  export const clearSnackbar = new ReduxAction('STATUS_SNACKBAR_MESSAGE_CLEAR', () => (
    {
      snack_message: '',
      snack_open: false,
      snack_autohide: 2000,
      snack_with_close_action: false,
      snack_with_confirm_to_close: false,
      snack_with_page_refresh_to_close: false,
      snack_installingWorker: null
    }
  ));