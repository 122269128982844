import {
  FETCH_USER_CARGO_REQUESTED,
  FETCH_USER_CARGO_SUCCEEDED,
  FETCH_USER_CARGO_FAILED,
} from '../../types';
import groupBy from 'lodash/groupBy';

const initialCargos = {
  loading: true,
  cargos: {},
  groupedCargos: {},
  listenerInstantiated: false,
  error: undefined,
};

const groupCargos = cargos => {
  let groupedCargos = {};
  groupedCargos['all'] = Object.keys(cargos).map(id => cargos[id]);
  groupedCargos = Object.assign(groupedCargos, groupBy(cargos, 'account'));
  return groupedCargos;
};

export function userCargosReducer(state = initialCargos, action) {
  switch (action.type) {
    case FETCH_USER_CARGO_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_CARGO_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: undefined,
        listenerInstantiated: true,
        cargos: action.payload,
        groupedCargos: groupCargos(action.payload),
      };
    case FETCH_USER_CARGO_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
