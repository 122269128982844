import React, { Component } from 'react';
import { connect } from 'react-redux';
import MapButton from './MapButton';
import DeleteIcon from '@material-ui/icons/DeleteSweep';

import { clearWaypoints } from '../../../store/redux/actions';

class ClearMapButton extends Component {
  render() {
    const { clearWaypoints, color } = this.props;

    return (
      <MapButton
        icon={<DeleteIcon />}
        callback={clearWaypoints}
        tooltip={"Clear Waypoints"}
        color={color}
      />
    );
  }
}


const mapDispatchToProps = dispatch => {
  return {
    clearWaypoints: () => {
        dispatch(clearWaypoints.call());
      },
  };
};

const ClearMapButtonContainer = connect(
  null,
  mapDispatchToProps
)(ClearMapButton);

export default ClearMapButtonContainer;
