import React from 'react';

import {
    NoContentDiv,
    IllustrationWrapper
  } from '../../styledComponents';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { NoData } from './../../../../illustrations';
import { StyledMessage } from './styledComponents';

const Pure = ({msg}) => {
    return (
        <NoContentDiv>
            <IllustrationWrapper>
                <NoData />
            </IllustrationWrapper>
            <StyledMessage>Register your company to access this functionality.</StyledMessage>
            <Button 
                variant="contained" 
                color="primary" 
                component={Link} 
                to="/app/account/billing?addProfile=true"
            >
                Register
            </Button>
        </NoContentDiv>
    )
}

export default Pure;