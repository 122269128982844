import {
    compose
} from 'recompose';
import { connect } from 'react-redux'; 
import {
    exitCustomAreaMode
} from './../../../../../../store/redux/actions';

const mapStateToProps = state => {
    return {
        active: false
    }
}

const mapDispatchToProps = dispatch => {
    return {
        exitCustomAreaMode: () => dispatch(exitCustomAreaMode.call())
    }
}

const Handlers = compose(
    connect(mapStateToProps, mapDispatchToProps)
)

export default Handlers;