import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

const StyledButton = styled(IconButton)`
    && {
    }
`

const StyledCloseIcon = styled(Close)`
    && {
        color: white;
        font-size: 18px;
    }
`

const CloseButton = ({
    destination,
    onClick,
    ...rest
}) => {
    if(destination){
        return (
            <Link to={destination} {...rest} >
                <StyledCloseIcon />
            </Link>
        );
    }
    else{
        return (
            <StyledButton onClick={onClick} {...rest} >
                <StyledCloseIcon />
            </StyledButton>
        );
    }
}

export default CloseButton;