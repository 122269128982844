import React from 'react';

import Typography from '@material-ui/core/Typography';
import { BlankCanvas } from './../../illustrations';

import { NoContentDiv, IllustrationWrapper } from './styledComponents';

const NoCompany = ({ message }) => (
  <NoContentDiv>
    <IllustrationWrapper>
      <BlankCanvas />
    </IllustrationWrapper>
    <Typography>{message}</Typography>
  </NoContentDiv>
);

export default NoCompany;
