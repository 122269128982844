import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Select from 'react-select';
import { StyledPlaceHolder } from './style';
import InputAdornment from '@material-ui/core/InputAdornment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

function handleAddOption(selectProps, setValue) {
  if (selectProps.inputValue) {
    const valueObj = {
      label: selectProps.inputValue,
      value: selectProps.inputValue,
    };
    if (selectProps.isMulti) {
      let newValues = selectProps.value;
      newValues.length > 0
        ? newValues.push(valueObj)
        : (newValues = [valueObj]);
      setValue(newValues);
    } else {
      setValue(valueObj);
    }
  }
}

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      {...props.innerProps}
      onClick={() =>
        props.selectProps.creatable && props.selectProps.inputValue
          ? !props.selectProps.handleCreate
            ? handleAddOption(props.selectProps, props.setValue)
            : props.selectProps.handleCreate(props.selectProps, props.setValue)
          : null
      }
    >
      {props.selectProps.creatable
        ? props.selectProps.inputValue.length > 2
          ? `Add ${props.selectProps.inputValue}`
          : 'Enter 2 or more characters to search'
        : props.selectProps.inputValue.length < 2
        ? 'Enter 2 or more characters to search'
        : 'No result found'}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

const DropdownIndicator = props => {
  const {
    selectProps: { isDisabled },
  } = props;
  return isDisabled ? null : <ArrowDropDownIcon color="primary" />;
};

function Control(props) {
  return (
    <TextField
      fullWidth
      error={props.selectProps.error ? true : false}
      helperText={props.selectProps.error ? props.selectProps.error : null}
      InputProps={{
        disableUnderline: props.selectProps.disableUnderline ? true : false,
        inputComponent,
        inputProps: {
          ref: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
        endAdornment: props.selectProps.adornment ? (
          <InputAdornment position={'end'}>
            {props.selectProps.adornment.content}
          </InputAdornment>
        ) : (
          false
        ),
      }}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <StyledPlaceHolder
      color="textSecondary"
      {...props.innerProps}
    >
      {props.children}
    </StyledPlaceHolder>
  );
}

function SingleValue(props) {
  return (
    <Typography
      color="primary"
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div>
      {props.children}
    </div>
  );
}

const customStyles = {
  menuPortal: (provided, state) => ({
    ...provided,
    top: 'unset',
    left: 'unset',
  }),
};

const Components = {
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  ValueContainer,
  DropdownIndicator,
};

class SyncSelectComponent extends React.Component {
  render() {
    const {
      suggestions,
      label,
      input,
      required,
      creatable,
      meta: { error, touched },
      placeholder,
      handleCreate,
      adornment,
      disabled,
      disableUnderline,
      labelWidth,
    } = this.props;
    return (
      <Grid container alignItems="center" spacing={2}>
        {label && (
          <Grid
            item
            xs={labelWidth ? labelWidth : 5}
            md={labelWidth ? labelWidth : 4}
          >
            <InputLabel
              disabled={disabled}
              required={required}
              error={error && touched ? true : false}
            >
              {label}
            </InputLabel>
          </Grid>
        )}
        <Grid
          item
          xs={labelWidth ? (label ? 12 - labelWidth : 12) : 7}
          md={labelWidth ? (label ? 12 - labelWidth : 12) : 8}
        >
          <Select
            // menuPlacement="bottom"
            styles={customStyles}
            menuPosition="fixed"
            isDisabled={disabled}
            options={suggestions}
            components={Components}
            isSearchable
            creatable={creatable}
            isClearable
            value={input.value}
            onChange={input.onChange}
            placeholder={placeholder || ''}
            isMulti={false}
            error={touched && error ? error : undefined}
            handleCreate={handleCreate}
            adornment={adornment}
            disableUnderline={disableUnderline}
          />
        </Grid>
      </Grid>
    );
  }
}

export const MultiSelect = SyncSelectComponent;
