import React from 'react';
import SymbolIcon from './../../SymbolIcon';
import {
    StyledSelect,
    Wrapper,
    IconWrapper
} from './styledComponents';

const CountryFieldPure = ({
    country,
    countries,
    countryId,
    onCountryChange,
    countryName,
    isEu
}) => {
    return (
        <Wrapper>
            <StyledSelect
                name="waypoint-countries-select"
                value={country}
                onChange={onCountryChange}
                multi={false}
                isClearable
                isSearchable={true}
                options={countries}
                placeholder="Select Country..."
            />
            <IconWrapper>
                <SymbolIcon
                    tooltip={countryName}
                    iconClassName={countryId ? `flag-icon flag-icon-${countryId.toLowerCase()} flag-icon-squared` : ''}
                />
                <SymbolIcon
                    tooltip={isEu ? 'EU country' : null}
                    iconClassName={isEu ? `flag-icon flag-icon-eu flag-icon-squared` : 'flag-icon flag-icon-squared'}
                />
            </IconWrapper>
        </Wrapper>
    );
}

export default CountryFieldPure;