import {
    compose,
    withProps
} from 'recompose';

const TYPES = [
    {
        label: 'Other',
        value: 'water'
    },
    {
        label: 'Port',
        value: 'port'
    },
    {
        label: 'Anchorage',
        value: 'anchorage'
    },
    {
        label: 'Oil Field/Block',
        value: 'block'
    },
    {
        label: 'Country Placeholder',
        value: 'country placeholder'
    },
    {
        label: 'Offshore Installation',
        value: 'offshore-installation'
    }
]

const TypeFieldHandlers = compose(
    withProps(({
        type
    }) => ({
        typeoptions: TYPES,
        typeObj: TYPES.find(t => t.value === type)
    })),
    withProps(({
        typeObj
    }) => ({
        typeLabel: typeObj ? typeObj.label : '',
        typeStr: typeObj ? typeObj.value : '',
    })),
);

export default TypeFieldHandlers;