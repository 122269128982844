import { ReduxAction } from './helper';

export const setLocationAddMode = new ReduxAction("MAP_LOCATION_ADD_MODE_SET", (modeStr) => modeStr);

export const setLeafletObject = new ReduxAction("MAP_LEAFLET_OBJECT_SET", (map, isWindyMap, hideData) => Object.assign({}, { leafletMap: map, isWindyMap: isWindyMap, hideData: hideData === true}) )

export const setCurrentViewport = new ReduxAction("MAP_VIEWPORT_SET", (center, zoom) => Object.assign({ center, zoom }) );

export const activateCustomAreaDeleteMode = new ReduxAction("CUSTOM_AREA_MODE_DELETE");
export const activateCustomAreaAddMode = new ReduxAction("CUSTOM_AREA_MODE_ADD");
export const exitCustomAreaMode = new ReduxAction("CUSTOM_AREA_MODE_EXIT");

export const toggleRequireDoubleConfirmation = new ReduxAction("DOUBLE_CONFIRMATION_TOGGLE");
export const toggleMapSettingsJwcAreas = new ReduxAction("MAP_SHOW_JWC_TOGGLE");

export const setActiveBaselayer = new ReduxAction('UI_STATE_MAP_ACTIVE_BASELAYER_SET', layer => layer);

export const toggleMapSettingsZoomControl = new ReduxAction('MAP_SETTINGS_CONTROLS_ZOOM_TOGGLE');

  export const toggleMapSettingsScale = new ReduxAction(
    'MAP_SETTINGS_CONTROLS_SCALE_TOGGLE',
    forcedParam =>
      Object.assign({ forced: !!forcedParam }, { value: forcedParam })
  );
  export const toggleMapSettingsEcaAreas = new ReduxAction(
    'MAP_SETTINGS_CONTROLS_ECA_AREAS_TOGGLE'
  );
  export const toggleMapSettingsHighRiskAreas = new ReduxAction(
    'MAP_SETTINGS_CONTROLS_HIGH_RISK_AREAS_TOGGLE'
  );
  
  export const toggleMapSettingsDrawCustomArea = new ReduxAction(
    'MAP_SETTINGS_CONTROLS_CUSTOM_AREAS_TOGGLE'
  );