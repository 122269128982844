import React, { PureComponent } from 'react';
import ValueSection from './ValueSection';
import CountryField from './CountryField';

export default class CountrySection extends PureComponent {
  render() {
    const { 
        countryId,
        onCountryChange
    } = this.props;

    return (
      <ValueSection header="Country">
        <CountryField 
            countryId={countryId} 
            onCountryChange={onCountryChange}
        />
      </ValueSection>
    );
  }
}
