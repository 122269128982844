import * as actions from './../actions';
import { update } from './helper';

const initialConnectivityStatus = {
  online: navigator.onLine,
  updateInterval: 5 * 60e3,
  lastSuccessfullAccountHealthcheck: Date.now(),
  lastSuccessfullApiHealthcheck: Date.now(),
};

export default function connectivityReducer(
  status = initialConnectivityStatus,
  action
) {
  switch (action.type) {
    case actions.connectivityEstablished.type:
      console.log('connectivity established')
      return update(status, {
        online: true,
      });
    case actions.connectivityLost.type:
      console.log('connectivity lost')
      return update(status, {
        online: false,
      });

    case actions.accountHealthcheck.SUCCEEDED:
      return update(status, {
        lastSuccessfullAccountHealthcheck: Date.now(),
      });

    case actions.apiHealthcheck.SUCCEEDED:
      return update(status, {
        lastSuccessfullApiHealthcheck: Date.now(),
      });

    default:
      return status;
  }
}
