import { compose, withState, withHandlers, lifecycle } from 'recompose';
import QueryString from 'query-string';
import { withRouter } from 'react-router-dom';

const Handlers = compose(
  withRouter,
  withHandlers({
    refreshLink: props => location => {
      const { urlTrigger, urlParams } = props;

      // const search = Object.assign(
      //     { },
      //     QueryString.parse(location.search),
      //     { [urlTrigger] : undefined }
      // );
      const queryStr = [
        // QueryString.stringify(search),
        // Object.keys(search).filter((key) => key !== urlTrigger).length > 0 ? '&' : '',
        `${urlTrigger}=true`,
        urlParams ? '&' : '',
        QueryString.stringify(urlParams || {}),
      ].join('');

      return `${location.pathname}?${queryStr}`;
    },
  }),
  withState('link', 'setLink', ({ refreshLink, location }) =>
    refreshLink(location)
  ),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      const { refreshLink, setLink, link } = this.props;
      const newLink = refreshLink(nextProps.location);
      if (link !== newLink) {
        setLink(newLink);
      }
    },
  })
);

export default Handlers;
