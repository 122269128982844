import React from 'react';
import {
  compose,
  withState,
  lifecycle,
  branch,
  renderComponent,
} from 'recompose';
import groupBy from 'lodash/groupBy';
import { fetchFaqs } from './../../services';
import LoadingScreen from '../../common/LoadingScreen';
import RequestFailure from '../../common/RequestFailure';

const FaqPageHandlers = compose(
  withState('loading', 'setLoading', true),
  withState('faqs', 'setFaqs', null),
  lifecycle({
    componentDidMount() {
      fetchFaqs()
        .then(response => {
          if (response.status === 'ok' && response.faqs.length > 0) {
            const faqsGrouped = groupBy(response.faqs, 'section_title');
            this.props.setFaqs(faqsGrouped);
          }
        })
        .then(() => this.props.setLoading(false))
        .catch(() => this.props.setLoading(false));
    },
  }),
  branch(({ loading }) => loading, renderComponent(LoadingScreen)),
  branch(
    ({ loading, faqs }) => !loading && !faqs,
    renderComponent(() => (
      <RequestFailure message="Sorry, something went wrong." />
    ))
  )
);

export default FaqPageHandlers;
