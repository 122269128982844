export const FETCH_LICENSES_REQUESTED = 'FETCH_LICENSES_REQUESTED';
export const FETCH_LICENSES_SUCCEEDED = 'FETCH_LICENSES_SUCCEEDED';
export const FETCH_LICENSES_FAILED = 'FETCH_LICENSES_FAILED';

export const FETCH_LICENSE_DETAILS_REQUESTED =
  'FETCH_LICENSE_DETAILS_REQUESTED';
export const FETCH_LICENSE_DETAILS_SUCCEEDED =
  'FETCH_LICENSE_DETAILS_SUCCEEDED';
export const FETCH_LICENSE_DETAILS_FAILED = 'FETCH_LICENSE_DETAILS_FAILED';

export const FETCH_USAGE_DETAILS_REQUESTED = 'FETCH_USAGE_DETAILS_REQUESTED';
export const FETCH_USAGE_DETAILS_SUCCEEDED = 'FETCH_USAGE_DETAILS_SUCCEEDED';
export const FETCH_USAGE_DETAILS_FAILED = 'FETCH_USAGE_DETAILS_FAILED';

export const FETCH_USER_VESSELS_REQUESTED = 'FETCH_USER_VESSELS_REQUESTED';
export const FETCH_USER_VESSELS_SUCCEEDED = 'FETCH_USER_VESSELS_SUCCEEDED';
export const FETCH_USER_VESSELS_FAILED = 'FETCH_USER_VESSELS_FAILED';

export const FETCH_USER_LOCATIONS_REQUESTED = 'FETCH_USER_LOCATIONS_REQUESTED';
export const FETCH_USER_LOCATIONS_SUCCEEDED = 'FETCH_USER_LOCATIONS_SUCCEEDED';
export const FETCH_USER_LOCATIONS_FAILED = 'FETCH_USER_LOCATIONS_FAILED';

export const FETCH_USER_FLEETS_REQUESTED = 'FETCH_USER_FLEETS_REQUESTED';
export const FETCH_USER_FLEETS_SUCCEEDED = 'FETCH_USER_FLEETS_SUCCEEDED';
export const FETCH_USER_FLEETS_FAILED = 'FETCH_USER_FLEETS_FAILED';

export const FETCH_USER_CARGO_REQUESTED = 'FETCH_USER_CARGO_REQUESTED';
export const FETCH_USER_CARGO_SUCCEEDED = 'FETCH_USER_CARGO_SUCCEEDED';
export const FETCH_USER_CARGO_FAILED = 'FETCH_USER_CARGO_FAILED';

export const FETCH_USER_ITINERARIES_REQUESTED =
  'FETCH_USER_ITINERARIES_REQUESTED';
export const FETCH_USER_ITINERARIES_SUCCEEDED =
  'FETCH_USER_ITINERARIES_SUCCEEDED';
export const FETCH_USER_ITINERARIES_FAILED = 'FETCH_USER_ITINERARIES_FAILED';

export const FETCH_USER_MATRICES_REQUESTED = 'FETCH_USER_MATRICES_REQUESTED';
export const FETCH_USER_MATRIX_SUCCEEDED = 'FETCH_USER_MATRICES_SUCCEEDED';