import styled from 'styled-components';
import { Colors } from './../../aquaplot/colors';

export const CircleSpan = styled.span`
    background-color: lightgray;
    margin-left: 8px;
    color: ${Colors.aqp_deep_sea};
    padding: 2px 6px;
    font-size: small;
    border-radius: 12px;
`