import React from 'react';
import { Route } from 'react-router-dom';
import RightDrawer from '../Drawer';
import QueryString from 'query-string';

const getDestination = (location, urlTrigger, urlParams) => {
  if (!location || !urlTrigger || !urlParams.length === 0) return null;

  const search = QueryString.parse(location.search);
  delete search[urlTrigger];
  urlParams.forEach(param => {
    delete search[param];
  });
  return `${location.pathname}?${QueryString.stringify(search)}`;
};

const UrlTriggeredDrawerPure = ({
  urlTrigger,
  urlParams,
  renderContent,
  heading,
  width,
  data,
  ...rest
}) => {
  return (
    <Route
      // eslint-disable-next-line react/no-children-prop
      children={routerProps => {
        const query = QueryString.parse(routerProps.location.search);
        const trigger = query[urlTrigger];
        const destination = getDestination(
          routerProps.location,
          urlTrigger,
          urlParams || []
        );
        const callback = () => routerProps.history.push(destination);
        return (
          <RightDrawer
            top
            {...rest}
            destination={destination}
            callback={callback}
            width={width || '1035px'}
            isOpen={trigger && trigger === 'true'}
            heading={heading}
            data={data}
            renderContent={() =>
              renderContent({ urlTrigger, ...routerProps, callback })
            }
          />
        );
      }}
    />
  );
};

export default UrlTriggeredDrawerPure;
