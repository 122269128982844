import {
    compose,
    lifecycle,
    withState,
    withHandlers,
} from 'recompose';
import withLeaflet from './../../../../common/Leaflet/withLeaflet';
import { connect } from 'react-redux';
import { 
    addPointToCustomArea, 
    clearCustomArea, 
    updateSnackbarMessage,
    removePointFromCustomArea
} from '../../../../store/redux/actions'
import { Colors } from './../../../../aquaplot/colors';
import L, { DomEvent } from 'leaflet';
import gpsi from 'geojson-polygon-self-intersections';

const GeoJsonPoint = (latlng, properties) => {
    return {
        "type": "Feature",
        "properties": properties || {},
        "geometry": {
            "type": "Point",
            "coordinates": [
                latlng.lng,
                latlng.lat
            ]
        }
    }
}

const mapStateToProps = (state) => {
    return {
      active: state.map.mode === state.map.modes.customArea,
      latlngs: state.routing.customAreaLatlngs,
      mobile: state.ui.mobile
    }
  }
  
const mapDispatchToProps = (dispatch) => {
    return {
        addPoint: (latlng) => {
            dispatch(addPointToCustomArea.call(latlng))
        },
        clearArea: () => dispatch(clearCustomArea.call()),
        updateSnackbarMessage: msg => {
            dispatch(updateSnackbarMessage.call(msg));
        },
        removePointFromCustomArea: (latlng) => {
            dispatch(removePointFromCustomArea.call(latlng))
        }
    }
}
  
const CustomAreaHandlers = compose(
    withLeaflet,
    connect(mapStateToProps, mapDispatchToProps),
    withState('activeState', 'setActiveState', ({active}) => active),
    withState('dialogIsOpen', 'setDialogIsOpen', false),
    withState('map', 'setMap', null),
    withHandlers({
        defaultAreaObject: () => () => {
            return {
                coords: [],
                layer: null,
                admissable: true,
            }   
        }
    }),
    withState('area', 'setArea', ({defaultAreaObject}) => defaultAreaObject()),
    withHandlers({
        onClickHandler: ({
            addPoint
        }) => ({ latlng }) => {
            // console.log('handling click', latlng)
            addPoint(latlng);
        },
    }),
    withHandlers({
        registerOnClickListener: props => () => {
            const { leafletMap: leaflet, onClickHandler, setMap } = props;
            if(leaflet){
                leaflet.off('click', onClickHandler);
                leaflet.on('click', onClickHandler);
                setMap(leaflet);
            } 
        },
        unregisterOnClickListener: props => () => {
            const { leafletMap: leaflet, onClickHandler, setMap } = props;
            leaflet && leaflet.off('click', onClickHandler);
            leaflet && setMap(leaflet);
        },
    }),
    withHandlers({
        handleUpdate: props => () => {
            const {
                active,
                unregisterOnClickListener,
                registerOnClickListener,
                setActiveState,
                clearArea
            } = props
            if(active){
                registerOnClickListener();
            }
            else{
                unregisterOnClickListener();
                clearArea();
            }
            setActiveState(active);
        },
        handleVerticeClick: ({
            removePointFromCustomArea,
            latlngs,
            isWindyMap,
            updateSnackbarMessage,
            setDialogIsOpen
        }) => (e) => {
            const coordinates = e.target.feature.geometry.coordinates;
            const latlng = {
                lat: coordinates[1],
                lng: coordinates[0]
            };
            DomEvent.stopPropagation(e);
            if(!latlng || !latlngs || latlngs.length === 0) return; // nothing sensical to do
            // if latlng matches last entry in array, delete that coordinate
            if(JSON.stringify(latlng) === JSON.stringify(latlngs[latlngs.length - 1])){
                removePointFromCustomArea(latlng);
            }
            if(latlngs.length > 2 && JSON.stringify(latlng) === JSON.stringify(latlngs[0])){
                // closed polyline, forming polygon
                // check admissability
                const areaLatlngs = latlngs.concat([latlng]);
                const areaGeoJSON = L.polygon(areaLatlngs).toGeoJSON();
                const isects = gpsi(areaGeoJSON);
                const admissable = isects.geometry.coordinates.length === 0;
                const smallEnough = latlngs.length <= 30;
                if(admissable && smallEnough){
                    setDialogIsOpen(true);
                }
                else if(admissable && !smallEnough){
                    updateSnackbarMessage('Area is too large (Maximum 30 points). You can add multiple areas that overlap instead.');
                }
                else{
                    updateSnackbarMessage('Area may not intersect itself.');
                }
            }
        }
    }),
    withHandlers({
        updateArea: ({
            latlngs,
            area: {
                coords,
                layer
            },
            setArea,
            map,
            leafletMap,
            isWindyMap,
            defaultAreaObject,
            handleVerticeClick,
            mobile
        }) => () => {
            //need to 'rerender' if either map or data has changed
            const mapsChanged = leafletMap !== map;
            const dataChanged = JSON.stringify(latlngs) !== JSON.stringify(coords);
            if(leafletMap && (mapsChanged || dataChanged)){
                // clear existing
                layer && map && map.removeLayer(layer); 

                let admissable = true;
                let areaGeoJSON = null;
                const smallEnough = latlngs.length <= 30;
                if(latlngs.length > 2){
                    areaGeoJSON = L.polygon(latlngs).toGeoJSON();
                    const isects = gpsi(areaGeoJSON);
                    admissable = isects.geometry.coordinates.length === 0;
                }

                const geojson = L.geoJSON(L.polyline(latlngs).toGeoJSON(), {
                    style: {
                        stroke: false,
                        color: Colors.aqp_deep_sea
                    },
                    pointToLayer: (feature, latlng) => {
                        return new L.CircleMarker(latlng, {
                            radius: mobile ? 10 : 4, 
                            fillOpacity: 0.75,
                            fillColor: Colors.aqp_deep_sea
                        });
                    },
                    onEachFeature : (feature, layer) => {
                        if(feature.geometry.type === "LineString"){
                            layer.setStyle(Object.assign({
                                stroke: true,
                            }, isWindyMap ? { clickable: false } : { interactive: false }));
                        }
                        else if(feature.geometry.type === "Polygon"){
                            layer.setStyle(Object.assign(
                                {}, 
                                isWindyMap ? { clickable: false } : { interactive: false },
                                admissable && smallEnough ? {} : { fillColor: 'red' }
                            ));
                        }
                        else if(feature.geometry.type === "Point"){
                            layer.on('click', handleVerticeClick)
                        }
                    }
                })
                areaGeoJSON && geojson.addData(areaGeoJSON);
                latlngs.forEach(latlng => geojson.addData(GeoJsonPoint(latlng)));
                
                // console.log(layerNew)
                geojson.addTo(leafletMap);
                setArea({
                    coords: latlngs,
                    layer: geojson,
                    admissable: admissable
                });
                // console.log(latlngs)
                
            }
            else if(!leafletMap && (map || coords.length > 0 || layer)){
                //clear existing
                map.removeLayer(layer);
                setArea(defaultAreaObject());
            }
        }
    }),
    lifecycle({
        componentDidMount(){
            const { handleUpdate, updateArea } = this.props;
            handleUpdate();
            updateArea();
        },
        componentDidUpdate(){
            const { 
                handleUpdate, 
                leafletMap, 
                map, 
                active, 
                activeState, 
                updateArea 
            } = this.props;
            if(leafletMap !== map || active !== activeState) handleUpdate();
            updateArea();
        },
        componentWillUnmount(){
            this.props.handleUpdate();
            this.props.updateArea();
        }
    })
);

export default CustomAreaHandlers;
  