import axios from 'axios';
import { functionWithApiEndpoints } from './../store/withApiEndpoints';

const axiosConfig = {
  mode: 'no-cors',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

export const addStripeSourceId = functionWithApiEndpoints(({ accountEndpoint, sourceId }) => {
  const url = `${accountEndpoint}/stripe_source`;
  return axios.post(url, { stripe_source_id: sourceId }, axiosConfig);
});
