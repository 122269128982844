import React from 'react';
import LoadingMarker from './../../markers/LoadingMarker';
import ConfirmValidatedMarker from './../../markers/ConfirmValidatedMarker';
import LeafletMarker from './../../markers/LeafletMarker';

const Pure = ({
    validatedLatlng,
    latlng,
    handleAddWaypoint
}) => {
    // console.log(validatedLatlng, latlng)
    if(!validatedLatlng){
        return <LoadingMarker onClick={() => {}} latlng={latlng} />;
    }
    // console.log('should render proper marker')
    return (
        <LeafletMarker
            draggable={false}
            latlng={validatedLatlng ? validatedLatlng : latlng}
            markerComponent={
                <ConfirmValidatedMarker 
                    latlng={validatedLatlng}
                    vesselType={true}
                />
            }
            onClick={handleAddWaypoint}
        />
    );
}

export default Pure;