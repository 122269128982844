import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
    CardsContainer,
  } from '../../styledComponents';

const Pure = ({ children }) => {
    return (
        <Grid item xs={12} md={9} container style={{position: 'relative', overflow: 'auto', maxHeight: '100%'}}>
          <CardsContainer>
            { children }
          </CardsContainer>
        </Grid>
    )
}

export default Pure;