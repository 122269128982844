import styled from 'styled-components';

export const WrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex-grow: 1;
  height: 70%;
  align-items: center;
  justify-content: center;
  // padding: 40px;
`;

export const Message = styled.p`
  color: darkgray;
  text-align: center;
`;
