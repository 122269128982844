import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Add from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';

export const BorderGrid = styled(Grid)`
  position: relative;
  border-bottom: 1px solid lightgray;
  min-height: 3em;
  height: ${props => props.height};
  padding: ${props => (props.padding ? props.padding : '1em')}
  background-color: ${props =>
    props.graybg === 'true' ? props.theme.aqp_bg_lighter_gray : 'white'};
`;

export const AddIcon = styled(Add)`
  && {
    height: 0.7em;
  }
`;

export const ToolbarTitle = styled(({children, ...other}) => (
    <Typography variant="h6" {...other}>{ children }</Typography>
))`
`;