export  class ReduxAction {
  
    constructor(actionType, payloadGenerator = null, snackMessage = ""){
      this.type = actionType;
      this.payloadGenerator = payloadGenerator;
      this.snackMessage = snackMessage;
    }
  
    call(){
      return { 
        type: this.type, 
        payload: this.payloadGenerator ? this.payloadGenerator.apply(this, arguments) : {},
        snack_message: this.snackMessage
      };
    }
  }
  
export class SagaReduxAction {
   
    constructor(name, payloadGenerator = null, successGenerator = null, failureGenerator = null){
        const _name = name.toUpperCase();
        this["REQUESTED"] = _name + "_REQUESTED";
        this["SUCCEEDED"] = _name + "_SUCCEEDED";
        this["FAILED"] = _name + "_FAILED";
  
        this.payloadGenerator = payloadGenerator;
    }
  
    call(){
      const action = { 
        type: this.REQUESTED, 
        payload: this.payloadGenerator ? this.payloadGenerator.apply(this, arguments) : {} 
      };
      return action;
    }
  
  }