import React from 'react';

import LeafletMarker from './LeafletMarker';
import CircleMarker from './CircleMarker';

class VesselMarker extends React.Component{
    render(){
        const { 
            vessel, 
            onClick, 
            onMouseOver,
            onMouseOut,
            highlighted
        } = this.props;

        return <LeafletMarker
            draggable={false}
            latlng={vessel.latlng}
            markerComponent={
                <CircleMarker 
                    highlighted={highlighted} 
                    tooltip={vessel.name || 'Loading'}
                    forceTooltipOpen={highlighted}
                />
            } 
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        />;
    }
}

export default VesselMarker;