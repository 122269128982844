import {
    compose,
    branch,
    renderNothing,
    withState,
    withProps,
    lifecycle,
    withHandlers
} from 'recompose';

import { connect } from 'react-redux';
import { addWaypoint } from './../../../../store/redux/actions';
import { 
    postValidateCall as validateCall 
} from '../../../../store/sagas/aqp-api-helper';

const mapStateToProps = (state) => {
    return {
      vesselCandidate: state.routing.vesselCandidate,
      waypoints: state.routing.waypoints,
      hideData: state.map.leafletObj.hideData === true
    }
}
  
const mapDispatchToProps = (dispatch) => {
  return {
      addWaypoint: (waypoint) => {
        dispatch(addWaypoint.call(waypoint))
      },
  }
}

const getWaypoint = (latlng, name, uuid) => {
    return {
      name: name,
      latlng: latlng,
      locode: null,
      country: null,
      type: 'vessel',
      uuid: uuid,
      draggable: false,
    };
  }

const Handlers = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withState('latlng', 'setLatlng', null),
    withState('validatedLatlng', 'setValidatedLatlng', null),
    withProps(({
        latlng,
        vesselCandidate: { latlng: candidateLatlng, uuid: id },
        waypoints
    }) => {
        return {
            hide: !candidateLatlng || !latlng || !!waypoints.find(({ uuid }) => uuid === id)
        }
    }),
    withHandlers({
        reset: ({
            setLatlng,
            setValidatedLatlng
        }) => () => {
            setLatlng(null);
            setValidatedLatlng(null);
        },
        validate: ({
            setValidatedLatlng,
            setLatlng
        }) => (latlng) => {
            validateCall({ latlng })
                .then(({ validatedLatlng }) => {
                    if(validatedLatlng){
                        setValidatedLatlng(validatedLatlng);
                        setLatlng(latlng)
                    }
                })
        },
    }),
    withHandlers({
        handleUpdate: ({
            vesselCandidate: { latlng },
            latlng: prevLatlng,
            validate,
            reset
        }) => () => {
            if(latlng && latlng !== prevLatlng){
                validate(latlng);
            }
            else if(!latlng && prevLatlng){
                // console.log('reset')
                reset();
            }
        },
        handleAddWaypoint: ({
            validatedLatlng,
            addWaypoint,
            vesselCandidate: {
                name,
                uuid
            },
            reset
        }) => () => {
            const wp = getWaypoint(validatedLatlng, name, uuid);
            addWaypoint(wp);
            reset();
        }
    }),
    lifecycle({
        componentDidMount(){
            this.props.handleUpdate();
        },
        componentDidUpdate(){
            this.props.handleUpdate();
        }
    }),
    branch(({ hide, hideData }) => hide || hideData, renderNothing),
);

export default Handlers;