import axios from 'axios';
import { functionWithApiEndpoints } from './../store/withApiEndpoints';

const axiosConfig = {
  mode: 'no-cors',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

const axiosConfigWithCookie = {
  mode: 'no-cors',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

export const requestLoginlink = functionWithApiEndpoints(({ accountEndpoint, email }) => {
  const url = `${accountEndpoint}/loginlink`;
  return axios.post(url, { email }, axiosConfig).then(({ data }) => data);
});


export const requestPasswordResetToken = functionWithApiEndpoints(({ accountEndpoint }) => {
  const url = `${accountEndpoint}/password-reset-token`;
  return axios.post(url, {}, axiosConfigWithCookie).then(({ data }) => data);
});