import React from 'react';
import {
    compose, 
    withProps
} from 'recompose';

const Handlers = compose(
    withProps(({
        wrapper,
        component
    }) => ({
        Wrapper: wrapper || React.Fragment,
        Component: wrapper ? 'section' : component || 'button'
    }))
);

export default Handlers;