import React from 'react';
import Range from 'rc-slider/lib/Range';
import Slider from 'rc-slider/lib/Slider';
import 'rc-slider/assets/index.css';
import {
    SliderWrapper
} from './styledComponents';

const Pure = ({
    min,
    max,
    step,
    value,
    onChange
}) => {
    if(Array.isArray(value)){
        return (
            <SliderWrapper>
                <Range
                    min={min}
                    max={max}
                    step={step}
                    value={value}
                    onChange={onChange}
                />
            </SliderWrapper>   
        )
    }
    else{
        return (
            <SliderWrapper>
                <Slider
                    min={min}
                    max={max}
                    step={step}
                    value={value}
                    onChange={onChange}
                />
            </SliderWrapper>
        )
    }
};

export default Pure;