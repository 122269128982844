import {
    compose,
    branch,
    withState,
    withHandlers,
    withProps,
    renderNothing,
    lifecycle
} from 'recompose';
import withLeaflet from './../../../../common/Leaflet/withLeaflet';
import { connect } from 'react-redux';
import { Colors } from './../../../../aquaplot/colors';
import {
    deleteNogoArea
} from './../../../../store/redux/actions';

import L, { DomEvent } from 'leaflet';

const nogoStyle = (selectable) => () => {
    return { 
        color: Colors.aqp_error, 
        dashArray: "10", 
        weight: 1, 
        fillOpacity: 0.2,
        interactive: selectable,
    }
}

const nogoStyleWindy = (selectable) => () => {
    return { 
        color: Colors.aqp_error, 
        dashArray: "10", 
        weight: 1, 
        fillOpacity: 0.5,
        clickable: selectable
    }
}


const mapStateToProps = state => {
    return {
        nogoAreas: state.routing.nogoAreas,
        areasSelectable: state.map.areasSelectable
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deleteNogoArea: (uuid) => dispatch(deleteNogoArea.call(uuid))
    }
}

const Handlers = compose(
    withLeaflet,
    connect(mapStateToProps, mapDispatchToProps),
    withProps(() => ({
        showNogoAreas: true
    })),
    withHandlers({
        defaultObject: () => () => {
            return { 
                geojsonData: [], 
                geojson: null,
                map: null,
                selectable: false
            };
        },
        handleAreaClick: ({
            areasSelectable,
            deleteNogoArea
        }) => (e) => {
            if(!areasSelectable) return true;
            DomEvent.stopPropagation(e);
            
            const uuid = e.target.feature.uuid;
            deleteNogoArea(uuid);
        }
    }),
    withState('areas', 'setAreas', ({ defaultObject }) => defaultObject()),
    withHandlers({
        updateAreas: ({
            areas: {
                geojsonData,
                map,
                geojson,
                selectable
            },
            areasSelectable,
            leafletMap,
            isWindyMap,
            showNogoAreas,
            nogoAreas,
            setAreas,
            defaultObject,
            handleAreaClick
        }) => () => {
            const nogoAreasGeoJson = nogoAreas.map(({latlngs, uuid}) => 
                Object.assign({}, L.polygon(latlngs).toGeoJSON(), {uuid}));
            const data = showNogoAreas ? nogoAreasGeoJson : [];
            //need to 'rerender' if either map has changed or data has changed
            const mapsChanged = leafletMap !== map;
            const dataChanged = JSON.stringify(data) !== JSON.stringify(geojsonData);
            const interactivityChanged = areasSelectable !== selectable;

            if(leafletMap && (mapsChanged || dataChanged || interactivityChanged)){
                if(geojson && map){
                    //clear existing
                    map.removeLayer(geojson);
                }

                const fg = L.geoJSON(data, {
                    style: isWindyMap ? nogoStyleWindy(areasSelectable) : nogoStyle(areasSelectable),
                    onEachFeature : (feature, layer) => {
                        areasSelectable && layer.on('click', handleAreaClick)
                    }
                });

                fg.addTo(leafletMap);
                setAreas({ 
                    map: leafletMap, 
                    geojson: fg, 
                    geojsonData: data,
                    selectable: areasSelectable
                });
            }
            else if(!leafletMap && (map || geojsonData.length > 0 || geojson)){
                setAreas(defaultObject());
            }
        }
    }),
    lifecycle({
        componentDidMount(){
            this.props.updateAreas();
            // console.log('mounted');
        },
        componentDidUpdate(){
            this.props.updateAreas();
            // console.log('updated');
        },
        componentWillUnmount(){
            this.props.updateAreas();
            // console.log('unmount')
        },
    }),
    branch(() => true, renderNothing)
)

export default Handlers;