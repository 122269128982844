import { createMuiTheme } from '@material-ui/core/styles';
import { Colors } from './colors';

export const coreTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#3c5c7f',
      main: '#073352',
      dark: '#000b29',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#61c0bd',
      main: '#298f8d',
      dark: '#006160',
      contrastText: '#000000',
    },
  },
  typography: {
    useNextVariants: true,
  },
  colors: {
    ...Colors,
  },
});

export const scTheme = {
  smDown: 'max-width: 600px',
  ...Colors,
};
