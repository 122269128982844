import React from 'react';
import { 
    StyledNavigation 
} from './styledComponents';
import Action from './Action';


const Pure = ({
    activeActions
}) => {
    return (
        <StyledNavigation>
            {
                activeActions.map((props, idx) => (
                    <Action 
                        key={`action-${idx}`}
                        {...props}
                        showLabel={activeActions.length < 4}
                    />
                ))
            }
        </StyledNavigation>
    )
}

export default Pure;