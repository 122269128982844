import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import green from '@material-ui/core/colors/green';
import { Colors } from '../aquaplot/colors';
import styled from 'styled-components';

const StyledDoneIcon = styled(DoneIcon)`
  color: white;
`

const styles = theme => ({
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: Colors.aqp_coastal_water,
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonFailure: {
    backgroundColor: Colors.aqp_error,
    color: Colors.aqp_white,
    '&:hover': {
      backgroundColor: Colors.aqp_error,
      color: Colors.aqp_white,
    },
  },
  buttonProgress: {
    color: Colors.aqp_coastal_water,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class CircularIntegration extends React.Component {
  render() {
    const {
      classes,
      label,
      waiting,
      disabled,
      success,
      onClick,
      fullWidth,
      flat,
      outlined,
      failure,
      rootStyle
    } = this.props;
    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
      [classes.buttonFailure]: failure,
    });

    return (
      <div className={classes.wrapper} style={rootStyle}>
        <Button
          variant={flat ? 'text' : outlined ? 'outlined' : 'contained'}
          color="primary"
          fullWidth={fullWidth}
          className={buttonClassname}
          disabled={waiting || disabled}
          onClick={success ? () => {} : onClick}
        >
          {success ? <StyledDoneIcon /> : label}
        </Button>
        {waiting && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(CircularIntegration);
