export const createMatrix = (database, firebaseId, name) => {
    console.log('creating matrix', firebaseId, name);
    return database.collection("DistanceMatrices").add({
        owner: firebaseId,
        name: name
    })
    .then(docRef => {
        console.log("Document written with ID: ", docRef.id);
        return docRef.id;
    })
    .catch(error => {
        console.error("Error adding document: ", error);
    });
  }