import React from 'react';
import styled from 'styled-components';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

const StyledIcon = styled(Icon)`
  &&{
    font-size: 16px;
    background-size: 100%;
    background-position-y: center;
  }
`

const StyledDiv = styled.div`
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
`

const Placeholder = styled.div`
  width: 16px;
  height: 16px;
  border: 1px dashed lightgray;
  font-size: 12px;
  color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SymbolIcon = ({
  tooltip,
  icon,
  iconClassName,
  hide
}) => {
  if(hide) return null;
  if(tooltip === undefined || tooltip === null){
    return (
      <StyledDiv>
        {
          icon 
            ? icon 
            : iconClassName 
              ? <StyledIcon className={iconClassName} /> 
              : <Placeholder>?</Placeholder>
        }
      </StyledDiv>
    )
  }
  return (
    <Tooltip title={tooltip} enterDelay={500}>
      <StyledDiv>
        {icon ? icon : iconClassName ? <StyledIcon className={iconClassName} /> : <Placeholder>?</Placeholder>}
      </StyledDiv>
    </Tooltip>
  );
}

export default SymbolIcon;
