import React from 'react';
import Context from './Context';

const withLeaflet = Component => (props) => {
    return (
        <Context.Consumer>
        {
            leafletObj => <Component {...props} {...leafletObj} />   
        }
        </Context.Consumer>
    )
  }
  
export default withLeaflet;