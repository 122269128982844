import * as actions from './actions';
import { combineReducers } from 'redux';
import UUID from 'uuid-js';
import Fuse from 'fuse.js';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {
  update,
  updateSegments,
  lastOfArrayOrNull,
  routingSplice,
  routingAdd,
} from './reducers/helper';
import { reducer as formReducer } from 'redux-form';
import ConnectivityReducer from './reducers/connectivity';
import DevReducer from './reducers/dev-settings';
import MapReducer from './reducers/map';
import ContextReducer from './reducers/context';
import AppsReducer from './reducers/applets';
import NavigationReducer from './reducers/navigation';
import BunkerReducer from './reducers/bunker';
import sortBy from 'lodash/sortBy';
import { arrayMove } from './utils';

import { fireStoreReducer } from './reducers/firestore';
import { userVesselsReducer } from './reducers/vesselsTab';
import { userLocationsReducer } from './reducers/locationsTab';
import { userFleetsReducer } from './reducers/fleetsTab';
import { userCargosReducer } from './reducers/cargoTab';
import { userItinerariesReducer } from './reducers/itinerariesTab';
import { matricesReducer } from './reducers/matrices';

const inititalTracking = {
  vessels: [],
  filterByFleet: undefined,
  highlightedImo: undefined,
};

function trackingReducer(tracking = inititalTracking, action) {
  switch (action.type) {
    case actions.setHighlightedImo.type:
      return update(tracking, {
        highlightedImo: action.payload
      });

    case actions.setFilterByFleetName.type:
      return update(tracking, {
        filterByFleet: action.payload
      });

    case actions.setTrackingVessels.type:
      return update(tracking, {
        vessels: action.payload
      });

    default:
      return tracking;
  }
}

const initialRouting = {
  waypoints: [],
  segments: [],
  defaultSpeed: 15,
  autocalculate: true, 
  autovalidate: false,
  channels: [
    {
      name: 'Suez Canal',
      slug: 'suez',
      default: true,  
      open: true,
    },
    {
      name: 'Panama Canal',
      slug: 'panama',
      default: true,
      open: true,
    },
    {
      name: 'Corinth Canal',
      slug: 'corinth',
      default: false,
      open: false,
    },
    {
      name: 'Kiel',
      slug: 'kiel',
      default: true,
      open: true,
    },
    {
      name: 'Strait of Gibraltar',
      slug: 'gibraltar',
      default: true,
      open: true,
    },
    {
      name: 'Strait of Messina',
      slug: 'messina',
      default: true,
      open: true,
    },
    {
      name: 'Singapore Strait',
      slug: 'singapore',
      default: true,
      open: true,
    },
    {
      name: 'Dover Strait',
      slug: 'dover',
      default: true,
      open: true,
    },
    {
      name: 'Strait of Magellan',
      slug: 'magellan',
      default: true,
      open: true,
    },
    {
      name: 'Florida Strait',
      slug: 'floridaStrait',
      default: true,
      open: true,
    },
    {
      name: 'Bosphorus',
      slug: 'bosphorus',
      default: true,
      open: true,
    },
    {
      name: 'Øresund',
      slug: 'oresund',
      default: true,
      open: true,
    },
    {
      name: 'Northeast Passage',
      slug: 'northeast',
      default: false,
      open: false,
    },
    {
      name: 'Northwest Passage',
      slug: 'northwest',
      default: false,
      open: false,
    },
  ],
  ecaAreas: [],
  highRiskAreas: [],
  highRiskAreasLastUpdated: null,
  jointWarCommitteeAreas: [],
  ecaAreaMappings: [
    {
      id: 0,
      name: 'No ECA',
    },
    {
      id: 1,
      name: 'Caribbean Sea',
    },
    {
      id: 2,
      name: 'Baltic Sea',
    },
    {
      id: 3,
      name: 'Hawaiian Islands',
    },
    {
      id: 4,
      name: 'US East Coast',
    },
    {
      id: 5,
      name: 'US West Coast',
    },
    {
      id: 6,
      name: 'North Sea',
    },
    {
      id: 7,
      name: 'Pearl River Delta',
    },
    {
      id: 8,
      name: 'Yangtze River Delta',
    },
    {
      id: 9,
      name: 'Bohai Gulf',
    },
  ],
  eca: false,
  hra: false,
  jwc: false,
  optionsObject: {
    autovalidate: 'true'
  },
  vesselCandidate: {},
  customAreaLatlngs: [],
  nogoAreas: [],
  buffer: null,
  bufferRoute: null,
  bufferPending: false,
  fullRouteGeojson: null,
  bufferSegmentLengths: [],
  bufferSegmentDistances: [],
  deviationFeatureCollection: null
};

const removeImmediateDuplicates = (waypoints) => {
  // console.log(waypoints)
  const uniques = waypoints.filter((wp, idx, arr) => {
    if(idx === 0) return true; //first item is safe
    // if execution reaches the following, at least 2 waypoints
    // are present and we can check against previous
    const prev = arr[idx - 1]; 
    if(prev.id && wp.id && prev.id === wp.id) return false;
    if(prev.latlng.lat === wp.latlng.lat && prev.latlng.lng === wp.latlng.lng) return false;
    return true;
  })
  return uniques;
}

function routingReducer(routing = initialRouting, { type, payload }) {
  switch (type) {
    case actions.setSingleVessel.type:
      return update(routing, {
        vesselCandidate: Object.assign({}, payload, {
          uuid: UUID.create().toString(),
        }),
      });

    case actions.addPointToCustomArea.type:
      return update(routing, {
        customAreaLatlngs: routing.customAreaLatlngs.concat([payload])
      });

    case actions.removePointFromCustomArea.type:
      return update(routing, {
        customAreaLatlngs: routing.customAreaLatlngs
          .filter(({lat, lng}) => lat !== payload.lat || lng !== payload.lng)
      });

    case actions.addNogoArea.type:
      return update(routing, {
        nogoAreas: routing.nogoAreas.concat([payload])
      });

    case actions.deleteNogoArea.type:
      return update(routing, {
        nogoAreas: routing.nogoAreas.filter(({ uuid }) => uuid !== payload)
      });

    case actions.clearCustomArea.type:
      return update(routing, {
        customAreaLatlngs: initialRouting.customAreaLatlngs
      });

    case actions.setAutocalculate.type:
      return update(routing, {
        autocalculate: payload
      });

    case actions.setAutovalidate.type:
      return update(routing, {
        autovalidate: payload
      });

    case actions.setEca.type:
    case actions.setHra.type:
    case actions.setJwc.type:
      return update(routing, payload);

    case actions.setDefaultSpeed.type:
      return update(routing, payload);

    case actions.addWaypoint.type: {
      const waypoint = payload;
      if (lastOfArrayOrNull(routing.waypoints) === waypoint.latlng) {
        return routing;
      }

      const idx = routing.segments.findIndex(
        ({ id }) => id === waypoint.segment_id
      );
      const waypoints = [].concat(routing.waypoints);
      waypoints.splice(idx > 0 ? idx + 1 : waypoints.length, 0, waypoint);

      return update(routing, routingAdd(routing, payload));
    }

    case actions.Validation.REQUESTED:
      return update(routing, {
        waypoints: routing.waypoints
          .map(wp => wp.uuid === payload.uuid 
            ? Object.assign(wp, { loading: true }) 
            : wp)
      })

    case actions.updateWaypoint.type:
    case actions.updateWaypointProperties.type:
    case actions.Validation.SUCCEEDED:
      const { forceUpdate, ...rest } = payload;
      // console.log(payload)
      const waypoints = routing.waypoints.map(wp =>
        wp.uuid === payload.uuid
          ? Object.assign({}, wp, rest, forceUpdate && routing.autocalculate ? { uuid: UUID.create().toString() } : {})
          : wp
      );
      return update(routing, {
        waypoints: waypoints,
        segments: updateSegments(routing, waypoints),
      });

    case actions.removeWaypoint.type: {
      return update(routing, routingSplice(routing, payload));
    }

    case actions.sortWaypoints.type:
      const { oldIndex, newIndex } = payload;
      const updatedWaypoints = removeImmediateDuplicates(arrayMove(routing.waypoints, oldIndex, newIndex));

      return update(routing, {
        waypoints: updatedWaypoints,
        segments: updateSegments(routing, updatedWaypoints),
      });

    case actions.clearWaypoints.type:
      return update(routing, {
        waypoints: [],
        vesselCandidate: {},
        segments: [],
      });

    case actions.requestSingleRoute.REQUESTED:
      // console.log('requestSingleRoute.REQUESTED', payload);
      return update(routing, {
        segments: routing.segments.map(seg => {
          return seg.id === payload.id
            ? Object.assign({}, seg, { processing: true, error: false })
            : seg;
          }),
      });
      
    case actions.Routes.FAILED:
      if(payload.response && 
        payload.response.status && 
        payload.response.status === "error" &&
        payload.response.id){
        const segments = routing.segments.map(seg => {
          return seg.id === payload.response.id
            ? Object.assign({}, seg, { error: true, processing: false })
            : seg;
        });

        return update(routing, {
          segments: segments,
        });
      }
      return routing;

    case actions.overwriteRouteData.type:
      return update(routing, payload);

    case actions.requestSingleRoute.SUCCEEDED:
    case actions.Routes.SUCCEEDED:
      const routeSegments = payload;
      const segments = routing.segments.map(seg => {
        const updated = routeSegments.find(
          ({ route: { id } }) => id === seg.id
        );
        return updated ? Object.assign({}, seg, updated, { processing: false, error: false }) : seg;
      });
      // console.log(routeSegments, routing.segments)
      return update(routing, {
        segments: segments,
      });

    case actions.updateRouteOptionsObject.type:
      return update(routing, {
        optionsObject: payload,
      });

    case actions.SETTINGS_CHANNEL_CLOSE:
      const channel_to_close = payload;
      return update(routing, {
        channels: routing.channels.map(c =>
          c.name !== channel_to_close ? c : update(c, { open: false })
        ),
      });

    case actions.RouteChannels.REQUESTED:
      return update(routing, {
        channels: initialRouting.channels,
      });

    case actions.SETTINGS_CHANNEL_SET:
      const openChannels = payload;
      return update(routing, {
        channels: routing.channels.map(c =>
          update(c, { open: openChannels.indexOf(c.name) > -1 })
        ),
      });

    case actions.ECA_ALL_SUCCEEDED:
      return update(routing, {
        ecaAreas: payload,
      });

    case actions.HRA_ALL_SUCCEEDED:
      return update(routing, {
        highRiskAreas: payload,
        highRiskAreasLastUpdated: Date.now()
      });

    case actions.requestJointWarCommitteeAreas.SUCCEEDED:
      return update(routing, {
        jointWarCommitteeAreas: payload,
      });

    case actions.BufferAroundRoute.REQUESTED:
      return update(routing, {
        bufferPending: true
      });

    case actions.updateBunkerPortsInBuffer.type:
      return update(routing, {
        bufferPending: false
      });

    case actions.BufferAroundRoute.SUCCEEDED:
      return update(routing, payload);

    case actions.clearBuffer.type:
      return update(routing, {
        buffer: null,
        bufferRoute: null,
        bufferPending: false,
        fullRouteGeojson: null,
        bufferSegmentLengths: [],
        bufferSegmentDistances: [],
        deviationFeatureCollection: null
      });

    case actions.Deviation.SUCCEEDED:
      return update(routing, {
        deviationFeatureCollection: payload.deviationFeatureCollection
      });

    case actions.clearDeviation.type:
      return update(routing, {
        deviationFeatureCollection: null
      });

    default:
      return routing;
  }
}

const initialGeocodeSearch = {
  search_term: '',
  locations: [],
  pois: [],
};

function geocodeSearchReducer(geocodeSearch = initialGeocodeSearch, action) {
  switch (action.type) {
    case actions.POI_SEARCH_TERM_SET:
      return update(geocodeSearch, {
        search_term: action.payload.search_term,
      });

    case actions.POI_SEARCH_TERM_CLEAR:
      return update(geocodeSearch, {
        search_term: initialGeocodeSearch.search_term,
      });

    case actions.POI_SUGGESTIONS_FETCH_SUCCEEDED:
      return update(geocodeSearch, {
        pois: action.payload,
      });

    case actions.POI_SUGGESTIONS_CLEAR:
      return update(geocodeSearch, {
        pois: initialGeocodeSearch.pois,
      });

    case actions.LOCATIONS_FETCH_SUCCEEDED:
      return update(geocodeSearch, {
        locations: action.payload,
      });

    case actions.LOCATIONS_CLEAR:
      return update(geocodeSearch, {
        locations: initialGeocodeSearch.locations,
      });

    default:
      return geocodeSearch;
  }
}

const initialUiState = {
  mobile: null,
  navbarIsVisible: true,
  showItineraryRedirectWarning: true
};

function uiReducer(uiState = initialUiState, action) {
  switch (action.type) {
    case actions.toggleItineraryRedirect.type:
      return update(uiState, {
        showItineraryRedirectWarning: !uiState.showItineraryRedirectWarning,
      });

    case actions.switchToMobileView.type:
      return update(uiState, {
        mobile: true,
      });

    case actions.switchToDesktopView.type:
      return update(uiState, {
        mobile: false,
      });

    case actions.toggleNavbar.type:
      return update(uiState, {
        navbarIsVisible: !uiState.navbarIsVisible,
      });

    default:
      return uiState;
  }
}

const initialStatus = {
  snack_message: '',
  snack_open: false,
  snack_autohide: 2000,
  snack_with_close_action: false,
  snack_with_confirm_to_close: false,
  snack_with_page_refresh_to_close: false,
  snack_installingWorker: null
};

function snackReducer(statusState = initialStatus, { payload, type, snack_message }) {
  // console.log(type)
  switch (type) {
    case actions.updateSnackbarMessage.type:
    case actions.clearSnackbar.type:
      return update(statusState, payload);

    case actions.Routes.FAILED:
      return update(statusState, {
        snack_message: "Sorry, something went wrong while calculating a route. Try moving the waypoints slightly or insert waypoint inbetween.",
        snack_open: true,
        snack_with_confirm_to_close: true
      });

    default:
      if (snack_message && snack_message !== '') {
        return update(statusState, {
          snack_message,
          snack_open: true,
        });
      }
      return initialStatus;
  }
}

const locationsFuseOptions = {
  shouldSort: true,
  tokenize: true,
  threshold: 0.1,
  location: 0,
  distance: 32,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    {
      name: 'name',
      weight: 0.4,
    },
    {
      name: 'locode',
      weight: 0.6,
    },
  ],
};

const initialData = {
  locations: [],
  locationsFuse: null,
};

function dataReducer(dataState = initialData, action) {
  switch (action.type) {
    case actions.LOCATIONS_ALL_SUCCEEDED:
      return update(dataState, {
        locations: action.payload,
        locationsFuse: new Fuse(action.payload, locationsFuseOptions),
      });

    default:
      return dataState;
  }
}

const initialUserState = (errorText = "") => ({
  loggedIn: false,
  loginInProgress: false,
  firstName: '',
  lastName: '',
  userLoaded: false,
  userEmails: [],
  hasPassword: false,
  loginErrorText: errorText,
  aqpToken: null,
  email: '',
  isUpdating: false
});

function userStateReducer(userState = initialUserState(), { type, payload }) {
  switch (type) {
    case actions.anyAuth.REQUESTED:
      return update(userState, {
        loginInProgress: true,
        loginErrorText: '',
      });

    case actions.anyAuth.SUCCEEDED:
      return update(userState, {
        loggedIn: payload.aqp_token !== '' && payload.aqp_token !== null,
        loginInProgress: false,
        loginErrorText: '',
        email: payload.email,
        aqpToken: payload.aqp_token,
      });

    case actions.anyAuth.FAILED:
      return update(userState, initialUserState(payload.showError ? 'Login failed. Please retry.' : ''));

    case actions.USER_LOGOUT_SUCCEEDED:
      return update(userState, initialUserState());

    case actions.UPDATE_NAME:
      return update(userState, {
        firstName: payload.firstName,
        lastName: payload.lastName,
      });

    case actions.UserProfile.REQUESTED:
    case actions.RemoveEmailFromUserProfile.REQUESTED:
    case actions.AddEmailToUserProfile.REQUESTED:
    case actions.ChangeUserProfile.REQUESTED:
      return update(userState, {
        isUpdating: true,
      });

    case actions.UserProfile.FAILED:
    case actions.RemoveEmailFromUserProfile.FAILED:
    case actions.AddEmailToUserProfile.FAILED:
    case actions.ChangeUserProfile.FAILED:
        return update(userState, {
          isUpdating: false,
        });

    case actions.UserProfile.SUCCEEDED:
    case actions.ChangeUserProfile.SUCCEEDED:
      return update(userState, {
        firstName: payload.first_name,
        lastName: payload.last_name,
        userLoaded: true,
        userEmails: payload.emails,
        hasPassword: payload.has_password,
        isUpdating: false,
      });

    case actions.RemoveEmailFromUserProfile.SUCCEEDED:
    case actions.AddEmailToUserProfile.SUCCEEDED:
      return update(userState, {
        userEmails: payload.emails,
        isUpdating: false,
      });

    default:
      return userState;
  }
}

const intialCountriesState = {
  loading: true,
  countries: [],
  isError: false,
  error: null,
};

const countriesReducer = (state = intialCountriesState, action) => {
  switch (action.type) {
    case actions.FETCH_COUNTRIES_SUCCEEDED:
      const sortedCountries = sortBy(action.payload, ['name']);
      const mappedCountries = sortedCountries.map(country => ({
        value: {
          id: country.country_id,
          isEu: country.is_eu,
          label: country.name,
        },
        label: country.name,
      }));
      return update(state, {
        ...state,
        loading: false,
        countries: mappedCountries,
      });
    case actions.FETCH_COUNTRIES_FAILED:
      return update(state, {
        ...state,
        loading: false,
        isError: true,
        error: action.payload,
      });
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  snack: snackReducer, //must be first
  context: ContextReducer,
  connectivity: ConnectivityReducer,
  tracking: trackingReducer,
  routing: routingReducer,
  geocodeSearch: geocodeSearchReducer,
  ui: uiReducer,
  map: MapReducer,
  user: userStateReducer,
  data: dataReducer,
  dev: DevReducer,
  apps: AppsReducer,
  firestore: fireStoreReducer,
  userVessels: userVesselsReducer,
  userLocations: userLocationsReducer,
  userFleets: userFleetsReducer,
  userCargos: userCargosReducer,
  userItineraries: userItinerariesReducer,
  countries: countriesReducer,
  form: formReducer,
  nav: NavigationReducer,
  bunker: BunkerReducer,
  matrices: matricesReducer
});

// config to persist the reducers
const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['routing', 'countries'],
  stateReconciler: autoMergeLevel2,
};

export default persistReducer(persistConfig, rootReducer);
