import React, {Component} from 'react';
import { connect } from 'react-redux';

import WaypointMarker from './../markers/WaypointMarker';

import { denormalizedLatLng } from './helper';

class Waypoints extends Component {
  render () {
    const { segments, waypoints } = this.props;

    const markers = waypoints
        .map((wp, idx) => Object.assign({}, wp, { latlng: denormalizedLatLng(wp, idx, segments) }));

    return (
        markers.map((waypoint, index) => 
            <WaypointMarker
                key={waypoint.uuid} 
                waypoint={waypoint}
                index={index + 1}
            />
        )
    )
  }
}

const mapStateToProps = (state) => {
    return{
        segments: state.routing.segments,
        waypoints: state.routing.waypoints,
    }
  }
  
const WaypointsContainer = connect(
    mapStateToProps,
    null
  )(Waypoints)

export default WaypointsContainer;