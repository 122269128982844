import { takeLatest, put, call, select } from 'redux-saga/effects';
import {
  // FETCH_COUNTRIES_REQUESTED,
  FETCH_COUNTRIES_SUCCEEDED,
  FETCH_COUNTRIES_FAILED,
} from './../redux/actions';
import { anyAuth } from './../redux/actions';
import axios from 'axios';
import { functionWithApiEndpoints } from './../withApiEndpoints';

const triggers = [anyAuth.SUCCEEDED];

export default function* countriesSagas() {
  yield takeLatest(triggers, getCountries);
}

const axiosConfig = {
  mode: 'no-cors',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

const fetchCountries = functionWithApiEndpoints(({ accountEndpoint }) => {
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  return axios.get(`${accountEndpoint}/countries`, axiosConfig);
});

function* getCountries() {
  const { countries } = yield select(state => state.countries)
  if(countries.length > 0) return;
  try {
    const response = yield call(fetchCountries);
    const countries = response.data.countries;

    // dispatch a success action to the store with the new countries
    yield put({ type: FETCH_COUNTRIES_SUCCEEDED, payload: countries });
  } catch (error) {
    yield put({ type: FETCH_COUNTRIES_FAILED, payload: error });
  }
}
