import React from 'react';
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import { Colors } from './../../aquaplot/colors';


export const StyledDrawer = styled(({ mobile, top, ...other }) => {
  return (
    <Drawer
      {...other}
      ModalProps={{
        BackdropProps: {
          classes: {
            root: top ? 'bd-root top' : 'bd-root',
          },
        },
      }}
      classes={{
        paperAnchorRight: mobile ? 'mobile-drawer' : 'desktop-drawer',
      }}
    />
  );
})`

  & .desktop-drawer {
    width: ${props => props.width}!important;
    max-width: 100%;
    top: 48px;
    background: ${props => props.theme.aqp_lightest_gray};
  }

  & .mobile-drawer {
    width: 100%;
    background: ${props => props.theme.aqp_lightest_gray};
  }

  & .bd-root {
    background-color: rgb(255, 255, 255, 0.6);
  }

  & bd-root top{
    z-index: 1500;
  }
`;

export const StyledDrawerContent = styled.div`
  padding: ${props => (props.padding ? props.padding : '2vw')};
  padding-bottom: 100px;
  min-width: 300px;
  overflow: auto;
  background-color: ${({ darkmode: d }) => d === 'true' ? '#2d3436' : 'ghostwhite'};
  color: ${({ darkmode: d }) => d === 'true' ? 'white' : 'inherit'};
  flex: 1 0 70%;

  a {
    color: ${({darkmode}) => darkmode === 'true' ? Colors.aqp_lemon : Colors.aqp_coastal_water}; 
  }

  svg {
      fill: ${({darkmode}) => darkmode === 'true' ? Colors.aqp_white : 'inherit'}!important;
  }
`;

export const StyledTitle = styled(Typography)`
  && {
    font-variant: all-petite-caps;
  }
`