import { SagaReduxAction } from './helper';

export const UserProfile = new SagaReduxAction('USER_PROFILE');
export const RemoveEmailFromUserProfile = new SagaReduxAction('USER_PROFILE_EMAIL_REMOVE',
    email => Object.assign({email: email}));
export const AddEmailToUserProfile = new SagaReduxAction('USER_PROFILE_EMAIL_ADD',
    email => Object.assign({email: email}));
export const ChangeUserProfile = new SagaReduxAction('USER_PROFILE_', 
    (firstName, lastName) => Object.assign(
        { 
            userProfile: {
                first_name: firstName, 
                last_name: lastName
            }
        }
    ));