import React from 'react';

import LeafletMarker from './../../markers/LeafletMarker';
import BaseMarker from './../../markers/BaseMarker';
import AddIcon from '@material-ui/icons/Add';
import { Colors } from './../../../../aquaplot/colors';

const CandidateMarkerComponent = ({
    needsConfirmation,
    validatedLatlng,
    type,
    ...other
}) => {
    if(type === "water"){
        return (needsConfirmation && validatedLatlng ? 
            <BaseMarker 
                {...other}
                backgroundColor={Colors.aqp_sundown}
                Icon={AddIcon}
            /> :
            <BaseMarker 
                {...other}    
                backgroundColor={Colors.aqp_sundown} 
                loading 
            />
        );
    }
    else{
        return null;
    }
}


const WaypointCandidatePure = (props) => {
    const {
        coords: {
            latlng,
            validatedLatlng,
            eca,
            eca_name,
            timezone,
            eez_country_code,
            sea_name
        },
        needsConfirmation,
        handleAddWaypoint
    } = props;

    return (
        <LeafletMarker
            draggable={false}
            latlng={validatedLatlng ? validatedLatlng : latlng}
            markerComponent={
                <CandidateMarkerComponent
                    needsConfirmation={needsConfirmation}
                    validatedLatlng={validatedLatlng}
                    type={"water"}
                />
            }
            onClick={ needsConfirmation && validatedLatlng ?
                () => handleAddWaypoint(validatedLatlng, null, eca, eca_name, timezone, eez_country_code, sea_name) : () => {}
            }
        />
    );
};

export default WaypointCandidatePure;