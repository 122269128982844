import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

export const GridWrapper = styled.div`
    flex: 10 1 auto;

    // .ag-column-hover{
    //     background-color: green;
    // }
`

export const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

`

export const Toggle = styled.div`
    display: ${props => props.hide === 'true' ? 'none' : 'flex'};
    flex: 1 1 auto;
    flex-direction: column;
`

export const StyledFormControl = styled(FormControl)`
    && {
        margin: 8px;
        margin-right: 20px;
    }
`

export const ErrorText = styled(Typography)`
    text-align: center;
    color: red;
`