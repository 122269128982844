import React, { Component } from 'react';
import { connect } from 'react-redux';
import MapButton from './MapButton';
import Fullscreen from '@material-ui/icons/Fullscreen';
import FullscreenExit from '@material-ui/icons/FullscreenExit';

import { toggleNavbar } from './../../../store/redux/actions';

class MapFullScreenButton extends Component {
  render() {
    const { toggleFullScreen, isVisible, color } = this.props;

    return (
      <MapButton
        icon={isVisible ? <Fullscreen /> : <FullscreenExit />}
        callback={toggleFullScreen}
        tooltip={isVisible ? "Hide navigation bar" : "Show navigation bar"}
        color={color}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isVisible: state.ui.navbarIsVisible,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleFullScreen: () => dispatch(toggleNavbar.call()),
  };
};

const MapFullScreenButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MapFullScreenButton);

export default MapFullScreenButtonContainer;
