import {
    compose,
    branch,
    withState,
    withHandlers,
    renderNothing,
    lifecycle
} from 'recompose';
import withLeaflet from './../../../../common/Leaflet/withLeaflet';
import { connect } from 'react-redux';
import { Colors } from './../../../../aquaplot/colors';

import L from 'leaflet';

const deviationPathStyle = () => {
    return {
        color: Colors.aqp_sundown_dark, 
        dashArray: "5 5", 
        weight: 2,
    }
}

const mapStateToProps = state => {
    return {
        deviationFeatureCollection: state.routing.deviationFeatureCollection
    }
}

const Handlers = compose(
    withLeaflet,
    connect(mapStateToProps),
    withHandlers({
        defaultDeviationsObject: () => () => {
            return { 
                geojsonData: null,
                geojson: null,
                map: null, 
            };
        }
    }),
    withState('deviation', 'setDeviation', ({ defaultDeviationsObject }) => defaultDeviationsObject()),
    withHandlers({
        updateDeviation: ({
            deviationFeatureCollection,
            deviation: {
                geojsonData,
                map,
                geojson
            },
            setDeviation,
            leafletMap,
            defaultDeviationsObject,
        }) => () => {
            const mapsChanged = leafletMap !== map;
            const deviationDataChanged = JSON.stringify(deviationFeatureCollection) !== JSON.stringify(geojsonData);

            if(leafletMap && (mapsChanged || deviationDataChanged)){
                if(geojson && map){
                    //clear existing
                    map.removeLayer(geojson);
                }

                const fg = L.geoJSON(deviationFeatureCollection, {
                    style: deviationPathStyle
                });
                fg.addTo(leafletMap);
                setDeviation({ map: leafletMap, geojson: fg, geojsonData: deviationFeatureCollection });
                
            }
            else if(!leafletMap && (map || geojsonData || geojson)){
                setDeviation(defaultDeviationsObject());
            }
        }
    }),
    lifecycle({
        componentDidMount(){
            this.props.updateDeviation();
        },
        componentDidUpdate(){
            this.props.updateDeviation();
        },
        componentWillUnmount(){
            this.props.updateDeviation();
        },
    }),
    branch(() => true, renderNothing)
)

export default Handlers;