import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateWaypointProperties } from '../../../store/redux/actions';
import styled from 'styled-components';
import CoordinatesSection from './../../../common/valuefields/CoordinatesSection';
import CountrySection from './../../../common/valuefields/CountrySection';
import TypeSection from './../../../common/valuefields/TypeSection';
import NameSection from './../../../common/valuefields/NameSection';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0px 10px;

  & > *{
    margin: 10px;
  }
`

class LocationContext extends Component {
  state = {
    name: '',
    locode: '',
    country: '',
    type: ''
  };

  componentDidMount() {
    // console.log(this.props)
    this.setState({ 
      name: this.props.name, 
      locode: this.props.locode,
      country: this.props.country || this.props.eez_country_code || '',
      type: this.props.type
    });
  }
  componentWillUnmount() {
    //saving (potential) changes
    const { updateWaypoint, ...rest } = this.props;
    const { name, locode, country, type } = this.state;
    updateWaypoint(Object.assign({}, rest, { name, locode, country, type }));
  }
  render() {
    const {
      latlng,
      //type,
      //status,
    } = this.props;

    const { name, locode, country, type } = this.state;

    return (
      <Wrapper>
        {/* Name */}
        <NameSection
          name={name}
          onNameChange={(e) => this.setState({ name: e.target.value })}
          locode={locode}
          onLocodeChange={(e) => this.setState({ locode: e.target.value })}
        />
        <TypeSection 
          type={type}
          onTypeChange={(value) => {
            this.setState({ type: value ? value.value : '' })
          }}
        />
        <CountrySection 
          countryId={country} 
          onCountryChange={(value) => {
            this.setState({ country: value && value.value && value.value.id ? value.value.id : '' })
          }} 
        />
        
        <CoordinatesSection latlng={latlng} />
      </Wrapper>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateWaypoint: waypoint => {
      dispatch(updateWaypointProperties.call(waypoint));
    },
  };
};

const LocationContextContainer = connect(null, mapDispatchToProps)(
  LocationContext
);

export default LocationContextContainer;
