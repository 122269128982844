import {
  compose,
  withHandlers,
  withState,
  renderComponent,
  branch,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import NoFirebase from './common/NoFirebase';
import { createMatrix } from './../../services/matrices';

const mapStateToProps = state => {
  return {
    hasPrivilege: state.firestore.hasPrivilege,
    matrices: state.matrices.matrices,
    firebaseDb: state.firestore.db,
    firebaseId: state.firestore.userId
  };
};

const VesselsTabHandlers = compose(
  connect(
    mapStateToProps
  ),
  withState('createdMatrices', 'setCreatedMatrices', []),
  withProps(({
    matrices,
    createdMatrices
  }) => ({
    pendingMatrices: createdMatrices.filter(({id}) => matrices.findIndex(m => m.id === id) === -1)
  })),
  withHandlers({
    matrixLink: ({
      match: { path }
    }) => (id) => {
      return [
        path,
        id && `/${encodeURIComponent(id.replace(/\s+/g, "-"))}`,
      ].join('');
    },
    createNewMatrix: ({
      firebaseDb,
      firebaseId,
      createdMatrices,
      setCreatedMatrices
    }) => (name) => {
      createMatrix(firebaseDb, firebaseId, name).then(id => {
        if(id){
          setCreatedMatrices(createdMatrices.concat({
            id,
            name
          }));
        }
      });
    }
  }),
  branch(({ hasPrivilege }) => !hasPrivilege, renderComponent(NoFirebase)),
);

export default VesselsTabHandlers;
