import { takeLatest, select } from 'redux-saga/effects'
import { 
  anyAuth, 
  requestJointWarCommitteeAreas
} from './../redux/actions'
import { singleAsync } from './saga-helper.js'
import { getJwcAllCall } from './aqp-api-helper.js'

const PolygonGeometry = {
    "type": "Polygon",
    "coordinates": []
};

const GeojsonFeature = {
    "type": "Feature",
    "properties": {},
    "geometry": PolygonGeometry,
};

const GeojsonFeatureCollection = {
    "type": "FeatureCollection",
    "features": [],
};

const success_transform = (response) => {
    let coll = Object.assign({}, GeojsonFeatureCollection);
    const areas = response.jwc;
    
    for(let key of Object.keys(areas)){
        const geometry = Object.assign({}, PolygonGeometry, {"coordinates": [areas[key]]});
        const feature = Object.assign({}, GeojsonFeature, { "geometry": geometry });
        coll.features.push(Object.assign({}, feature));
    }

    //console.log(JSON.stringify(coll));

    return coll;
} 

function* getAllJwcAreas(action) {
  const jointWarCommitteeAreas = yield select(state => state.routing.jointWarCommitteeAreas);
  if(jointWarCommitteeAreas && jointWarCommitteeAreas.type) return;

  yield singleAsync(getJwcAllCall, requestJointWarCommitteeAreas.SUCCEEDED, requestJointWarCommitteeAreas.FAILED, action.payload, 0, success_transform);
}

export default function* getAllJointWarCommitteeAreasSaga() {
  yield takeLatest(anyAuth.SUCCEEDED, getAllJwcAreas);
}
