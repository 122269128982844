import { aquaplotStore } from '../../index';
import {
    FETCH_USER_ITINERARIES_SUCCEEDED,
    FETCH_USER_ITINERARIES_FAILED,
} from '../../types';
import { 
    ItineraryAdded,
    ItineraryRemoved
  } from './../../redux/actions'

const itinerariesMetadataListener = doc => {
    if (doc.exists) {
        aquaplotStore.dispatch({
            type: FETCH_USER_ITINERARIES_SUCCEEDED,
            payload: doc.data(),
        });
    } else {
        aquaplotStore.dispatch({
            type: FETCH_USER_ITINERARIES_FAILED,
            payload: 'No such document!',
        });
    }
}

const itinerariesMetadataErrorHandler = error => {
    console.log('Error getting document:', error);
    aquaplotStore.dispatch({
        type: FETCH_USER_ITINERARIES_FAILED,
        payload: `Error getting document: ${error}`,
    });
}

const itinerariesSnapShotHandler = (snapshot) => {
    let ids = [];

    snapshot.docChanges().forEach(({ type, doc, ...other }) => {
        // console.log(doc.id)
        if (type === "added") {
            ids.push(doc.id)
            
            // console.log("New itinerary ", doc, other);
            aquaplotStore.dispatch({type: ItineraryAdded.REQUESTED, payload: doc});
        }
        if (type === "removed") {
            aquaplotStore.dispatch({type: ItineraryRemoved.REQUESTED, payload: doc.id});
        }
    });
    if(ids.length === snapshot.size){
        const {
            userItineraries: { itinerariesMetadata: meta },
          } = aquaplotStore.getState();
        // console.log(ids, meta, Object.keys(meta))
        Object.keys(meta).filter(id => !ids.includes(id)).map(staleId => 
            aquaplotStore.dispatch({type: ItineraryRemoved.REQUESTED, payload: staleId})
        )
    }
}

export function* bootstrapItinerariesMetadata(userDoc){
    console.log('bootstrap itineraries')
    yield userDoc.collection('itineraries')
        .doc('metadata')
        .onSnapshot(itinerariesMetadataListener, itinerariesMetadataErrorHandler);

    yield userDoc.collection('itineraries')
        .where("version", ">", 0).onSnapshot(itinerariesSnapShotHandler)
}