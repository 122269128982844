import * as actions from './../actions';
import { update } from './helper';

const initialBunker = {
  isInitialized: false,
  isPremiumData: false,
  columnDefinitions: [],
  rowData: [],
  stats: {},
  portsInBuffer: [],
  statsByGrade: [],
  requestingBunkerPlan: false,
  validations: [],
  allPricesByPort: [],
  allStatsByGrade: []
};

function bunkerReducer(bunkerState = initialBunker, { type, payload }) {
  switch (type) {
    case actions.updateBunkerPrices.type:
        return update(bunkerState, {
            columnDefinitions: payload.columnDefinitions,
            rowData: payload.rowData,
            isPremiumData: payload.isPremiumData,
            isInitialized: true
        });
    case actions.updateBunkerStats.type:
        return update(bunkerState, {
            stats: payload
        });

    case actions.updateBunkerPortsInBuffer.type:
        return update(bunkerState, payload);

    case actions.updateBunkerPorts.type:
      return update(bunkerState, payload);

    case actions.clearBuffer.type:
      return update(bunkerState, {
        portsInBuffer: [],
        statsByGrade: [],
        validations: []
      });

    case actions.CreateBunkerPlan.REQUESTED:
      return update(bunkerState, {
        requestingBunkerPlan: true
      });

    case actions.CreateBunkerPlan.SUCCEEDED:
      // console.log('payload', payload);
      return update(bunkerState, {
        requestingBunkerPlan: false,
        validations: bunkerState.validations.concat(payload.validations)
      });

    case actions.CreateBunkerPlan.FAILED:
        return update(bunkerState, {
          requestingBunkerPlan: false
        });

    default:
      return bunkerState;
  }
}

export default bunkerReducer;
