import React from 'react';
import Grid from '@material-ui/core/Grid';
import { StyledHeading } from '../styledComponents';
import SingleFaq from '../singleFaq';

const FaqGroupPure = ({ faqs, sectionHeading }) => {
  return (
    <Grid container direction="column">
      <StyledHeading variant="h5">{sectionHeading}</StyledHeading>
      {faqs.map((faq, idx) => (
        <SingleFaq
          key={`faq-${idx}`}
          faq={faq}
        />
      ))}
    </Grid>
  );
};

export default FaqGroupPure;
