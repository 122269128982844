import React from 'react';
import { TopTag } from './styledComponents';

const CardPure = props => {
  const { tagname, children, ...other } = props;
  // console.log(props)

  return <TopTag {...other}>{children || tagname}</TopTag>;
};
export default CardPure;
