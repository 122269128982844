import styled from 'styled-components';

export const TopTag = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({backgroundColor}) => backgroundColor || '#298f8d'};
  color: white;
  padding: 2px 30px;
  min-width: 100px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: center;
  text-transform: capitalize;
  font-size: x-small;
`;
