import React from 'react';
import LeafletContext from './../Context';

const LeafletProvider = ({
    children,
    leafletObj
}) => {
    return (
        <LeafletContext.Provider value={leafletObj}>
            { children }
        </LeafletContext.Provider>
    )   
}

export default LeafletProvider;