import React from 'react';
import FileDropImport from './../../../common/FileDropImport';
import AsyncButton from './../../../common/AsyncButton';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {
    GridWrapper,
    Wrapper,
    Toggle,
    StyledFormControl,
    ErrorText
} from './styledComponents';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Stepper from './../common/Stepper';
import LinearProgress from '@material-ui/core/LinearProgress';


const ImportField = ({
    fileHandler
}) => {
    return (
        <div style={{
            backgroundColor: 'cadetblue',
            color: 'white',
            fontVariantCaps: 'petite-caps',
            margin: 10
        }}>
            <FileDropImport  
                labelProps={{
                    style: {
                        textAlign: 'center'
                    }
                }}
                rootProps={{
                    style: {
                        height: 120,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }
                }}
                allowFiletypes={['csv']}
                accept={['.csv']}
                fileHandler={fileHandler}
            />
        </div>
    )
}

const ClearData = ({
    handleClick
}) => {
    return (
        <Button variant="contained" style={{alignSelf: 'center'}} onClick={handleClick}>Clear Data</Button>
    )
}

const SubmitNodes = ({
    handleClick,
    selectedRows,
    submitting
}) => {
    return (
        <AsyncButton
            waiting={submitting}
            success={false}
            failure={false}
            disabled={selectedRows.length === 0} 
            rootStyle={{alignSelf: 'center'}} 
            onClick={handleClick}
            label={`Add ${selectedRows.length} Waypoints To Matrix`}
        />
    )
}


const Pure = ({
    fileHandler,
    rowData,
    columnDefs,
    error,
    // handleSelection,
    clearData,
    mappedColForName,
    mappedColForLng,
    mappedColForLat,
    setColForName,
    setColForLng,
    setColForLat,
    extractColumnDefs,
    extractRowData,
    frameworkComponents,
    activeStep,
    updateActiveStep,
    validatedRowData,
    numberOfLocs,
    numberOfValidatedLocs,
    onSelectionChange,
    selectedRows,
    submitting
}) => {
    return (
        <Wrapper>
            <Stepper 
                nSteps={3}
                activeStep={activeStep}
                setActiveStep={updateActiveStep}
                disableNext={(activeStep === 0 && rowData.length === 0)}
            />
            <Toggle hide={activeStep !== 0 ? 'true' : 'false'}>
                <Typography style={{padding: 20}}>First, add waypoints by importing a CSV file. Go to the next step if you are happy with how it got loaded.</Typography>
                { rowData.length === 0 ? 
                    <React.Fragment>
                        <ImportField fileHandler={fileHandler} />
                        <ErrorText>{ error }</ErrorText>
                    </React.Fragment> :
                    <ClearData handleClick={clearData}/>
                } 
            </Toggle>
            <Toggle hide={activeStep !== 1 ? 'true' : 'false'}>
                <Typography style={{padding: 20}}>Please select the appropriate columns for data extraction.</Typography>
                <form autoComplete="off">
                    <StyledFormControl>
                        <InputLabel htmlFor="waypoint-name">Name</InputLabel>
                        <Select
                            value={mappedColForName}
                            onChange={(e) => setColForName(e.target.value)}
                            inputProps={{
                                name: 'name',
                                id: 'waypoint-name',
                            }}
                        >
                        {
                            columnDefs.map(col => (
                                <MenuItem key={col.field} value={col.field}>{col.headerName}</MenuItem>
                            ))
                        }
                        </Select>
                    </StyledFormControl>
                    <StyledFormControl>
                        <InputLabel htmlFor="waypoint-lat">Latitude</InputLabel>
                        <Select
                            value={mappedColForLat}
                            onChange={(e) => setColForLat(e.target.value)}
                            inputProps={{
                                name: 'lat',
                                id: 'waypoint-lat',
                            }}
                        >
                        {
                            columnDefs.map(col => (
                                <MenuItem key={col.field} value={col.field}>{col.headerName}</MenuItem>
                            ))
                        }
                        </Select>
                    </StyledFormControl>
                    <StyledFormControl>
                        <InputLabel htmlFor="waypoint-lng">Longitude</InputLabel>
                        <Select
                            value={mappedColForLng}
                            onChange={(e) => setColForLng(e.target.value)}
                            inputProps={{
                                name: 'lng',
                                id: 'waypoint-lng',
                            }}
                        >
                        {
                            columnDefs.map(col => (
                                <MenuItem key={col.field} value={col.field}>{col.headerName}</MenuItem>
                            ))
                        }
                        </Select>
                    </StyledFormControl>    
                </form>
            </Toggle>
            <Toggle hide={activeStep !== 2 ? 'true' : 'false'}>
                <Typography style={{padding: 20}}>Waypoints are being validated. To continue, please select the waypoints that you would like to import (look at the 'Moved By' column to verify that the auto-correction is acceptable).</Typography>
                <LinearProgress variant="determinate" value={Math.ceil(numberOfValidatedLocs / numberOfLocs) * 100} />
                <SubmitNodes 
                    submitting={submitting}
                    selectedRows={selectedRows} 
                    handleClick={() => console.log('clicked')} 
                />
            </Toggle>
            {
                activeStep < 2 &&
                <GridWrapper className={`ag-theme-balham`}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowData={rowData}
                        // onCellMouseDown={handleSelection}
                        // onRowClicked={handleSelection}
                    />
                </GridWrapper>
            }
            {
                activeStep > 1 &&
                <GridWrapper className={`ag-theme-balham`}>
                    <AgGridReact
                        columnDefs={extractColumnDefs}
                        rowData={validatedRowData || extractRowData}
                        // onCellMouseDown={handleSelection}
                        // onRowClicked={handleSelection}
                        frameworkComponents={frameworkComponents}
                        rowSelection={'multiple'}
                        isRowSelectable={row => row.data.movedBy !== null && row.data.movedBy >= 0}
                        onSelectionChanged={onSelectionChange}
                    />
                </GridWrapper>
            }
        </Wrapper>
    )
}

export default Pure;