import React from 'react';
import { Polygon as Icon } from './../../../../illustrations/flaticon/polygon';
import MapButton from './../MapButton';

const CustomAreaButtonPure = ({
    handleButtonClick
}) => {
    return (
        <MapButton
            icon={<Icon />}
            callback={handleButtonClick}
            tooltip={"Draw custom area"}
        />
    )
}

export default CustomAreaButtonPure;