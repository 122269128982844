import styled from 'styled-components';

export const ControlContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 10px;
    bottom: ${({bottom}) => bottom || '10px'};
    top: 15px;
    z-index: 700;
    justify-content: flex-start;
    pointer-events: none;
`

export const FlexGrowDiv = styled.div`
    flex-grow: 1;
`

export const ControlsSection = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({background}) => background || 'transparent'};
    border-radius: ${({borderRadius}) => borderRadius || '0px'};
`