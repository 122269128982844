import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export const ContactGridContainer = styled(Grid)`
  height: 100%;
`;

export const GridContainer = styled(ContactGridContainer)`
  height: auto;
  overflow-y: auto;
`;

export const StyledHeading = styled(Typography)`
  color: #093352 !important;
  margin: 3% 0% 1% 0 !important;
  padding-top: 1em;
`;

export const StyledGrid = styled(Grid)`
  padding: 2%;
  background-color: #fafafa;
  margin-bottom: 4% !important;
`;

export const FullHeightGrid = styled(StyledGrid)`
  height: 100%;
  margin-bottom: 0% !important;
`;

export const StyledContactGrid = styled(Grid)`
  padding: 2%;
`;
