import React, { Component } from 'react';
import { connect } from 'react-redux';
import { removeWaypoint, clearContextContent } from '../../../store/redux/actions';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

const styles = {
  button: {
    marginRight: 10,
  },
};

class LocationActions extends Component {
  render() {
    const { waypoint, removeWaypoint, classes } = this.props;

    return (
      <Button
        onClick={() => removeWaypoint(waypoint.uuid)}
        className={classes.button}
        variant="contained"
        size="small"
      >
        Remove
      </Button>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    removeWaypoint: id => {
      dispatch(removeWaypoint.call(id));
      dispatch(clearContextContent.call());
    },
  };
};

const LocationActionsContainer = connect(null, mapDispatchToProps)(
  withStyles(styles)(LocationActions)
);

export default LocationActionsContainer;
