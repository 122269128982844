import {
    compose,
    lifecycle,
    withState,
    withHandlers,
    renderNothing,
    branch
} from 'recompose';
import { connect } from 'react-redux';
import withLeaflet from './../../../common/Leaflet/withLeaflet';
import { tileLayer } from 'leaflet';

const mapStateToProps = state => {
    return {
        activeBaseLayer: state.map.activeBaseLayer,
    };
};

const TileLayerHandlers = compose(
    withLeaflet,
    connect(mapStateToProps, null),
    withState('layerObj', 'setLayer', { layer: null, m: null }),
    withHandlers({
        updateLayer: ({
            activeBaseLayer: l, 
            leafletMap: map,
            setLayer, 
            layerObj: {
                layer,
                m
            }
        }) => () => {
            if(l && l.url && map){
                if(layer && map === m){
                    layer.setUrl(l.url);
                }
                else{
                    const layer = tileLayer(l.url);
                    layer.addTo(map);
                    setLayer({
                        layer,
                        m: map
                    });
                }
            }
        }
    }),
    lifecycle({
        componentDidMount(){
            const { updateLayer, isWindyMap } = this.props;
            !isWindyMap && updateLayer();
        },
        componentDidUpdate(){
            const { updateLayer, isWindyMap } = this.props;
            !isWindyMap && updateLayer();
        }
    }),
    branch(() => true, renderNothing)
);

export default TileLayerHandlers;
  