import {
    compose,
    withProps
} from 'recompose';

const Handlers = compose(
    withProps(({
        actions,
        mode,
        modes
    }) => ({
        activeActions: (actions && actions[mode]) || [],
        showModeTag: modes && modes[mode] && modes[mode].label ,
    })),
    withProps(({
        showModeTag,
        mode,
        modes
    }) => ({
        modeTag: showModeTag ? modes[mode].label : '',
        modeColor: showModeTag && modes[mode].color ? modes[mode].color : 'gray'
    }))
);

export default Handlers;