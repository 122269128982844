import {
    compose,
    withHandlers,
    withProps,
    branch,
    renderNothing
} from 'recompose';
import { connect } from 'react-redux';
import { 
    exitCustomAreaMode, 
    activateCustomAreaAddMode 
} from './../../../../store/redux/actions';

const mapStateToProps = state => {
    return {
        mode: state.map.mode,
        modes: state.map.modes,
        showCustomAreaControl: state.map.showCustomAreaControl
    }
}

const mapDispatchToProps = dispatch => {
    return {
        exitCustomAreaMode: () => dispatch(exitCustomAreaMode.call()),
        activateCustomAreaAddMode: () => dispatch(activateCustomAreaAddMode.call())
    }
}

const Handlers = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withProps(({
        mode,
        modes
    }) => ({
        active: mode === modes.customArea || mode === modes.deleteArea
    })),
    withHandlers({
        deactivateWaypointAdd: ({
            activateCustomAreaAddMode,
        }) => () => {
            activateCustomAreaAddMode()
        },
        reactivateWaypointAdd: ({
            exitCustomAreaMode,
        }) => () => {
            exitCustomAreaMode()
        }
    }),
    withHandlers({
        handleButtonClick: ({
            active,
            deactivateWaypointAdd,
            reactivateWaypointAdd
        }) => () => {
            if(!active){
                deactivateWaypointAdd();
            }
            else{
                reactivateWaypointAdd();
            }
        },
    }),
    branch(({ showCustomAreaControl }) => !showCustomAreaControl, renderNothing)
);

export default Handlers;