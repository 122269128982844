import { select } from 'redux-saga/effects';
import { aquaplotStore } from './../../index';
import { updateBunkerPrices, updateBunkerStats } from './../../redux/actions';

const bunkerPricesListener = (isPremiumData) => doc => {
    // console.log(doc)
    if (doc.exists) {
        const data = doc.data();
        // console.log(data);
        aquaplotStore.dispatch(updateBunkerPrices.call(Object.assign({}, data, { isPremiumData: isPremiumData })));
    } else {
        console.error('no bunker prices document');
    }
}

const bunkerStatsListener = doc => {
    // console.log(doc)
    if (doc.exists) {
        const data = doc.data();
        // console.log(data);
        aquaplotStore.dispatch(updateBunkerStats.call(data.stats ? data.stats : data));
    } else {
        console.error('no bunker stats document');
    }
}

const errorHandler = error => {
    console.error('Error getting document:', error);
}

const decodeJwtPayload = (jwt) => JSON.parse(window.atob(jwt.split('.')[1]))

export function* bootstrapBunkerEx(){
    if (process.env.REACT_APP_SHOW_DEBUG === 'true') console.log('bootstrap premium bunker-ex')
    const { aqpToken, db, isInitialized } = yield select(state => 
        Object.assign({}, {
            aqpToken: state.user.aqpToken,
            db: state.firestore.db,
            isInitialized: state.bunker.isInitialized
        })
    );
    // console.log(db, aqpToken, isInitialized)
    if(!db || !aqpToken || isInitialized) return;
    const {
        has_bunker_ex_premium_account: hasPremium
    } = decodeJwtPayload(aqpToken);
    // console.log(hasPremium)
    if(hasPremium){
        yield db.doc('app/explorer/data/premiumBunker').onSnapshot(bunkerPricesListener(true), errorHandler);
    }
    else yield db.doc('app/explorer/data/freeBunker').onSnapshot(bunkerPricesListener(false), errorHandler);

    yield db.doc('bunker-ex/stats').onSnapshot(bunkerStatsListener, errorHandler);
}