import axios from 'axios';
import { functionWithApiEndpoints } from './../store/withApiEndpoints';

const axiosConfig = {
  mode: 'no-cors',
  withCredentials: true,
};

export const getExplorerPlans = functionWithApiEndpoints(({accountEndpoint}) => {
  const url = `${accountEndpoint}/product/explorer/plans`;
  return axios
    .get(url, axiosConfig)
    .then(res => {
        const monthly_plans = res.data.plans.filter((plan) => plan.price > 0 && plan.is_monthly);
        const annual_plans = res.data.plans.filter((plan) => plan.price > 0 && plan.is_annually);

        return (Object.assign({}, res.data, {
            included_with_all_paid_plans: JSON.parse(res.data.included_with_all_paid_plans),
            monthly_plans,
            annual_plans,
            current_plan: res.data.current_plan
        }));
    })
    .catch(err => {
      throw err;
    });
});