import { takeEvery, takeLatest } from 'redux-saga/effects'
import { AisSignal, AisRefresh } from './../redux/actions'
import { getLatestAisCall, refreshAis } from './aqp-api-helper.js';
import { singleAsync } from './saga-helper.js'

function* getAisSignal(action) {
  yield singleAsync(getLatestAisCall, AisSignal.SUCCEEDED, AisSignal.FAILED, action.payload, 0);
}

export default function* aisSignalSaga() {
  yield takeEvery(AisSignal.REQUESTED, getAisSignal);
}

function* refreshAisData(action) {
  const imoNumbers = action.payload;
  yield singleAsync(refreshAis, AisRefresh.SUCCEEDED, AisRefresh.FAILED, { imoNumbers: imoNumbers }, 0);
}

export function* aisRefreshSaga() {
  yield takeLatest(AisRefresh.REQUESTED, refreshAisData);
}