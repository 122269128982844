import React from 'react';

import { Colors } from './../../../aquaplot/colors';

import BaseMarker from './BaseMarker';
import LeafletMarker from './LeafletMarker';


const LoadingMarkerPure = ({latlng, ...rest}) => {
  return (
    <LeafletMarker
      {...rest}
      latlng={latlng}
      markerComponent={<BaseMarker backgroundColor={Colors.aqp_sundown} loading />}
    />
  );
}


export default LoadingMarkerPure;