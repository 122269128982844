import {
    FETCH_USER_MATRIX_SUCCEEDED,
    FETCH_USER_MATRICES_REQUESTED
  } from '../../types';
  
  const initialMatrices = {
    matrices: [],
    listenerInstantiated: false,
  };

  const byCreatedAtDesc = (a,b) => {
      return a.createdAt < b.createdAt;
  }
  
  export function matricesReducer(state = initialMatrices, action) {
    switch (action.type) {
      case FETCH_USER_MATRIX_SUCCEEDED:
        return {
          ...state,
          matrices: state.matrices.filter(m => m.id !== action.payload.id).concat(action.payload.nodes ? action.payload : []).sort(byCreatedAtDesc),
          listenerInstantiated: true
        };
      case FETCH_USER_MATRICES_REQUESTED:
        return initialMatrices;
      default:
        return state;
    }
  }
  