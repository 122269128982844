import {
    compose,
    lifecycle,
    withState,
    withHandlers,
    branch,
    renderNothing
} from 'recompose';
import withLeaflet from './../../../../common/Leaflet/withLeaflet';
import { connect } from 'react-redux';
import { addWaypoint } from '../../../../store/redux/actions';
import { /*getValidateCall*/ postValidateCall as apiValidateCall } from '../../../../store/sagas/aqp-api-helper';

import UUID from 'uuid-js';

const getWaypoint = (latlng, eca, eca_name, timezone, eez_country_code, sea_name) => {
    // console.log(sea_name)
    return {
      name: sea_name || 'Custom',
      latlng: latlng,
      locode: null,
      country: null,
      type: 'water',
      uuid: UUID.create().toString(),
      draggable: true,
      eca: eca || false,
      eca_name: eca_name || '',
      timezone: timezone,
      eez_country_code: eez_country_code || '',
      sea_name: sea_name || ''
    };
  }

const errorResponse = () => ({
    status: 'error',
    validatedLatlng: null,
    eca: false,
    eca_name: '',
})

const parseValidateResponse = (response) => {
    if(response && response.suggestion){
        return (
            {
                status: 'ok',
                validatedLatlng: {
                    lat: response.suggestion[1], 
                    lng: response.suggestion[0]
                },
                eca: response.eca,
                eca_name: response.eca_name,
                timezone: response.timezone_of_validated,
                eez_country_code: response.eez_country_code,
                sea_name: response.sea_name
            }
        );
    }

    return errorResponse();
}

const validateCall = (latlng) => {
    return Promise.race([
        apiValidateCall({ latlng }).then(parseValidateResponse),
        new Promise(resolve => setTimeout(resolve, 5000)).then(() => errorResponse())
    ]).then((winner) => {
        // console.log('winner is...', winner);
        return { ...winner, latlng: latlng };
    });
    
}

const mapStateToProps = (state) => {
    return {
      needsConfirmation: state.map.requireDoubleConfirm,
      disabled: state.map.mode !== state.map.modes.automatic || (state.map.leafletObj.hideData === true),
      autovalidate: state.map.mode === state.map.modes.automatic && !state.map.requireDoubleConfirm
    }
  }
  
const mapDispatchToProps = (dispatch) => {
    return {
        addWaypoint: (waypoint, segment_id) => {
            dispatch(addWaypoint.call(waypoint, segment_id))
        },
    }
}
  
const LeafletMarkerHandlers = compose(
    withLeaflet,
    connect(mapStateToProps, mapDispatchToProps),
    withState('disabledState', 'setDisabledState', ({disabled}) => disabled),
    withState('map', 'setMap', null),
    withState('coords', 'setCoords', { latlng: null, validatedLatlng: null, segment_id: null }),
    withHandlers({
        reset: props => () => {
            const { setCoords } = props;
            setCoords({ 
                latlng: null, 
                validatedLatlng: null, 
                segment_id: null,
                eca: null,
                eca_name: null,
                timezone: null,
                eez_country_code: '',
                sea_name: ''
            });
        }
    }),
    withHandlers({
        handleAddWaypoint: props => (validatedLatlng, segment_id, eca, eca_name, timezone, eez_country_code, sea_name) => {
            const { addWaypoint, reset } = props;
        
            addWaypoint(getWaypoint(validatedLatlng, eca, eca_name, timezone, eez_country_code, sea_name), segment_id);
            reset();
        }
    }),
    withHandlers({
        validate: props => (latlng, segment_id) => {
            const { 
                needsConfirmation,
                setCoords,
                reset,
                handleAddWaypoint
            } = props;

            validateCall(latlng)
                .then(({ status, validatedLatlng, eca, eca_name, timezone, eez_country_code, sea_name }) => {
                    // console.log('received', status, validatedLatlng)
                    if(status === 'ok'){
                        if(needsConfirmation){
                            // console.log(sea_name)
                            setCoords({ latlng, validatedLatlng, segment_id, eca, eca_name, timezone, eez_country_code, sea_name });
                        }
                        else handleAddWaypoint(validatedLatlng, segment_id, eca, eca_name, timezone, eez_country_code, sea_name);
                    }
                    else reset();
                });
        },
    }),
    withHandlers({
        onClickHandler: props => (e) => {
            // console.log('handling click', e)
            if(props.coords.latlng){
                const { lng, lat } = props.coords.latlng;
                if(e.latlng.lat === lat && e.latlng.lng === lng) return;
            }
            if(props.autovalidate){
                props.handleAddWaypoint(e.latlng, e.segment_id);
            }
            else{
                props.setCoords({latlng: e.latlng, validatedLatlng: null, segment_id: e.segment_id, eca: null, eca_name: '', timezone: '', eez_country_code: '', sea_name: '' });
                props.validate(e.latlng, e.segment_id);
            }
        },
    }),
    withHandlers({
        registerListener: props => () => {
            const { leafletMap: leaflet, onClickHandler } = props;
            if(leaflet){
                leaflet.off('aqpWaypointAdd', onClickHandler);
                leaflet.on('aqpWaypointAdd', onClickHandler);
            } 
        },
        registerOnClickListener: props => () => {
            const { leafletMap: leaflet, onClickHandler, setMap } = props;
            if(leaflet){
                // console.log('register onClick')
                leaflet.off('click', onClickHandler);
                leaflet.on('click', onClickHandler);
                setMap(leaflet);
            } 
        },
        unregisterOnClickListener: props => () => {
            const { leafletMap: leaflet, map, setMap, reset, onClickHandler, coords } = props;
            if(coords.latlng !== null) reset();
            leaflet && leaflet.off('click', onClickHandler);
            if(leaflet !== map) setMap(leaflet);
        },
    }),
    withHandlers({
        handleUpdate: props => () => {
            const {
                disabled,
                unregisterOnClickListener,
                registerOnClickListener,
                registerListener,
                setDisabledState
            } = props
            registerListener();

            if(disabled){
                unregisterOnClickListener();
            }
            else{
                registerOnClickListener();
            }
            setDisabledState(disabled);
          }
    }),
    lifecycle({
        componentDidMount(){
            const { handleUpdate } = this.props;
            handleUpdate();
        },
        componentDidUpdate(){
            const { handleUpdate, leafletMap, map, disabled, disabledState } = this.props;
            if(leafletMap !== map || disabled !== disabledState) handleUpdate();
        },
    }),
    branch(({ coords }) => !coords.latlng, renderNothing)
);

export default LeafletMarkerHandlers;
  