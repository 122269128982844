import React from 'react';
import {
  GridContainer,
  StyledContactGrid,
  FullHeightGrid,
} from './styledComponents';
import FaqGroup from './faqGroup/index';
import ContactUs from '../../common/ContactUs';

const FaqPure = props => {
  const { faqs } = props;

  return (
    <GridContainer container wrap="wrap">
      <FullHeightGrid item sm={6} xs={12}>
        {faqs && Object.keys(faqs).map(key => (
          <FaqGroup
            key={`faq-group-${key}`}
            sectionHeading={key}
            faqs={faqs[key]}
          />
        ))}
      </FullHeightGrid>
      <StyledContactGrid item sm={6} xs={12}>
        <ContactUs />
      </StyledContactGrid>
    </GridContainer>
  );
};

export default FaqPure;
