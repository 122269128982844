import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { WrapperDiv, Message } from './styledComponents';

const LoadingScreen = ({ message }) => {
  return (
    <WrapperDiv>
      <CircularProgress size={50} thickness={2.6} />
      <Message>{ message || 'Loading'}</Message>
    </WrapperDiv>
  );
};
export default LoadingScreen;
