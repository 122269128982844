import React from 'react';
import ClipboardContext from './../Context';

const ClipboardProvider = ({
    children,
    copyToClipboard,
    getFromClipboard
}) => {
    return (
        <ClipboardContext.Provider value={{
            getFromClipboard,
            copyToClipboard
        }}>
            { children }
        </ClipboardContext.Provider>
    )   
}

export default ClipboardProvider;