import { aquaplotStore } from './../../index';
import {
    FETCH_USER_LOCATIONS_SUCCEEDED,
    FETCH_USER_LOCATIONS_FAILED,
} from '../../types';

const locationsListener = doc => {
    if (doc.exists) {
        aquaplotStore.dispatch({
            type: FETCH_USER_LOCATIONS_SUCCEEDED,
            payload: doc.data().locations,
        });
    } else {
        aquaplotStore.dispatch({
            type: FETCH_USER_LOCATIONS_FAILED,
            payload: 'No such document!',
        });
    }
}

const locationsErrorHandler = error => {
    console.log('Error getting document:', error);
    aquaplotStore.dispatch({
        type: FETCH_USER_LOCATIONS_FAILED,
        payload: `Error getting document: ${error}`,
    });
}

export function* bootstrapLocations(userDoc){
    if (process.env.REACT_APP_SHOW_DEBUG === 'true') console.log('bootstrap locations')
    yield userDoc.collection('locations')
    .doc('metadata')
    .onSnapshot(locationsListener, locationsErrorHandler);
}