import {
    compose,
    withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { setHighlightedImo, setSingleVessel } from './../../../../store/redux/actions';

const mapStateToProps = (state) => {
    return{
        vessels: state.tracking.vessels,
        filterByFleet: state.tracking.filterByFleet,
        highlightedImo: state.tracking.highlightedImo,
        userFleets: state.userFleets,
        userVessels: state.userVessels,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setHighlighted: (imo) => {
            dispatch(setHighlightedImo.call(imo))
        },
        addWaypointCandidate: (vessel) => {
            dispatch(setSingleVessel.call(vessel))
        }
    }
}

const Handlers = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withProps(({
        vessels,
        userVessels: { vessels: userV },
        filterByFleet,
        userFleets: { fleets }
    }) => ({
        data: !filterByFleet || filterByFleet === 'All' || !fleets[filterByFleet]
            ? vessels.map(v => Object.assign({}, v, userV[v.imo] || {}))
            : fleets[filterByFleet].vessels.map(imo => Object.assign({}, vessels.find(v => v.imo === imo), userV[imo]))
    }))
);

export default Handlers;