import {
    compose,
    lifecycle,
    withState,
    withHandlers,
    withProps
} from 'recompose';
import { connect } from 'react-redux';
import withLeaflet from './../../common/Leaflet/withLeaflet';
import { setLeafletObject } from './../../store/redux/actions';
import L from 'leaflet';


const mapStateToProps = (state) => {
    return{
      mobile: state.ui.mobile,
      center: state.map.center,
      zoom: state.map.zoom,
      isDrawingMode: state.map.mode === state.map.modes.customArea,
      isDeleteMode: state.map.mode === state.map.modes.deleteArea,
      maxZoom: state.map.activeBaseLayer.maxZoom
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLeafletObject: (map) => {
            dispatch(setLeafletObject.call(map, false))
        }
    }
}
  
const MapHandlers = compose(
    withLeaflet,
    connect(mapStateToProps, mapDispatchToProps),
    withState('clientContainer', 'setClientContainer', { height: 0, width: 0 }),
    withState('map', 'setMap', null),
    withState('mapDiv', 'setMapDiv', null),
    withHandlers({
        forceMapRefresh: ({
            map
        }) => () => {
            // console.log('force refresh main leaflet map');
            map.invalidateSize();
        },
    }),
    withProps(({
        maxZoom
    }) => ({
        zoomLimit: maxZoom === undefined ? 10 : maxZoom
    })),
    withProps(({activeApplet, mobile}) => {
        const isOtherApplet = activeApplet && activeApplet.url !== '/app/map';
        const mobileOrWithoutMap = activeApplet && (!activeApplet.hasMap || mobile);
        return {
            hide: !activeApplet || (isOtherApplet && mobileOrWithoutMap),
        };
    }),
    withProps(({
        hide,
        isDrawingMode,
        isDeleteMode
    }) => ({
        showDrawingTools: !hide && (isDrawingMode || isDeleteMode)
    })),
    lifecycle({
        componentDidUpdate(){
            const {
                mapDiv,
                center,
                zoom,
                leafletMap: activeLeafletMap, 
                setLeafletObject, 
                map, 
                clientContainer, 
                forceMapRefresh, 
                setClientContainer,
                hide,
                setMap,
                zoomLimit
            } = this.props;

            if(!map){
                // console.log(center, zoom)
                const mapObj = L.map(mapDiv, {
                    center: center,
                    zoom: zoom,
                    attributionControl: false,
                    zoomControl: false,
                    minZoom: 1,
                    maxZoom: zoomLimit
                });
                setMap(mapObj);
            }
            else{
                if(!hide && activeLeafletMap !== map){
                    if(activeLeafletMap){
                        map.setView(activeLeafletMap.getCenter(), activeLeafletMap.getZoom());
                    }
                    else{
                        map.setView(center, zoom);
                    }
                    setLeafletObject(map);
                }
            
                const { clientHeight: height, clientWidth: width } = mapDiv;
                if( height !== clientContainer.height ||
                    width !== clientContainer.width){
                    forceMapRefresh();
                    setClientContainer({ height, width });
                }

                if(zoomLimit !== map.getMaxZoom()){
                    map.setMaxZoom(zoomLimit);
                }
            }
        }
    })
);

export default MapHandlers;
  