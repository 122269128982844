import React from 'react';

import BaseMarker from './BaseMarker';
import AddIcon from '@material-ui/icons/Add';
import ShipIcon from '@material-ui/icons/DirectionsBoat';
import { Colors } from '../../../aquaplot/colors';

const ConfirmMarkerPure = ({vesselType}) => {
  return(
    <BaseMarker
      backgroundColor={Colors.aqp_sundown}
      Icon={vesselType ? ShipIcon : AddIcon}
    />
  );
}

export default ConfirmMarkerPure;