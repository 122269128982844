import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';

export default function VerticalLinearStepper({ activeStep }) {

  return (
    <div>
      <Typography variant="overline">Status</Typography>
      <Stepper activeStep={2} orientation="vertical">
        {['Waiting for Waypoints', 'Processing', 'Ready'].map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
