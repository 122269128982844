import React from 'react';

import MapButton from './MapButton';
import UrlTriggerLink from '../../../common/UrlTriggerLink';
import SettingsIcon from '@material-ui/icons/Settings';

const MapSettingsButton = () =>{
  return (
    <UrlTriggerLink urlTrigger={'showMapSettings'} >
      <MapButton 
        icon={<SettingsIcon />}
        tooltip="Map settings" 
      />
    </UrlTriggerLink>
  );
}

export default MapSettingsButton;
