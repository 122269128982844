import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
    StyledFab
} from './../styledComponents';

const ExitPure = ({
    active,
    exitCustomAreaMode
}) => {
    return (
        <StyledFab 
            size="small"
            color={active ? "primary" : "default"} 
            aria-label="Exit Drawing Mode" 
            onClick={exitCustomAreaMode}
        >
            <CloseIcon />
        </StyledFab>
    )
}

export default ExitPure;