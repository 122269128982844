import { takeLatest } from 'redux-saga/effects';
import {
  emailAuth,
  USER_LOGOUT_REQUESTED as LOGOUT_REQUESTED,
  USER_LOGOUT_SUCCEEDED as LOGOUT_SUCCESS,
  USER_LOGOUT_FAILED as LOGOUT_FAILURE,
} from './../redux/actions';
import {
  postCredentialsCallNoCors as callType,
  deleteCredentialsCall,
} from './aqp-api-helper.js';
import { singleAsync } from './saga-helper.js';

function* getCredentials(action) {
  yield singleAsync(callType, emailAuth.SUCCEEDED, emailAuth.FAILED, action.payload, 0);
}

export default function* credentialsSaga() {
  yield takeLatest(emailAuth.REQUESTED, getCredentials);
}

function* deleteCredentials(action) {
  yield singleAsync(
    deleteCredentialsCall,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    action.payload,
    0
  );
}

export function* deleteCredentialsSaga() {
  yield takeLatest(LOGOUT_REQUESTED, deleteCredentials);
}

function* reloadApp(action){
  yield window.location.reload();
}

export function* reloadOnLogoutSaga(){
  yield takeLatest(LOGOUT_SUCCESS, reloadApp)
}