import styled from 'styled-components';

export const NoContentDiv = styled.div`
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  align-self: stretch;

  > * {
    margin-bottom: 2%;
  }
`;

export const IllustrationWrapper = styled.div`
  && {
    width: 40%;
    min-width: 200px;
  }
`

