import {
    compose,
    branch,
    renderNothing,
    withProps
} from 'recompose';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    return {
        countries: state.countries
    }
}

const CountryFieldHandlers = compose(
    connect(mapStateToProps),
    branch(({ countries: { loading }}) => loading, renderNothing),
    withProps(({
        countries: { countries: array },
        countryId
    }) => ({
        countries: array,
        country: array.find(v => v.value.id === countryId)
    })),
    withProps(({
        country
    }) => ({
        countryName: country && country.label ? country.label : 'Country Unknown',
        isEu: country && country.value && country.value.isEu
    })),
);

export default CountryFieldHandlers;