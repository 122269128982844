import { aquaplotStore } from './../../index';
import {
    FETCH_USER_VESSELS_SUCCEEDED,
    FETCH_USER_VESSELS_FAILED,
} from '../../types';

const vesselListener = doc => {
    if (doc.exists) {
        aquaplotStore.dispatch({
            type: FETCH_USER_VESSELS_SUCCEEDED,
            payload: doc.data().vessels,
        });
    } else {
        aquaplotStore.dispatch({
            type: FETCH_USER_VESSELS_FAILED,
            payload: 'No such document!',
        });
    }
}

const vesselErrorHandler = error => {
    if (process.env.REACT_APP_SHOW_DEBUG === 'true') {
        console.log('Error getting document:', error);
        aquaplotStore.dispatch({
          type: FETCH_USER_VESSELS_FAILED,
          payload: `Error getting document: ${error}`,
        });
    }
}

export function* bootstrapVessels(userDoc){
    if (process.env.REACT_APP_SHOW_DEBUG === 'true') console.log('bootstrap vessels')
    yield userDoc.collection('vessels')
    .doc('metadata')
    .onSnapshot(vesselListener, vesselErrorHandler);
}