import {
    compose,
    withState,
    withHandlers,
    lifecycle
} from 'recompose';

const Handlers = compose(
    withState('checked', 'setChecked', true),
    withState('intervalHook', 'setIntervalHook', null),
    withHandlers({
        flip: ({
            checked,
            setChecked
        }) => () => {
            setChecked(!checked);
        }
    }),
    lifecycle({
        componentDidMount(){
            const {
                setIntervalHook,
                flip
            } = this.props;
            setIntervalHook(setInterval(flip, 1000));
        },
        componentWillUnmount(){
            const { intervalHook } = this.props;
            clearInterval(intervalHook)
        }
    })
);

export default Handlers;