import React from 'react';
import Context from './Context';
import { aquaplotStore } from './index';

export const functionWithApiEndpoints = fn => (props) => {
    const state = aquaplotStore.getState();
    const endpoints = {
        accountEndpoint: state.dev.accountEndpoint,
        apiEndpoint: state.dev.apiEndpoint,
        aqpToken: state.user.aqpToken
    }
    const response = fn({ 
      ...props, 
      ...endpoints 
    });
    // console.log('response', response)
    return response;
  }

const withApiEndpoints = Component => (props) => {
    return (
        <Context.Consumer>
        {
            endpoints => <Component {...props} {...endpoints} />   
        }
        </Context.Consumer>
    )
  }
  
export default withApiEndpoints;