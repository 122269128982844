import { select, delay } from 'redux-saga/effects'

import { 
    accountHealthcheck,
    apiHealthcheck
  } from './../redux/actions'

import { singleAsync } from './saga-helper.js'
import { accountHealth, apiHealth } from './aqp-api-helper.js'


export default function* healthchecks() {
    
    while(true){
        const state = yield select(state => state);
        const { connectivity } = state;
        const { updateInterval, online } = connectivity;
        
        yield delay(updateInterval ? updateInterval : 60e3);
        
        if(online){
            yield singleAsync(accountHealth, accountHealthcheck.SUCCEEDED, accountHealthcheck.FAILED, {});
            yield singleAsync(apiHealth, apiHealthcheck.SUCCEEDED, apiHealthcheck.FAILED, {});
        }
        
    }
}