import React from 'react';
import WaypointCandidate from './candidate/WaypointCandidate';
import VesselCandidate from './candidate/VesselCandidate';
import MapControls from './controls';
import MapData from './data';

import TileLayer from './TileLayer';
import MapSettings from './Settings';
import UrlTriggeredDrawer from '../../common/UrlTriggeredDrawer';
import CustomAreaControls from './controls/CustomAreaControls';
import { StyledMap } from './styledComponents'; 

import "leaflet/dist/leaflet.css";


const LeafletMapPure = (props) => {
    const {
        hide,
        mapDiv,
        setMapDiv,
        showDrawingTools
    } = props;

    return (
        <React.Fragment>
            <StyledMap 
                ref={(m) => !mapDiv && m && setMapDiv(m)} 
                hide={hide}
            />
            <CustomAreaControls showDrawingTools={showDrawingTools} />
            <TileLayer />
            <MapControls disabled={hide}/> { /*must be outside to capture click events first*/ }
            <WaypointCandidate />
            <VesselCandidate />
            <MapData />
            <UrlTriggeredDrawer
                width={'500px'}
                urlTrigger={'showMapSettings'}
                renderContent={() => <MapSettings />}
                heading={'Map Settings'}
            />
        </React.Fragment>
    );
}

export default LeafletMapPure;