//@flow

import React from 'react';
import { RenderCheckbox, RenderSelectField } from '../../../common/FormFields';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import {
  Attribution
} from './styledComponents';

const MapSettingsPure = ({
    activeBaselayer,
    layers,
    showZoomControl,
    showEcaAreas,
    showHighRiskAreas,
    toggleMapSettingsZoomControl,
    toggleMapSettingsEcaAreas,
    toggleMapSettingsHighRiskAreas,
    showCustomAreaControl,
    toggleMapSettingsDrawCustomArea,
    requireDoubleConfirm,
    toggleRequireDoubleConfirmation,
    handleChange,
    attribution,
    toggleMapSettingsJwcAreas,
    showJwcAreas
}) => {
    return (
      <Grid container spacing={2} direction={'column'}>
        <Grid item xs={12}>
          <RenderSelectField
            label="Baselayer"
            input={{
              value: activeBaselayer.uuid,
              onChange: handleChange,
              name: 'baselayer',
            }}
            meta={{ error: false }}
          >
            {layers.map(layer => (
              <MenuItem key={layer.uuid} value={layer.uuid}>
                {layer.name}
              </MenuItem>
            ))}
          </RenderSelectField>
          <Attribution 
            dangerouslySetInnerHTML={{__html: attribution}} 
            variant="caption" 
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Waypoints</FormLabel>
            <FormGroup>
              <RenderCheckbox
                label="Require extra confirmation"
                input={{
                  value: requireDoubleConfirm,
                  onChange: toggleRequireDoubleConfirmation,
                }}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Areas of Interest</FormLabel>
            <FormGroup>
              <RenderCheckbox
                label="Show ECA Areas"
                input={{
                  value: showEcaAreas,
                  onChange: toggleMapSettingsEcaAreas,
                }}
              />
              <RenderCheckbox
                label="Show High Risk Areas"
                input={{
                  value: showHighRiskAreas,
                  onChange: toggleMapSettingsHighRiskAreas,
                }}
              />
              <RenderCheckbox
                label="Show Joint War Committee Listed Areas"
                input={{
                  value: showJwcAreas,
                  onChange: toggleMapSettingsJwcAreas,
                }}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Controls</FormLabel>
            <FormGroup>
              <RenderCheckbox
                label="Show Zoom Control"
                input={{
                  value: showZoomControl,
                  onChange: toggleMapSettingsZoomControl,
                }}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Experimental Features</FormLabel>
            <FormGroup>
              <RenderCheckbox
                label="Draw Custom Areas"
                input={{
                  value: showCustomAreaControl,
                  onChange: toggleMapSettingsDrawCustomArea,
                }}
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    );
}

export default MapSettingsPure;
