import {
    compose,
    branch,
    renderNothing
} from 'recompose';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    return {
        hideData: state.map.leafletObj.hideData === true
    }
}

const Handlers = compose(
    connect(mapStateToProps),
    branch(({ hideData }) => hideData, renderNothing)
);

export default Handlers;