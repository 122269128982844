import {
    compose,
    withHandlers,
    withState,
    withProps
} from 'recompose';
import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { postMultipleValidateCall } from './../../../store/sagas/aqp-api-helper';
import { chunkify } from './../../../utility/array';

const AsyncCell = (params) => {
    if(params.value === undefined){
        return <Skeleton variant="rect" width={40} height={14} style={{marginTop: 6}}/>
    }
    else{
        return (
            <div>
                {/* <span style={{fontSize: 8}}>nmi</span> */}
                <div style={{float: 'right'}}>{params.value}</div>
            </div>
        );
    }
}

const Handlers = compose(
    withState('activeStep', 'setActiveStep', 0),
    withState('columnDefs', 'setColumnDefs', []),
    withState('rowData', 'setRowData', []),
    withState('error', 'setError', ''),
    withState('mappedColForName', 'setColForName', 0),
    withState('mappedColForLat', 'setColForLat', 1),
    withState('mappedColForLng', 'setColForLng', 2),
    withState('validatedRowData', 'setValidatedRowData', null),
    withHandlers({
        handleSelection: () => (params) => {
            console.log(params);
        },
        clearData: ({
            setColumnDefs,
            setRowData,
            setError,
            setColForName,
            setColForLng,
            setColForLat
        }) => () => {
            setColumnDefs([]);
            setRowData([]);
            setError('');
            setColForName(0);
            setColForLng(1);
            setColForLat(2);
        },
        fileHandler: ({
            setColumnDefs,
            setRowData,
            setError
        }) => (fileContent) => {
            const lines = fileContent.split(/\r?\n/);
            if(lines.length < 2){
                setError('File must have at least two rows, a header row and at least one data row');
                return;
            }
            const data = lines
                .map(l => l.split(','))
                .map(arr => arr.reduce((acc, val, idx) => ({
                    ...acc,
                    [idx]: val
                }), {}));
            const header = { ...data[0] };
            const rowData = data.slice(1);
            if(rowData.length > 250){
                setError('File must have less than or equal to 250 data rows.');
                return;
            }
            const columnDefs = Object.keys(header).map(key => header[key]).map((v, i) => ({
                headerName: v, 
                field: `${i}`
            }));
            if(columnDefs.length < 3){
                setError('File must have at least three columns for Name, Latitude and Longitude values');
                return;
            }
            // console.log(lines, header, rowData, columnDefs);

            setColumnDefs(columnDefs);
            setRowData(rowData);
            setError('');

            
        }
    }),
    withProps(({
        rowData,
        mappedColForName,
        mappedColForLng,
        mappedColForLat
    }) => ({
        extractColumnDefs: [
            { headerName: 'Id', field: 'idx', checkboxSelection: true, headerCheckboxSelection: true, sortable: true, resizable: true },
            { headerName: 'Name', field: 'name', resizable: true, sortable: true },
            { headerName: 'Lat', field: 'lat', resizable: true },
            { headerName: 'Lng', field: 'lng', resizable: true },
            { headerName: 'Validated Lat', field: 'validatedLat', cellRenderer: 'asynRenderer', resizable: true },
            { headerName: 'Validated Lng', field: 'validatedLng', cellRenderer: 'asynRenderer', resizable: true },
            { headerName: 'Moved by (meter)', field: 'movedBy', cellRenderer: 'asynRenderer', sortable: true, resizable: true }
        ],
        extractRowData: rowData.map((row, idx) => {
            return {
                idx: idx,
                name: row[mappedColForName],
                lat: row[mappedColForLat],
                lng: row[mappedColForLng]
            }
        }).filter(row => row.name !== undefined && row.name !== ''),
        frameworkComponents: {
            asynRenderer: AsyncCell,
        }
    })),
    withHandlers({
        updateActiveStep: ({
            activeStep,
            setActiveStep,
            columnDefs,
            setColForName,
            setColForLat,
            setColForLng,
            extractRowData,
            setValidatedRowData
        }) => (val) => {
            if(activeStep === 0 && val === 1){
                // try to do some initial column mapping
                const nameCol = columnDefs.find(c => c.headerName.toLowerCase().includes('name'));
                if(nameCol !== undefined){
                    setColForName(nameCol.field);
                }

                const latCol = columnDefs.find(c => c.headerName.toLowerCase().includes('latitude') || c.headerName.toLowerCase().includes('lat'));
                if(latCol !== undefined){
                    setColForLat(latCol.field);
                }

                const lngCol = columnDefs.find(c => c.headerName.toLowerCase().includes('longitude') || c.headerName.toLowerCase().includes('lng'));
                if(lngCol !== undefined){
                    setColForLng(lngCol.field);
                }
            }
            if(activeStep === 1 && val === 2){
                // initiate validation
                // console.log(extractRowData);
                const requestChunks = chunkify(extractRowData, 50);
                const promises = requestChunks.map(chunk => postMultipleValidateCall({
                    latlngs: chunk.map(row => ({ lat: row.lat, lng: row.lng }))
                }));
                Promise.all(promises)
                    .then(results => results.reduce((a, v) => a.concat(v), []))
                    .then(results => results.map(result => result.status === "ok" ? ({
                        validatedLat: result.validatedLatlng.lat,
                        validatedLng: result.validatedLatlng.lng,
                        movedBy: Math.ceil(result.moved_by)
                    }) : ({
                        validatedLat: null,
                        validatedLng: null,
                        movedBy: null
                    })))
                    .then(responses => {
                        const validatedRowData = extractRowData.map(row => ({
                            ...row,
                            ...responses[row.idx]
                        }));
                        setValidatedRowData(validatedRowData);
                    });

                // const promises = extractRowData.map(row => ({
                //     lat: row.lat,
                //     lng: row.lng
                // }))
            }
            setActiveStep(val);
        }
    }),
    withProps(({
        extractRowData,
        validatedRowData
    }) => ({
        numberOfLocs: extractRowData.length,
        numberOfValidatedLocs: validatedRowData !== null ? validatedRowData.length : 0
    })),
    withState('selectedRows', 'setSelectedRows', []),
    withState('submitting', 'setSubmitting', false),
    withHandlers({
        onSelectionChange: ({
            setSelectedRows
        }) => ({api}) => {
            const selectedRows = api.getSelectedRows();
            // console.log('selected', selectedRows);
            setSelectedRows(selectedRows);
        }
    }),
    withHandlers({
        addNodesToMatrix: ({
            validatedRowData
        }) => (waypoints) => {

        }
    }),
);

export default Handlers;