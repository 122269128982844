import React from 'react';
import { Link } from 'react-router-dom';

const UrlTriggerLinkPure = ({
  link,
  children, 
  disabled,
  hide,
  urlTrigger,   //just so it is not passed on as part of 'rest' object
  urlParams,    //just so it is not passed on as part of 'rest' object
  refreshLink,  //just so it is not passed on as part of 'rest' object
  setLink,      //just so it is not passed on as part of 'rest' object
  staticContext,//just so it is not passed on as part of 'rest' object
  ...rest
}) => {
  if(hide) return null;
  if (disabled) return children;
  
  return (
    <Link to={link} tabIndex={-1}>
      {React.Children.map(children, child => React.cloneElement(child, rest))}
    </Link>);
};

export default UrlTriggerLinkPure;
