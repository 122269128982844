import React from 'react';
import { MorphReplace } from 'react-svg-morph';
import { AqpLogoSvg } from '../../aquaplot/logo.js';
import { Wrapper } from './styledComponents';

const AutoRenew = () => <svg />

const Pure = ({
    checked
}) => {
    return (
        <Wrapper>
            <MorphReplace 
                width={130} 
                height={130}
            >
            {
                checked ? <AqpLogoSvg key="logo" /> : <AutoRenew key="empty" />
            }
            </MorphReplace>
        </Wrapper>
    )
}

export default Pure;