import React, { PureComponent } from 'react';
import ValueField from './ValueField';

export default class LatitudeField extends PureComponent {
  render() {
    const { lat } = this.props;

    const lat_string = lat
      .toString()
      .substring(0, lat.toString().indexOf('.') + 4);

    return (
      <ValueField
        label="Latitude"
        value={lat_string}
        unit="(Decimal Degrees)"
      />
    );
  }
}
