import { aquaplotStore } from './../../index';
import {
    FETCH_USER_MATRIX_SUCCEEDED,
    FETCH_USER_MATRICES_REQUESTED
  } from '../../types';

const matricesListener = snapshot => {
    snapshot.docChanges().forEach(function(change) {
        aquaplotStore.dispatch({
            type: FETCH_USER_MATRIX_SUCCEEDED,
            payload: change.type === 'removed' ? {
                id: change.doc.id
            } : {
                id: change.doc.id,
                ...change.doc.data()
            }
        });
    });
}

export function* bootstrapMatrix(database, firebaseId){
    // console.log('FIREBASE ID', firebaseId);
    if (process.env.REACT_APP_SHOW_DEBUG === 'true') console.log('bootstrap matrix')
    aquaplotStore.dispatch({
        type: FETCH_USER_MATRICES_REQUESTED,
        payload: null,
    });
    yield database
        .collection('DistanceMatrices')
        .where("owner", "==", firebaseId)
        .where("visible", "==", true)
        .onSnapshot(matricesListener);
}