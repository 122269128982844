import axios from 'axios';

export * from './company';
export * from './invoices';
export * from './payments';
export * from './dataApplet';
export * from './plans';
export * from './faqs';
export * from './user';
export * from './bunkerEx';

const axiosConfig = {
  mode: 'no-cors',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

export const plainPost = url => {
  return axios
    .post(url, {}, axiosConfig)
    .then(res => res.data)
    .catch(err => {
      throw err;
    });
};
