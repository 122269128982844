import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import {
    StyledFab
} from './../styledComponents';

const AddPure = ({
    active,
    activateAddMode
}) => {
    return (
        <StyledFab 
            size="small"
            color={active ? "primary" : "default"} 
            aria-label="Add" 
            onClick={activateAddMode}
        >
            <AddIcon />
        </StyledFab>
    )
}

export default AddPure;