import styled from 'styled-components';
import { Colors } from '../../aquaplot/colors.js';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  fill: ${Colors.aqp_deep_sea};
  flex-grow: 1;
`