import { compose, withHandlers } from 'recompose';
import { contactUs } from '../../services';
import { updateSnackbarMessage } from '../../store/redux/actions';
import { connect } from 'react-redux';

const mapDispatchToProps = dispatch => {
  return {
    updateSnackbarMessage: msg => {
      dispatch(updateSnackbarMessage.call(msg));
    },
  };
};

const ContactUsHandlers = compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withHandlers({
    submitForm: props => values => {
      const { title, comment } = values;
      return contactUs({title, comment})
        .then(resp => {
          if (resp.data.status === 'ok') {
            props.updateSnackbarMessage('Email Sent Successfully!');
            props.initialize({
              title: '',
              comment: '',
            });
          }
          if (process.env.REACT_APP_SHOW_DEBUG === 'true') console.log(resp);
        })
        .catch(err => {
          if (process.env.REACT_APP_SHOW_DEBUG === 'true') console.log(err);
        });
    },
  })
);

export default ContactUsHandlers;
