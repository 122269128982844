import React from 'react';

export const Polygon = () => <div style={{height: 24, width: 24}}>
<svg height="100%" viewBox="0 0 512 512" width="100%" xmlns="http://www.w3.org/2000/svg"><path d="m234.558594 114.238281-136 296.960938-29.117188-13.4375 136-296.960938zm0 0" fill="#073352"/><path d="m427.039062 242.078125-23.359374 21.761719-142.71875-153.917969 23.359374-21.761719zm0 0" fill="#073352"/><path d="m409.921875 321.121094-295.523437 123.199218-12.320313-29.441406 295.523437-123.199218zm0 0" fill="#073352"/><path d="m205.441406 100.800781 29.117188 13.4375-19.359375 42.402344c-10.558594-2.71875-20.480469-7.199219-29.277344-13.28125zm0 0" fill="#073352"/><path d="m316.160156 122.398438c-6.558594 8.480468-14.558594 16-23.519531 21.761718l-31.679687-34.238281 23.359374-21.761719zm0 0" fill="#073352"/><path d="m304 64c0 35.347656-28.652344 64-64 64s-64-28.652344-64-64 28.652344-64 64-64 64 28.652344 64 64zm0 0" fill="#073352"/><path d="m118.078125 368.640625-19.519531 42.558594-29.117188-13.4375 19.359375-42.402344c10.558594 2.71875 20.480469 7.199219 29.277344 13.28125zm0 0" fill="#073352"/><path d="m157.441406 426.398438-43.042968 17.921874-12.320313-29.441406 43.042969-17.917968c5.757812 8.960937 10.078125 18.878906 12.320312 29.4375zm0 0" fill="#073352"/><path d="m128 448c0 35.347656-28.652344 64-64 64s-64-28.652344-64-64 28.652344-64 64-64 64 28.652344 64 64zm0 0" fill="#073352"/><path d="m397.601562 291.679688 12.320313 29.441406-43.042969 17.917968c-5.757812-8.960937-10.078125-18.878906-12.320312-29.4375zm0 0" fill="#073352"/><path d="m403.679688 263.839844-31.839844-34.238282c6.558594-8.480468 14.558594-16 23.519531-21.761718l31.679687 34.238281zm0 0" fill="#073352"/><path d="m512 288c0 35.347656-28.652344 64-64 64s-64-28.652344-64-64 28.652344-64 64-64 64 28.652344 64 64zm0 0" fill="#073352"/><g fill="#fff"><path d="m224 48h32v32h-32zm0 0"/><path d="m432 272h32v32h-32zm0 0"/><path d="m48 432h32v32h-32zm0 0"/></g></svg>
</div>;

/* 
<div>
Icons made by <a href="https://www.flaticon.com/authors/pixelmeetup" title="Pixelmeetup">
Pixelmeetup</a> from <a href="https://www.flaticon.com/" 			    
title="Flaticon">www.flaticon.com</a> is licensed by 
<a href="http://creativecommons.org/licenses/by/3.0/" 			    
title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></div> */