import React from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '../../../common/ResponsiveDialog';
import TextField from '@material-ui/core/TextField';
import UUID from 'uuid-js';

const Pure = ({
    open,
    cancel,
    name,
    setName,
    createNewMatrix
}) => {
    return (
        <Dialog
            isOpen={open}
            cancel={cancel}
            title={'Please give a name or confirm default'}
            actionText={'Create'}
            renderContent={() => (
                <Grid container direction={'column'}>
                    <Grid item xs={12}>
                        <TextField
                            label="Name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            fullWidth={true}
                        />
                    </Grid>
                </Grid>
            )}
            handleAgree={() => {
                createNewMatrix(name);
                cancel();
                setName('New Matrix ' + UUID.create().toString().substring(0,4));
            }}
        />
    );
}

export default Pure;