// General helpers
export const update = (state, mutations) => Object.assign({}, state, mutations);

export const lastOfArrayOrNull = arr => arr.length > 0 ? arr[arr.length - 1] : null;


// Routing (waypoint, segment) related helpers

// IF YOU EDIT THIS FUNCTION, MAKE SURE TO ALSO CHECK isInvolved 
export const generateSegmentIdentifier = (fromWaypoint, toWaypoint) => 
    [fromWaypoint.uuid, toWaypoint.uuid].join('~')

// IF YOU EDIT THIS FUNCTION, MAKE SURE TO ALSO CHECK generateSegmentIdentifier 
export const isInvolved = (waypoint_uuid) => (segment) => segment.id.split('~').includes(waypoint_uuid)

export const notMatchingUuid = uuid => w => w.uuid !== uuid

export const newSegment = (fromWaypoint, toWaypoint, speedOverGround, idGen = generateSegmentIdentifier) =>
    Object.assign({
        from: fromWaypoint.latlng,
        to: toWaypoint.latlng,
        id: idGen(fromWaypoint, toWaypoint),
        route: null,
        speedOverGround,
    })

export const updateSegments = (
    {
        defaultSpeed,
        segments: oldSegments
    },
    waypoints, 
    idGen = generateSegmentIdentifier
) => {
    const segments = waypoints.reduce((segs, toWp, idx) => {
        if(idx === 0) return segs;
        const fromWp = waypoints[idx - 1];
        const existing = oldSegments.find(({id}) => id === idGen(fromWp, toWp));

        return segs.concat([ existing || newSegment(fromWp, toWp, defaultSpeed, idGen) ]);
    }, []);
    return segments;
}

// returns { segments, waypoints } after removing waypoint
export const routingSplice = (
    { 
        segments: prevSegments, 
        waypoints: prevWaypoints,
        defaultSpeed
    }, 
    waypoint,
    idGen = generateSegmentIdentifier 
) => {
    let segments = [].concat(prevSegments);
    const waypoints = prevWaypoints.filter(notMatchingUuid(waypoint.uuid)) 

    const index = prevSegments.findIndex(isInvolved(waypoint.uuid));
    if(index === 0 || index === prevSegments.length - 1){
        // only one segment is involved
        segments.splice(index, 1);
    }
    else{
        segments.splice(index, 2, newSegment(waypoints[index], waypoints[index + 1], defaultSpeed, idGen));
    }

    return { segments, waypoints };
}

// returns { segments, waypoints } after adding waypoint
export const routingAdd = (
    { 
        segments: prevSegments, 
        waypoints: prevWaypoints,
        defaultSpeed,
    }, 
    waypoint,
    idGen = generateSegmentIdentifier 
) => {
    const last = lastOfArrayOrNull(prevWaypoints)
    if(last && last.latlng === waypoint.latlng){
        return { segments: prevSegments, waypoints: prevWaypoints }; // no duplicate
    }

    const { segment_id } = waypoint;
    const segment_idx = prevSegments.findIndex(({id}) => id === segment_id);
    if(segment_id && segment_idx > -1){
        const waypoints = [].concat(prevWaypoints);
        waypoints.splice(segment_idx + 1, 0, waypoint);

        const segments = [].concat(prevSegments);
        segments.splice(
            segment_idx, 
            1, 
            newSegment(waypoints[segment_idx], waypoint, defaultSpeed, idGen), 
            newSegment(waypoint, waypoints[segment_idx + 2], defaultSpeed, idGen)
        );
        return {
            waypoints,
            segments
        }
    }
    else{
        return {
            waypoints: prevWaypoints.concat(waypoint),
            segments: prevSegments.concat( last ? newSegment(last, waypoint, defaultSpeed, idGen) : [])
        }
    }
}

export const denormalizedRoutes = (routes) => {
    const lngs = routes
        .map((r) => r.features[0].geometry.coordinates)             // get coordinates
        .map(coordinates => coordinates[coordinates.length - 1][0]) // get lng from last point
    
    if(!lngs) return routes; // routes must have been empty array

    routes.forEach((route, idx) => {
        if(idx === 0) return; //skip first
        const startLng = route.features[0].geometry.coordinates[0][0];
        const lastLng = lngs[idx - 1];
        const delta = startLng - lastLng;

        if(Math.abs(delta) > 0){
            route.features[0].geometry.coordinates = route.features[0].geometry.coordinates.map((c) => [c[0] - delta, c[1]])
        }
    });

    return routes;
}

export const denormalizedLatLng = (waypoint, index, segments) => {
    if(segments.length === 0) return waypoint.latlng;
    const isLast = index === segments.length; //index of waypoint array which has 1 more than segments
    const seg = segments[isLast ? index - 1 : index];
    if(!seg.route || seg.route.status !== "ok") return waypoint.latlng;
    const coordinates = seg.route.features[0].geometry.coordinates;
    const coords = isLast ? coordinates[coordinates.length - 1] : coordinates[0];
    const latlng = { lng: coords[0], lat: coords[1] }
    return latlng;
}