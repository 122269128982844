import {
  FETCH_USER_LOCATIONS_REQUESTED,
  FETCH_USER_LOCATIONS_SUCCEEDED,
  FETCH_USER_LOCATIONS_FAILED,
} from '../../types';
import groupBy from 'lodash/groupBy';

const initialLocations = {
  loading: true,
  locations: {},
  groupedLocations: {},
  listenerInstantiated: false,
  error: undefined,
};

const groupLocations = locations => {
  let groupedLocations = {};
  groupedLocations['all'] = Object.keys(locations).map(id => locations[id]);
  groupedLocations = Object.assign(
    groupedLocations,
    groupBy(locations, 'type')
  );
  return groupedLocations;
};

export function userLocationsReducer(state = initialLocations, action) {
  switch (action.type) {
    case FETCH_USER_LOCATIONS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_LOCATIONS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: undefined,
        listenerInstantiated: true,
        locations: action.payload,
        groupedLocations: groupLocations(action.payload),
      };
    case FETCH_USER_LOCATIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
