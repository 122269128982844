import { 
    compose,
    withState
} from 'recompose';
import UUID from 'uuid-js';

const Handlers = compose(
    withState('name', 'setName', 'New Matrix ' + UUID.create().toString().substring(0,4))
);

export default Handlers;
