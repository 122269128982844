import React from 'react';
import Dialog from '../../../../../common/ResponsiveDialog';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {
    StyledCard,
    StyledFormControlLabel
} from './styledComponents';

const OptionsPure = ({
    options,
    value,
    setValue
}) => {
    return (
        <FormControl component="fieldset">
            <RadioGroup
                aria-label="Custom Area Action"
                name="area-option-choice"
                value={value}
                onChange={(e) => setValue(e.target.value)}
            >
            {
                options.map((option) => (
                    <StyledFormControlLabel 
                        key={`area-option-${option.slug}`}
                        value={option.slug}
                        control={<Radio />}
                        labelPlacement="end"
                        disabled={option.disabled}
                        label={
                            <StyledCard>
                                <CardContent>
                                    <Typography color="textSecondary" gutterBottom>
                                    { option.label }
                                    </Typography>
                                    <Typography variant="caption" component="p">
                                    { option.description }
                                    </Typography>
                                </CardContent>
                            </StyledCard>
                        }
                    />
                ))   
            }
            </RadioGroup>
        </FormControl>
    )
}

const CustomAreaDialogPure = ({
    isOpen,
    setIsOpen,
    options,
    chosenOption,
    setChosenOption,
    canOk,
    handleChoice
}) => {
    return (
        <Dialog
            title="What action would you like to take?"
            renderContent={() => 
                <OptionsPure 
                    options={options}
                    value={chosenOption}
                    setValue={setChosenOption} 
                />
            }
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            actionText={'OK'}
            cancel={() => setIsOpen(false)}
            handleAgree={handleChoice}
            actionTextDisabled={!canOk}
        />
    )
}

export default CustomAreaDialogPure;