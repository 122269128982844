import { put, select, debounce, takeLatest } from 'redux-saga/effects';
import {
  BufferAroundRoute,
  updateBunkerPortsInBuffer,
  CreateBunkerPlan
} from './../redux/actions';

import lineSliceAlong from '@turf/line-slice-along';
import buffer from '@turf/buffer';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import { featureReduce } from '@turf/meta';
import { lineString } from '@turf/helpers';
import lineSplit from '@turf/line-split';
import union from '@turf/union';
import bbox from '@turf/bbox';
import { denormalizedRoutes } from './../../applets/LeafletMap/data/helper';

import {
    createBunkerPlan
} from './aqp-api-helper';
import { singleAsync } from './saga-helper.js';

const reduceToSingleFeatureCollection = segments => {
    return denormalizedRoutes(segments.map((seg) => seg.route))
        .reduce((acc, { features }) => {
        acc.features = acc.features.concat(features);
        return acc;
    }, {
        type: "FeatureCollection",
        features: []
    })
}

const spliceAtLng = (features, lng) => {
    const remaining = [];
    const singledOut = [];
    const splitter = lineString([[lng, -85], [lng, 85]]);
    features.forEach(feature => {
        const split = lineSplit(feature, splitter);
        split.features.forEach(f => {
            var [lngLeft,,,] = bbox(f);
            if(lngLeft >= lng){
                singledOut.push(f);
            }
            else{
                remaining.push(f);
            }
        });
    });
    return [remaining, singledOut];
}

const denormalizedLatlng = (buffer, portLat, portLng) => {
    const [lngLeft,,lngRight,] = bbox(buffer);
    const nLeft = Math.floor(Math.max(portLng - lngLeft, 0) / 360.);
    const nRight = Math.floor(Math.max(lngRight - portLng, 0) / 360.);

    for (var i = - nLeft; i <= nRight; i++) { 
        const lon = portLng + (i * 360.);
        if(booleanPointInPolygon([lon, portLat], buffer)){
            return {
                lat: portLat,
                lng: lon
            }
        }
    }
    return {
        lat: portLat,
        lng: portLng
    }
}

const deepCopy = obj => JSON.parse(JSON.stringify(obj));

function* getBufferAroundValidSegments(action) {
  const {
    acrossRange,
    alongRange
  } = action.payload;
//   console.log('alongRange', alongRange);

  const { segments } = yield select(state => ({
      segments: state.routing.segments
  }));
  // filter down to valid segments
  const validSegments = segments.filter(seg => seg.route && seg.route.status && seg.route.status === "ok" && !seg.error && !seg.processing);
  if(validSegments.length === 0) return;
  const routesData = reduceToSingleFeatureCollection(validSegments);
  
  const distances = validSegments.map(({ route }) => route.features[0].properties.distance);
  const totalDistance = distances.map(({ total }) => total).reduce((a, v) => a + v, 0);
  const ecaDistance = distances.map(({ insideEca: { total } }) => total).reduce((a, v) => a + v, 0);
  
  const combinedRoute = featureReduce(routesData, (prev, curr) => {
        prev.geometry.coordinates = prev.geometry.coordinates.concat(curr.geometry.coordinates);
        return prev;
    }, { 
        type: "Feature",
        geometry: { type: "LineString", coordinates: []} 
    });
  const rangeBegin =  Math.max(0,alongRange[0]) / 100 * totalDistance;
  const rangeEnd = Math.max(1,alongRange[1]) / 100 * totalDistance; 
  const routeRunway = lineSliceAlong(combinedRoute, rangeBegin, rangeEnd, {units: 'nauticalmiles'});

  // routeRunway may cross antimeridian (lon +/- 180) which is not gracefully handled when buffering
  // so we need to split linestring into different pieces, move them somewhere safe, then create buffer
  // and move buffer back 

  var [leftMost,,rightMost,] = bbox(routeRunway);
  const spansLngRange = rightMost - leftMost;
  const splitEveryRange = 200;
  const numberOfCutsNeeded = Math.floor(spansLngRange / splitEveryRange);
  var remaining = [];
  var singledOut = [];
  [...Array(numberOfCutsNeeded).keys()].reverse().map(i => i + 1).forEach(index => {
      const splitLng = leftMost + index * splitEveryRange;
      var features = remaining.length === 0 ? [deepCopy(routeRunway)] : remaining;
      var [remainingLines, singledOutLines] = spliceAtLng(features, splitLng);
      singledOut = singledOut.concat(singledOutLines);
      remaining = remaining.concat(remainingLines);
  });
  if(remaining.length > 0){
    singledOut = singledOut.concat(remaining)
    remaining = [];
  }
  if(singledOut.length === 0){
    singledOut.push(deepCopy(routeRunway)); // no split
  }

  const buffers = singledOut.map(line => {
    const coords = line.geometry.coordinates;
    const [lngLeft,,lngRight,] = bbox(line);
    const midLng = (lngLeft + lngRight) / 2;
    const shiftToSafety = -midLng;
    line.geometry.coordinates = coords.map(arr => [arr[0] + shiftToSafety, arr[1]]);
    const bf = buffer(line, acrossRange, { units: 'nauticalmiles' });
    bf.geometry.coordinates.forEach((coords, idx, poly) => {
        poly[idx] = coords.map(arr => [arr[0] - shiftToSafety, arr[1]])
    });
    return bf;
  });

  const combinedBuffers = buffers.reduce((a, v, i) => i === 0 ? v : union(a,v));

  const segmentLengths = validSegments
    .map(seg => seg.route.features[0].geometry.coordinates.length)
    .map((v,i,a) => a.slice(0,i + 1).reduce((acc,v) => acc + v, 0));
  
  yield put({
    type: BufferAroundRoute.SUCCEEDED,
    payload: {
        buffer: combinedBuffers,
        bufferRoute: { 
            ...routeRunway, 
            properties: { 
                totalDistanceOfFullRoute: totalDistance,
                ecaDistanceOfFullRoute: ecaDistance
            } 
        },
        fullRouteGeojson: routesData,
        bufferSegmentLengths: segmentLengths,
        bufferSegmentDistances: distances
    }
  });
}

export function* bufferAroundValidSegmentsSaga() {
  yield debounce(500, BufferAroundRoute.REQUESTED, getBufferAroundValidSegments);
}

const isEven = v => v % 2 === 0;

const median = (sortedArray) => {
    return isEven(sortedArray.length)
        ? (sortedArray[(sortedArray.length / 2) - 1] + sortedArray[sortedArray.length / 2]) / 2
        : sortedArray[Math.ceil(sortedArray.length / 2) - 1];
}

const quartiles = (sortedArray) => {
    if(sortedArray.length === 1){
        return {
            firstQuartile: sortedArray[0],
            secondQuartile: sortedArray[0],
            thirdQuartile: sortedArray[0] 
        }
    }
    const secondQuartile = median(sortedArray);
    return {
        firstQuartile: median(sortedArray.filter(v => v <= secondQuartile)),
        secondQuartile: secondQuartile,
        thirdQuartile: median(sortedArray.filter(v => v >= secondQuartile)),
    }
}

function* getBunkerPortsInBuffer(){
    const {
        rowData,
        bufferedGeojson: poly,
        bufferRoute: line,
        bufferSegmentLengths,
        waypoints,
        bufferSegmentDistances
    } = yield select(state => ({
        rowData: state.bunker.rowData,
        bufferedGeojson: state.routing.buffer,
        bufferRoute: state.routing.bufferRoute,
        bufferSegmentLengths: state.routing.bufferSegmentLengths,
        waypoints: state.routing.waypoints,
        bufferSegmentDistances: state.routing.bufferSegmentDistances
    }));

    const rowDataFiltered = poly && poly.type === "Feature" 
        ? rowData.filter(({lng, lat}) => {
            return booleanPointInPolygon([lng, lat], poly) ||
                booleanPointInPolygon([lng + 360., lat], poly) ||
                booleanPointInPolygon([lng - 360., lat], poly);
        })
        : []; 
    // console.log(rowDataFiltered)
    const portsInBuffer = rowDataFiltered
        .map(({lat, lng, portId, port, region, timeZoneOffset}) => ({lat, lng, portId, port, region, timeZoneOffset}))
        .filter(({portId: pId}, index, self) => self.findIndex(({portId}) => portId === pId) === index);

    const grades = rowData
        .map(({ grade }) => grade)
        .filter((v, i, a) => a.indexOf(v) === i); // make unique (only first occurence remains)

    const statsByGrade = grades
        .map(g => ({
            values: rowDataFiltered.filter(({ grade }) => grade === g).sort((a,b) => a.price - b.price),
            grade: g
        }))
        .filter(({ values }) => values.length > 0)
        .map(({ grade, values }) => ({
            grade: grade,
            min: values[0].price,
            max: values[values.length - 1].price,
            avg: values.map(({ price }) => price).reduce((acc, val, i) => acc + val, 0) / values.length,
            ...(quartiles(values.map(({ price }) => price)))
        }))

    const pricesByPort = portsInBuffer.map(port => ({
        ...port,
        mapLatlng: denormalizedLatlng(poly, port.lat, port.lng),
        nearestPointOnLine: nearestPointOnLine(line, [port.lng, port.lat], {units: 'nauticalmiles'}),
        prices: rowData
            .filter(({ portId }) => portId === port.portId)
            .reduce((acc, val) => ({ ...acc, [`${val.grade}`]: val.price}) ,{})
    })).sort((a,b) => {
        return a.nearestPointOnLine.properties.index - b.nearestPointOnLine.properties.index;
    }).map(port => ({
        ...port,
        segmentIndex: bufferSegmentLengths.findIndex((v,i) => v > port.nearestPointOnLine.properties.index)
    })).map(port => ({
        ...port,
        segmentFrom: waypoints[port.segmentIndex],
        segmentTo: waypoints[port.segmentIndex + 1],
        segmentDistance: bufferSegmentDistances[port.segmentIndex],
        distanceToDeviationPortOffset: bufferSegmentDistances
                .slice(0, port.segmentIndex)
                .reduce((acc, v) => acc + v.total, 0),
        ecaDistanceToDeviationPortOffset: bufferSegmentDistances
                .slice(0, port.segmentIndex)
                .reduce((acc, v) => acc + v.insideEca.total, 0),
        nonEcaDistanceToDeviationPortOffset: bufferSegmentDistances
                .slice(0, port.segmentIndex)
                .reduce((acc, v) => acc + (v.total - v.insideEca.total), 0),
        distanceTotal: bufferSegmentDistances
                .reduce((acc, v) => acc + v.total, 0),
        ecaDistanceTotal: bufferSegmentDistances
                .reduce((acc, v) => acc + v.insideEca.total, 0),
        nonEcaDistanceTotal: bufferSegmentDistances
                .reduce((acc, v) => acc + (v.total - v.insideEca.total), 0),
    }))
    // console.log(pricesByPort);

    yield put({
        type: updateBunkerPortsInBuffer.type,
        payload: {
            portsInBuffer: pricesByPort,
            statsByGrade: statsByGrade,
        }
    })
}

export function* bunkerPortsInBufferSaga() {
    yield takeLatest(BufferAroundRoute.SUCCEEDED, getBunkerPortsInBuffer);
}

/*
    CREATE BUNKER PLAN / DEVIATIONS
*/

function* initiateBunkerPlanCreation(action) {
    yield singleAsync(createBunkerPlan, CreateBunkerPlan.SUCCEEDED, CreateBunkerPlan.FAILED, action.payload, 0);
  }
  
  export function* createBunkerPlanSaga() {
    yield takeLatest(CreateBunkerPlan.REQUESTED, initiateBunkerPlanCreation);
  }