import { takeLatest, put, debounce, select } from 'redux-saga/effects';
import {
  LOCATIONS_FETCH_REQUESTED as REQUESTED,
  LOCATIONS_FETCH_SUCCEEDED as SUCCESS,
  LOCATIONS_FETCH_FAILED as FAILURE,
  anyAuth,
  LOCATIONS_ALL_SUCCEEDED as ALL_SUCCESS,
  LOCATIONS_ALL_FAILED as ALL_FAILURE,
  POI_SEARCH_TERM_SET,
  POI_SEARCH_TERM_CLEAR,
  POI_SUGGESTIONS_FETCH_SUCCEEDED,
  setLocationSuggestions
} from './../redux/actions';
import { getLocationsCall, getAllLocationsCall } from './aqp-api-helper.js';
import { singleAsync } from './saga-helper.js';
import Fuse from 'fuse.js';
import moment from 'moment';

function* getLocations(action) {
  const { fromCache, locationsFuse } = yield select(state => ({
    fromCache: state.data.locations.length > 0,
    locationsFuse: state.data.locationsFuse,
  }))
  if (fromCache && locationsFuse) {
    const locations = locationsFuse.search(action.payload.name).slice(0, 12);
    yield put(setLocationSuggestions(locations));
  }
  else{
    yield singleAsync(getLocationsCall, SUCCESS, FAILURE, action.payload, 100);
  }
}

export default function* locationsSaga() {
  yield debounce(500, REQUESTED, getLocations);
}

function* refreshAllLocations(action){
  yield singleAsync(
    getAllLocationsCall,
    ALL_SUCCESS,
    ALL_FAILURE,
    action.payload,
    0,
    result => {
      localStorage.locations = JSON.stringify(result);
      localStorage.locationsTimestamp = (new Date()).toUTCString();
      console.log('locations saved to local storage', localStorage.locationsTimestamp);
      return result;
    }
  );
}

function* getAllLocations(action) {
  if (
    typeof Storage !== 'undefined' &&
    typeof localStorage !== 'undefined'
  ) {
    // const s = `
    // [{"id":"0","from":{"lat":0,"lng":0},"to":{"lat":51.899906,"lng":4.356422},"options":{"suez":"false","panama":"true","kiel":"true","northeast":"false","northwest":"false","eca":"ignore","hra":"minimize","vesseltype":"commercial","jwc":"ignore","autovalidate":"false"}},{"id":"1","from":{"lat":51.899906,"lng":4.356422},"to":{"lat":21.46417,"lng":39.154999},"options":{"suez":"false","panama":"true","kiel":"true","northeast":"false","northwest":"false","eca":"ignore","hra":"minimize","vesseltype":"commercial","jwc":"ignore","autovalidate":"false"}}]
    // `;
    // const reqs = JSON.parse(s).map(({ from, to, options }) => {
    //   return [
    //     `http://localhost:8887/route/from/${from.lng}/${from.lat}/to/${to.lng}/${to.lat}`,
    //     `?`,
    //     Object.keys(options).map(key => `${key}=${options[key]}`).join('&')
    //   ].join('')
    // })
    // console.log(reqs);
    console.log('local storage supported');
    if (localStorage.locations) {
      console.log('locations from local storage');
      yield put({
        type: ALL_SUCCESS,
        payload: JSON.parse(localStorage.locations),
      });
      console.log(localStorage.locationsTimestamp);
      if(localStorage.locationsTimestamp){
        const ageInHours = moment().diff(moment(localStorage.locationsTimestamp), 'hours');
        console.log(
          'last updated', 
          ageInHours,
          'hours ago');

        if(ageInHours > 24){
          yield refreshAllLocations(action);
        }
      }
      else{
        console.log("no timestamp, will refresh");
        yield refreshAllLocations(action);
      }
    } else {
      console.log('locations from remote storage');
      yield refreshAllLocations(action);
    }
    yield put({ type: ALL_FAILURE, payload: {} });
  } else {
    // No Web Storage support... do nothing
    yield put({ type: ALL_FAILURE, payload: {} });
  }
}

export function* allLocationsSaga() {
  yield takeLatest(anyAuth.SUCCEEDED, getAllLocations);
}

const locationsFuseOptions = {
  shouldSort: true,
  tokenize: true,
  threshold: 0.1,
  location: 0,
  distance: 32,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    {
      name: 'name',
      weight: 0.4,
    },
    {
      name: 'locode',
      weight: 0.6,
    },
  ],
};

const updatePoiTriggers = [
  POI_SEARCH_TERM_CLEAR,
  POI_SEARCH_TERM_SET
]

function* updatePoi(action){
  // console.log(action.payload.search_term)
  const { userLocations } = yield select(state => ({
    userLocations: state.userLocations.locations,

  })); 
  // console.log(userLocations)
  const fuse = new Fuse(Object.values(userLocations), locationsFuseOptions)
  const matches = fuse.search(action.payload.search_term).slice(0, 12);
  yield put({
    type: POI_SUGGESTIONS_FETCH_SUCCEEDED,
    payload: matches
  })
}

export function* updatePoiSaga(){
  yield takeLatest(updatePoiTriggers, updatePoi);
}