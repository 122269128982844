import {
    compose
} from 'recompose';
import { connect } from 'react-redux'; 
import {
    activateCustomAreaAddMode
} from './../../../../../../store/redux/actions';

const mapStateToProps = state => {
    return {
        active: state.map.mode === state.map.modes.customArea
    }
}

const mapDispatchToProps = dispatch => {
    return {
        activateAddMode: () => dispatch(activateCustomAreaAddMode.call())
    }
}

const Handlers = compose(
    connect(mapStateToProps, mapDispatchToProps)
)

export default Handlers;