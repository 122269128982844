import {
    compose,
    withState,
    withProps,
    withHandlers,
    lifecycle
} from 'recompose';
import { connect } from 'react-redux';
import { 
    clearCustomArea, 
    addNogoArea,
} from '../../../../../store/redux/actions'

const actionOptions = {
    setAsNogoArea: {
        slug: "set_as_nogo_area",
        label: "Set As Nogo Area",
        description: "Tells our routing algorithm to avoid this area if possible.",
        disabled: false
    },
    listPortsInArea: {
        slug: "list_ports_in_area",
        label: "List Ports (available soon)",
        description: "Show all ports in the selected area.",
        disabled: true
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearArea: () => dispatch(clearCustomArea.call()),
        addNogoArea: latlngs => {
            dispatch(addNogoArea.call(latlngs))
        },
    }
}

const Handlers = compose(
    connect(null, mapDispatchToProps),
    withState('chosenOption', 'setChosenOption', ''),
    withProps(({
        chosenOption
    }) => ({
        options: Object.values(actionOptions),
        canOk: chosenOption !== ''
    })),
    withHandlers({
        handleChoice: ({
            latlngs,
            chosenOption,
            addNogoArea,
            clearArea,
            setIsOpen
        }) => () => {
            switch(chosenOption){
                case actionOptions.setAsNogoArea.slug:
                    addNogoArea(latlngs);
                    clearArea();
                    setIsOpen(false);
                    return;

                default:
            }
        }
    }),
    lifecycle({
        componentDidUpdate(){
            const { isOpen, chosenOption, setChosenOption } = this.props;
            !isOpen && chosenOption !== '' && setChosenOption('');
        }
    })
);

export default Handlers;