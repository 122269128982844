import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'

const DropzonePure = ({
    rootProps,
    labelProps,
    inputProps,
    allowFiletypes,
    accept,
    fileHandler
}) => {
  const onDrop = useCallback(acceptedFiles => {
    const reader = new FileReader()

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result
      fileHandler && fileHandler(binaryStr);
    //   console.log(binaryStr)
    }

    acceptedFiles.forEach(file => reader.readAsBinaryString(file))
  }, [])
  const {getRootProps, getInputProps, /*isDragActive*/} = useDropzone({
      onDrop,
      accept: accept
  })

  return (
    <div {...getRootProps()} {...rootProps}>
      <input {...getInputProps()} {...inputProps} />
      <p {...labelProps}>Drag 'n' drop a file here, or click to select file</p>
      {
        allowFiletypes && 
        <p {...labelProps}>Supported file types: {allowFiletypes.join(',')}</p>
      }
    </div>
  )
}

export default DropzonePure;