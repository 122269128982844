import { takeEvery, select, put } from 'redux-saga/effects'
import { 
  addWaypoint,
  updateWaypoint,
  Validation
} from './../redux/actions'
import { /*getValidateCall,*/ postValidateCall as validateCall } from './aqp-api-helper.js';
import { singleAsync } from './saga-helper.js'

// function* validateWaypoint(action) {
//   yield singleAsync(validateCall, Validation.SUCCEEDED, Validation.FAILED, action.payload, 0);
// }

// export default function* validationSaga() {
//   yield takeLatest(Validation.REQUESTED, validateWaypoint);
// }

function* autoValidate(action){
  const autovalidate = yield select(state => state.routing.autovalidate);
  const success_trans = (response) => {
    const merged = Object.assign({}, action.payload, response, { loading: false });
    // console.log(action.payload, response, merged)
    return merged;
  }
  if(autovalidate){
    yield put({
      type: Validation.REQUESTED,
      payload: action.payload
    });
    yield singleAsync(
      validateCall, 
      Validation.SUCCEEDED, 
      Validation.FAILED, 
      action.payload, 
      0, 
      success_trans);
  }
}

const TRIGGERS = [
  addWaypoint.type,
  updateWaypoint.type
]

export function* autoValidateSaga(){
  yield takeEvery(TRIGGERS, autoValidate);
}