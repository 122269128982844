import axios from 'axios';
import { functionWithApiEndpoints } from './../store/withApiEndpoints';

const axiosConfig = {
  mode: 'no-cors',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

export const getAllInvoices = functionWithApiEndpoints(({ accountEndpoint }) => {
  const url = `${accountEndpoint}/all_invoices`;
  return axios.get(url, axiosConfig).then(response => response.data);
});

// export const getAllInvoices = () => {
//   const data = getInvoices();
//   return data.invoices;
// };
