import {
    compose, 
    branch,
    renderNothing
} from 'recompose';

const Handlers = compose(
    branch(({activeActions}) => !activeActions || activeActions.length === 0, renderNothing)
);

export default Handlers;