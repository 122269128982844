import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const StyledCard = styled(Card)`
    && {
        flex: 1 1 auto;
    }
`

export const StyledFormControlLabel = styled(FormControlLabel)`
    && {
        padding-top: 20px;
    }

    & > span:last-child {
        flex: 1 1 auto;
    }
`