export const localStorageSupported = typeof(Storage) !== "undefined" && typeof(localStorage) !== "undefined";

export const fromLocalStorage = (val) => {
    if (localStorageSupported) {
        return JSON.parse(localStorage.getItem(val));
    }
    return null;
}

export const toLocalStorage = (param, val) => {
    if(localStorageSupported){
        localStorage.setItem(param, JSON.stringify(val));
    }
}