import { takeLatest, put } from 'redux-saga/effects';
import {
  anyAuth,
  RouteChannels,
} from './../redux/actions';


function* refreshChannels(action) {
  yield put({ type: RouteChannels.REQUESTED, payload: {} });
}

export default function* getAllEcaAreasSaga() {
  yield takeLatest(anyAuth.SUCCEEDED, refreshChannels);
}
