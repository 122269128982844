import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Location from '@material-ui/icons/LocationOn';
import Loyalty from '@material-ui/icons/Loyalty';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import ChangeRoute from '@material-ui/icons/TrackChanges';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import Add from '@material-ui/icons/Add';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';

import { Colors } from './../../aquaplot/colors';

export const LocationIcon = styled(Location)`
  && {
    height: 0.7em;
  }
`;

export const CargoIcon = styled(Loyalty)`
  && {
    height: 0.7em;
  }
`;

export const AddIcon = styled(Add)`
  && {
    height: 0.7em;
  }
`;

export const StyledTabs = styled(({ ...other }) => (
  <Tabs
    {...other}
    classes={{
      indicator: 'indicator',
    }}
  />
))`
  && {
    flex: 1;
    background: #e6eff8;
  }

  && .indicator {
    background-color: ${props => props.theme.aqp_light_cyan_blue};
  }
`;

export const CardsContainer = styled(props => {
  return (
    <Grid
      spacing={2}
      item
      container
      xs={12}
      alignContent="flex-start"
      alignItems="flex-start"
      {...props}
    />
  );
})`
  && {
    height: 100%;
    margin: 0px;
    overflow: scroll;
    position: relative;
  }
`;

export const StyledPaper = styled(Paper)`
  && {
    flex-grow: 1;
    margin: 7px;
    border-radius: 10px;
    overflow-y: auto;
    display: flex;
    position: relative;
    @media only screen and (max-width: 960px) {
      margin: 0px;
    }
  }
`;

export const ContainerDiv = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
`;

export const ListContainer = styled(Paper)`
  min-height: 80%;
  width: 250px;
  margin: 20px;
  display: flex;
  flex-direction: column;
`;

export const HeaderDiv = styled.div`
  display: ${({ mobile }) => (mobile === 'true' ? 'none' : 'flex')};
  flex-direction: row;
  min-height: 48px;
`;

export const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  background: #dee7f0;
  flex-basis: 14%;
  justify-content: center;
  min-height: 48px;
`;

export const StyledTab = styled(Tab)`
  && {
    min-width: 105px !important;
    font-size: medium;
    font-variant-caps: all-petite-caps;
  }
`;

export const StyledCardGrid = styled(Grid)`
  height: 7em;
  padding: 12px;
  cursor: pointer;
  border-left: ${props =>
    props.color ? `5px solid ${props.theme[props.color]}` : ''};
`;

export const AddCardGrid = styled(StyledCardGrid)`
  border: ${props => `2px dotted ${props.theme[props.color]}`};
  color: ${props => props.theme[props.color]};
  border-radius: 4px;
`;

export const StyledFlagIcon = styled(Icon)`
  margin-right: 10px;
`;

export const BorderGrid = styled(Grid)`
  border-bottom: 1px solid lightgray;
  min-height: 3em;
  height: ${props => props.height};
  padding: ${props => (props.padding ? props.padding : '1em')}
  background-color: ${props =>
    props.graybg === 'true' ? props.theme.aqp_bg_lighter_gray : 'white'};
`;

export const CategoryTab = styled(BorderGrid)`
  padding: 0.2em 2em;
  border-left: ${props =>
    props.isactive === 'true'
      ? `5px solid ${props.theme.aqp_deep_sea}`
      : 'unset'};
  cursor: pointer;
`;

export const Title = styled(Typography)`
  && {
    padding: 6px 0px 4px 0px;
    text-transform: capitalize;
  }
`;

export const SubTitle = styled(Typography)`
  && {
    padding: 0px 0px 6px 0px;
    color: ${Colors.aqp_gray};
    font-variant-caps: all-petite-caps;
  }
`;

export const ActionButton = styled(Button)`
  && {
    font-size: 0.7em;
  }
`;

export const CardContainer = styled(Card)`
  && {
    position: relative;
    margin: 1em;
    padding: 1.5em;
  }
`;

export const StyledCardActions = styled(CardActions)`
  justify-content: center;
`;

export const EditContainer = styled.div`
  && {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
`;

export const EditContent = styled.div`
  && {
    width: fit-content;
    padding: 5px 15px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: #50a2fe;
    border-radius: 0px 0px 5px 5px;
  }
`;

export const CancelButton = styled(CancelIcon)`
  && {
    color: ${props => props.theme.aqp_error};
    height: 0.7em;
  }
`;

export const StyledSaveIcon = styled(SaveIcon)`
  && {
    font-size: 1.3em;
    margin-right: 4px;
  }
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  && {
    font-size: 1.3em;
    margin-right: 4px;
  }
`;

export const StyledCancelIcon = styled(CancelIcon)`
  && {
    font-size: 1.3em;
    margin-right: 4px;
  }
`

export const StyledEditIcon = styled(EditIcon)`
  && {
    font-size: 1.3em;
    margin-right: 4px;
  }
`;

export const StyledDownloadIcon = styled(DownloadIcon)`
  && {
    font-size: 1.3em;
    margin-right: 4px;
  }
`;

export const RouteIcon = styled(ChangeRoute)`
  && {
    font-size: 1.3em;
    margin-right: 4px;
  }
`;

export const WarningButton = styled(ActionButton)`
  && {
    color: ${props => props.theme.aqp_error};
  }
`;

export const StyledLink = styled(NavLink)`
  && {
    width: 100%;
  }
`;

export const OuterWrapper = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
    position: relative;
  }
`;

export const DataAppletWrapper = styled.div`
  background-color: ${Colors.aqp_lightest_gray};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`

export const ContentGrid = styled(Grid)`
  && {
    flex-grow: 1;
    overflow: hidden;
  }
`;

export const IllustrationWrapper = styled.div`
  && {
    width: 100px;
    height: 100px;
  }
`;

export const NoContentDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 10%;
  align-self: stretch;
  > * {
    margin-bottom: 2%;
  }
`;

export const StyledAddButton = styled(props => {
  return (
    <Button variant="contained" color="primary" {...props}>
      <AddIcon />
    </Button>
  );
})`
  && {
    min-height: 32px;
  }
`;
