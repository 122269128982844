import styled from 'styled-components';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { Colors } from './../../../../aquaplot/colors';

export const StyledNavigation = styled(BottomNavigation)`
    && {
        border-top: 1px solid lightgray;
        flex: 0 0 auto;
    }
`

export const StyledAction = styled(BottomNavigationAction)`
    && {
        padding: 4px 8px 6px 8px;
    }

    && > span {
        color: ${({variant}) => variant === 'warning' ? Colors.aqp_warning : 'inherit'};
    }

    && > span > svg {
        margin: 0px;
        padding-bottom: 5px;
    }
`