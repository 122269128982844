import React from 'react';
import VesselMarker from '../../markers/VesselMarker';

const Pure = ({
    data,
    setHighlighted,
    highlightedImo,
    addWaypointCandidate
}) => {
    // console.log(data)
    return (
        data.map((vessel, index) => 
            <VesselMarker 
                key={`key-imo-${vessel.imo}-${index}`}
                highlighted={highlightedImo ? Number(highlightedImo) === vessel.imo : false}
                vessel={vessel}
                onClick={(e) => {
                //   console.log('clicked on vessel marker', e, vessel)
                  addWaypointCandidate(vessel);
                }}
                onMouseOver={(e) => {
                    setHighlighted(vessel.imo);
                }}
                onMouseOut={(e) => {
                    setHighlighted(undefined);
                }}
            />
        )
    )
}

export default Pure;