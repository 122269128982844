import React from 'react';
import { connect } from 'react-redux';
import { setContextContent, updateWaypoint } from './../../../store/redux/actions';
import withApiEndpoints from './../../../store/withApiEndpoints';

import LeafletMarker from './LeafletMarker';
import BaseMarker from './BaseMarker';

import LocationContext from './../context/Location';
import LocationActions from './../context/LocationActions';

import { /*getValidateCall*/ postValidateCall as validateCall } from './../../../store/sagas/aqp-api-helper';


class WaypointMarker extends React.Component{

    onClick = () => {
        const { waypoint, setLocationContext } = this.props;
        
        setLocationContext(
            <LocationContext {...waypoint}/>, 
            <LocationActions waypoint={waypoint} />
        );
    }

    onDragEnd = ({latlng}) => {
        // console.log(e)
        const { 
            waypoint,
            updateWaypoint,
        } = this.props;

        validateCall({latlng})
        .then((res) => {
            if(res.suggestion){
                const validatedLatlng = {lat: res.suggestion[1], lng: res.suggestion[0]};
                const updated = Object.assign({}, waypoint, {latlng: validatedLatlng}, { forceUpdate: true });
                updateWaypoint(updated);
            }
        })
        .catch(() => {});
    }

    shouldComponentUpdate(nextProps){
        return (
            nextProps.index !== this.props.index ||
            nextProps.waypoint.latlng.lat !== this.props.waypoint.latlng.lat ||
            nextProps.waypoint.latlng.lng !== this.props.waypoint.latlng.lng
        );
    }

    render(){
        const { 
            waypoint: {
                draggable,
                latlng
            }, 
            index, 
        } = this.props;

        return <LeafletMarker
            draggable={draggable}
            onDragEnd={draggable ? this.onDragEnd : () => {}}
            onClick={this.onClick}
            latlng={latlng}
            markerComponent={<BaseMarker number={index} />} 
        />;
    }

}
  
  const mapDispatchToProps = (dispatch) => {
    return { 
        setLocationContext: (content, actions) => {
            dispatch(setContextContent.call(content, actions))
        },
        updateWaypoint: (updated) => {
            dispatch(updateWaypoint.call(updated))
        }
    }
  }

const WaypointMarkerContainer = connect(
    null,
    mapDispatchToProps
  )(withApiEndpoints(WaypointMarker))

export default WaypointMarkerContainer;