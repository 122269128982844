import axios from 'axios';
import { functionWithApiEndpoints } from './../store/withApiEndpoints';

const axiosConfig = {
  mode: 'no-cors',
  withCredentials: true,
};

export const connectBunkerExAccount = functionWithApiEndpoints(({ accountEndpoint }) => {
  const url = `${accountEndpoint}/bunkerex/connect`;
  return axios
    .get(url, axiosConfig)
    .then(res => res.data )
    .catch(err => {
      throw err;
    });
});