import { requireFirebase, setUserDoc, setHasFirebasePrivilege } from './../actions'
import { update } from '../reducers/helper';

const initialFireStore = {
  loading: true,
  db: undefined,
  userDoc: null,
  userId: null,
  name: '',
  hasPrivilege: null
};

export function fireStoreReducer(state = initialFireStore, action) {
  switch (action.type) {
    case requireFirebase.SUCCEEDED:
      return update(state, {
        loading: false,
        db: action.payload,
      });

    case requireFirebase.FAILED:
      return update(state, {
        loading: false,
        userDoc: null,
      });

    case setUserDoc.type:
      return update(state, {
        userDoc: action.payload
      })

    case setHasFirebasePrivilege.type:
      return update(state, {
        hasPrivilege: action.payload.flag,
        userId: action.payload.userId,
      })

    default:
      return state;
  }
}
