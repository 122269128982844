import {
    compose,
    branch,
    withState,
    withHandlers,
    renderNothing,
    lifecycle
} from 'recompose';
import withLeaflet from './../../../../common/Leaflet/withLeaflet';
import { connect } from 'react-redux';
import { Colors } from './../../../../aquaplot/colors';

import L from 'leaflet';

const jwcStyle = () => {
    return { 
        color: Colors.aqp_error, 
        dashArray: "10", 
        weight: 0, 
        fillOpacity: 0.3,
        clickable: false,
    }
}

const jwcStyleWindy = () => {
    return { 
        color: Colors.aqp_error, 
        dashArray: "10", 
        weight: 0, 
        fillOpacity: 0.3,
        clickable: false
    }
}


const mapStateToProps = state => {
    return {
        showJwcAreas: state.map.showJwcAreas,
        jwcAreas: state.routing.jointWarCommitteeAreas,
    }
}

const Handlers = compose(
    withLeaflet,
    connect(mapStateToProps),
    withHandlers({
        defaultObject: () => () => {
            return { 
                geojsonData: [], 
                geojson: null,
                map: null, 
            };
        }
    }),
    withState('areas', 'setAreas', ({ defaultObject }) => defaultObject()),
    withHandlers({
        updateAreas: ({
            areas: {
                geojsonData,
                map,
                geojson
            },
            leafletMap,
            isWindyMap,
            showJwcAreas,
            jwcAreas,
            setAreas,
            defaultObject
        }) => () => {
            const data = showJwcAreas ? jwcAreas : [];
            //need to 'rerender' if either map has changed or routesData has changed
            const mapsChanged = leafletMap !== map;
            const dataChanged = JSON.stringify(data) !== JSON.stringify(geojsonData);

            if(leafletMap && (mapsChanged || dataChanged)){
                if(geojson && map){
                    //clear existing
                    map.removeLayer(geojson);
                }

                if(isWindyMap){
                    // console.log('adding')
                    const fg = L.geoJson(data, {
                        style: jwcStyleWindy
                    });

                    fg.addTo(leafletMap);
                    setAreas({ map: leafletMap, geojson: fg, geojsonData: data });
                }
                else{
                    // console.log('adding')
                    const fg = L.geoJSON(data, {
                        style: jwcStyle
                    });

                    fg.addTo(leafletMap);
                    setAreas({ map: leafletMap, geojson: fg, geojsonData: data });
                }
                
            }
            else if(!leafletMap && (map || geojsonData.length > 0 || geojson)){
                //clear existing
                map.removeLayer(geojson);
                setAreas(defaultObject());
            }
        }
    }),
    lifecycle({
        componentDidMount(){
            this.props.updateAreas();
            // console.log('mounted');
        },
        componentDidUpdate(){
            this.props.updateAreas();
            // console.log('updated');
        },
        componentWillUnmount(){
            this.props.updateAreas();
            // console.log('unmount')
        },
    }),
    branch(() => true, renderNothing)
)

export default Handlers;