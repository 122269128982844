import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const Pure = ({
    ButtonVariant,
    disabled,
    onClick,
    tooltip,
    label,
    noLabel,
    icon,
    ButtonComponent,
    to,
    Wrapper
}) => {
    return (
        <Tooltip title={tooltip || label}>
            <Wrapper>
                <ButtonVariant
                    disabled={disabled}
                    size="small"
                    onClick={onClick}
                    component={ButtonComponent}
                    to={to}
                >
                    { icon }
                    { noLabel ? null : label }
                </ButtonVariant>
            </Wrapper>
        </Tooltip>
    )
}

export default Pure;