import React, { Component } from 'react';
import { connect } from 'react-redux';
import withLeaflet from './../../../common/Leaflet/withLeaflet';

import MapButton from './MapButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

class ZoomControl extends Component {
  increaseZoom = () => {
    const { leafletMap } = this.props;
    if (leafletMap) leafletMap.zoomIn();
  };

  decreaseZoom = () => {
    const { leafletMap } = this.props;
    if (leafletMap) leafletMap.zoomOut();
  };

  render() {
    if(!this.props.showZoom) return null;
    const { color } = this.props;

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <MapButton 
          icon={<AddIcon />} 
          callback={this.increaseZoom}
          color={color}
        />
        <MapButton 
          icon={<RemoveIcon />} 
          callback={this.decreaseZoom} 
          color={color}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    showZoom: state.map.showZoomControl,
  };
};

const ZoomControlContainer = connect(mapStateToProps, null)(
  ZoomControl
);

export default withLeaflet(ZoomControlContainer);
