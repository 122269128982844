// @flow

import { ReduxAction, SagaReduxAction } from './actions/helper';

export * from './actions/map';
export * from './actions/context';
export * from './actions/applets';
export * from './actions/firebase';
export * from './actions/itineraries';
export * from './actions/auth'; 
export * from './actions/tracking';
export * from './actions/snacks'; 
export * from './actions/routing';
export * from './actions/app-internals';
export * from './actions/user';
export * from './actions/bunker-ex';
export * from './actions/bunker-plan';
export * from './actions/deviations';


export const AisSignal = new SagaReduxAction('AIS_SIGNAL', imo => imo);
export const AisRefresh = new SagaReduxAction('AIS_REFRESH', imoNumbers => imoNumbers);

export const setSingleVessel = new ReduxAction(
  'SINGLE_VESSEL_SET',
  (vessel) => vessel 
);

export const ECA_ALL_SUCCEEDED = 'ECA_ALL_SUCCEEDED';
export const ECA_ALL_FAILED = 'ECA_ALL_FAILED';

export const HRA_ALL_SUCCEEDED = 'HRA_ALL_SUCCEEDED';
export const HRA_ALL_FAILED = 'HRA_ALL_FAILED';

export const requestJointWarCommitteeAreas = new SagaReduxAction('JWC_AREAS');

export const POI_SEARCH_TERM_SET = 'POI_SEARCH_TERM_SET';
export const POI_SEARCH_TERM_CLEAR = 'POI_SEARCH_TERM_CLEAR';
export const POI_SUGGESTIONS_FETCH_REQUESTED =
  'POI_SUGGESTIONS_FETCH_REQUESTED';
export const POI_SUGGESTIONS_FETCH_SUCCEEDED =
  'POI_SUGGESTIONS_FETCH_SUCCEEDED';
export const POI_SUGGESTIONS_FETCH_FAILED = 'POI_SUGGESTIONS_FETCH_FAILED';
export const POI_SUGGESTIONS_CLEAR = 'POI_SUGGESTIONS_CLEAR';

export function setGeocodeSearchSearchTerm(search_term) {
  return {
    type: POI_SEARCH_TERM_SET,
    payload: Object.assign({}, { search_term: search_term }),
  };
}

export function clearGeocodeSearchSearchTerm() {
  return { type: POI_SEARCH_TERM_CLEAR, payload: {} };
}

export function requestGeocodeSearchSuggestions(name) {
  return {
    type: POI_SUGGESTIONS_FETCH_REQUESTED,
    payload: Object.assign({}, { name: name }),
  };
}

export function clearGeocodeSearchSuggestions() {
  return { type: POI_SUGGESTIONS_CLEAR, payload: {} };
}

export const LOCATIONS_FETCH_REQUESTED = 'LOCATIONS_FETCH_REQUESTED';
export const LOCATIONS_FETCH_SUCCEEDED = 'LOCATIONS_FETCH_SUCCEEDED';
export const LOCATIONS_FETCH_FAILED = 'LOCATIONS_FETCH_FAILED';
export const LOCATIONS_CLEAR = 'LOCATIONS_CLEAR';

export function requestLocationSuggestions(name) {
  return {
    type: LOCATIONS_FETCH_REQUESTED,
    payload: Object.assign({}, { name: name }),
  };
}

export function setLocationSuggestions(locations) {
  return { type: LOCATIONS_FETCH_SUCCEEDED, payload: locations };
}

export function clearLocationSuggestions() {
  return { type: LOCATIONS_CLEAR, payload: {} };
}

export const LOCATIONS_ALL_REQUESTED = 'LOCATIONS_ALL_REQUESTED';
export const LOCATIONS_ALL_SUCCEEDED = 'LOCATIONS_ALL_SUCCEEDED';
export const LOCATIONS_ALL_FAILED = 'LOCATIONS_ALL_FAILED';

export function requestLocationsData() {
  return { type: LOCATIONS_ALL_REQUESTED, payload: {} };
}




export const USER_LOGOUT_REQUESTED = 'USER_LOGOUT_REQUESTED';
export const USER_LOGOUT_SUCCEEDED = 'USER_LOGOUT_SUCCEEDED';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';

export function requestUserLogout(usr) {
  return { type: USER_LOGOUT_REQUESTED, payload: { usr: usr } };
}

export const UPDATE_NAME = 'UPDATE_NAME';
export function updateName(firstName, lastName) {
  return {
    type: UPDATE_NAME,
    payload: { firstName: firstName, lastName: lastName },
  };
}
export const toggleItineraryRedirect = new ReduxAction('UI_REDIRECT_ITINERARY_TOGGLE');

// export const FETCH_COUNTRIES_REQUESTED = 'FETCH_COUNTRIES_REQUESTED';
export const FETCH_COUNTRIES_SUCCEEDED = 'FETCH_COUNTRIES_SUCCEEDED';
export const FETCH_COUNTRIES_FAILED = 'FETCH_COUNTRIES_FAILED';
// export function fetchCountries() {
//   return {
//     type: FETCH_COUNTRIES_REQUESTED,
//     payload: {},
//   };
// }
