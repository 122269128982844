import { takeLatest, select } from 'redux-saga/effects'
import { 
  anyAuth, 
  HRA_ALL_SUCCEEDED as SUCCESS, 
  HRA_ALL_FAILED as FAILURE 
} from './../redux/actions'
import { singleAsync } from './saga-helper.js'
import { getHraAllCall } from './aqp-api-helper.js'

const PolygonGeometry = {
    "type": "Polygon",
    "coordinates": []
};

const GeojsonFeature = {
    "type": "Feature",
    "properties": {},
    "geometry": PolygonGeometry,
};

const GeojsonFeatureCollection = {
    "type": "FeatureCollection",
    "features": [],
};

const success_transform = (response) => {
    let coll = Object.assign({}, GeojsonFeatureCollection);
    const areas = response.high_risk_areas;
    
    for(let key of Object.keys(areas)){
        const geometry = Object.assign({}, PolygonGeometry, {"coordinates": [areas[key]]});
        const feature = Object.assign({}, GeojsonFeature, { "geometry": geometry });
        coll.features.push(Object.assign({}, feature));
    }

    //console.log(JSON.stringify(coll));

    return coll;
} 

function* getAllHraAreas(action) {
  const { 
    highRiskAreas,
    highRiskAreasLastUpdated
   } = yield select(state => state.routing);
  if(
    highRiskAreas && 
    highRiskAreas.type && 
    highRiskAreasLastUpdated !== null && 
    (Date.now() - highRiskAreasLastUpdated < 24 * 60 * 60 * 1e3)
  ) return;

  yield singleAsync(getHraAllCall, SUCCESS, FAILURE, action.payload, 0, success_transform);
}

export default function* getAllHighRiskAreasSaga() {
  yield takeLatest(anyAuth.SUCCEEDED, getAllHraAreas);
}
