import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    background: '#607d8b',
    color: 'white',
  },
  drawerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    paddingBottom: '5%',
  },
};

const mapStateToProps = state => {
  return {
    mobile: state.ui.mobile,
  };
};

const TestRightDrawerHandlers = compose(
  withStyles(styles),
  connect(mapStateToProps)
);

export default TestRightDrawerHandlers;
