import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import UrlTriggerLink from './../../../../common/UrlTriggerLink';

import {
    GridWrapper
} from './styledComponents';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

const Pure = ({
    data,
    columnDefinitions
}) => {
    // console.log(data, columnDefinitions)
    return (
        <React.Fragment>
            <Typography variant="overline">Waypoints</Typography>
            <UrlTriggerLink urlTrigger={'addNodes'}>
              <IconButton style={{float: 'right'}} size='small'><AddIcon /></IconButton>
            </UrlTriggerLink>
            <GridWrapper className={`ag-theme-balham`}>
                <AgGridReact
                    columnDefs={columnDefinitions}
                    rowData={data}
                />
            </GridWrapper>
        </React.Fragment>
    )
}

export default Pure;