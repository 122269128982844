export const denormalizedRoutes = (routes) => {
    const lngs = routes
        .map((r) => r.features[0].geometry.coordinates)             // get coordinates
        .map(coordinates => coordinates[coordinates.length - 1][0]) // get lng from last point
    const lats = routes
        .map((r) => r.features[0].geometry.coordinates)             // get coordinates
        .map(coordinates => coordinates[coordinates.length - 1][1]) // get lat from last point
    
    if(!lngs) return routes; // routes must have been empty array

    routes.forEach((route, idx) => {
        if(idx === 0) return; //skip first
        const startLng = route.features[0].geometry.coordinates[0][0];
        const startLat = route.features[0].geometry.coordinates[0][1];
        const lastLng = lngs[idx - 1];
        const lastLat = lats[idx - 1];
        const deltaLng = startLng - lastLng;
        const deltaLat = startLat - lastLat;

        if(Math.abs(deltaLng) > 0 && Math.abs(deltaLat) < 0.01){
            route.features[0].geometry.coordinates = route.features[0].geometry.coordinates.map((c) => [c[0] - deltaLng, c[1]])
        }
    });

    return routes;
}

export const denormalizedLatLng = (waypoint, index, segments) => {
    if(segments.length === 0) return waypoint.latlng;
    const isLast = index === segments.length; //index of waypoint array which has 1 more than segments
    const seg = segments[isLast ? index - 1 : index];
    if(!seg.route || seg.route.status !== "ok") return waypoint.latlng;
    const coordinates = seg.route.features[0].geometry.coordinates;
    const coords = isLast ? coordinates[coordinates.length - 1] : coordinates[0];
    const latlng = { lng: coords[0], lat: coords[1] }
    return latlng;
}