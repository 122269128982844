import Pure from './pure';
import Handlers from './handlers';
import { reduxForm } from 'redux-form';
import { validate } from './validate';

const ContactUs = Handlers(Pure);

// export const PrepopulatedContactUs = initialValues =>
//   reduxForm({
//     form: 'contact-us',
//     validate: validate,
//     initialValues: initialValues,
//   })(ContactUs);

export default reduxForm({
  form: 'contact-us',
  validate: validate,
})(ContactUs);
