import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

export const StyledOuterGrid = styled(Grid)`
    && {
        margin: 8px;
    }
`

export const StyledPaper = styled(Paper)`
    && {
        background-color: #fbfbfb;
        padding: 8px;
        height: 100%;
    }
`