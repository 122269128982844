import React from 'react';

import {
    NoContentDiv,
    IllustrationWrapper
  } from '../../styledComponents';
import Typography from '@material-ui/core/Typography';
import { NoData } from './../../../../illustrations';

const Pure = ({msg}) => {
    return (
        <NoContentDiv>
            <IllustrationWrapper>
                <NoData />
            </IllustrationWrapper>
            <Typography>{ msg }</Typography>
        </NoContentDiv>
    )
}

export default Pure;