import * as actions from './../actions';
import { update } from './helper';

const initialContext = {
  content: null,
  contextActions: null,
};

function contextReducer(contextState = initialContext, action) {
  switch (action.type) {
    case actions.setContextContent.type:
      const { content, contextActions } = action.payload;
      return update(contextState, {
        content: content,
        contextActions: contextActions,
      });

    case actions.clearContextContent.type:
      return update(contextState, {
        content: null,
        contextActions: null,
      });

    default:
      return contextState;
  }
}

export default contextReducer;
