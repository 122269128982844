import {
    compose,
    withState,
    withProps
} from 'recompose';

class Clipboard {
    constructor() {
        this._map = new Map();
    }

    get(key){
        if(this._map.has(key)){
            // clear timer to effectively extend object lifetime
            const { value } = this._map.get(key);
            if(Array.isArray(value)){
                return [].concat(value)
            }
            return Object.assign({}, value);
        } 
        else return undefined;
    }

    set(key, value, { callback, timeout } = {}){
        if(this._map.has(key)){
            const { clearTimer, callback: cb } = this._map.get(key);
            if(cb){
                // call callback function with value as parameter, then auto-destroy
                setTimeout(() => {
                    this._map.delete(key)
                }, 3e3); // delete this entry in 3 seconds
                cb(value);
                return; // prevent setting any new entry
            }
            else{
                // clear timer to effectively extend object lifetime (if present) 
                clearTimer && clearTimeout(clearTimer);
            }
        }

        //new entry 
        this._map.set(key, Object.assign(
            {
                value
            },
            callback ? {
                callback,
            } : 
            {
                clearTimer: setTimeout(() => this._map.delete(key), timeout || 30e3),
            }
        ));
    }
}



const Handlers = compose(
    withState('clipboard', 'unused__setClipboard', new Clipboard()),
    withProps(({clipboard}) => ({
        copyToClipboard: (...args) => clipboard.set(...args), 
        getFromClipboard: (...args) => clipboard.get(...args), 
    }))
);

export default Handlers;