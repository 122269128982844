import { select } from 'redux-saga/effects'
import { 
  ItineraryAdded,
  ItineraryRemoved
} from './../../redux/actions'
import { aquaplotStore } from './../../index';


export function* addItinerary({ payload: doc }) {
    const {
        firestore: { userDoc },
      userItineraries: { itinerariesMetadata: meta },
    } = yield select(state => state);

    if(doc.id in meta){
        // console.log('ignore this one', doc.id); 
    }
    else{
        // console.log(meta, doc.id, doc.data());
        const itinerary = doc.data();
        const updatedMeta = Object.assign({}, meta, {
            [itinerary.data.uuid]: {
                uuid: itinerary.data.uuid,
                title: itinerary.data.title,
                subtitle: itinerary.data.subtitle,
                createdAt: itinerary.data.createdAt,
            }
        })
        // console.log(updatedMeta)
        
        userDoc
            .collection(`itineraries`)
            .doc('metadata')
            .update(updatedMeta)
            .then(() => {
                    aquaplotStore.dispatch({
                        type: ItineraryAdded.SUCCEEDED,
                        payload: itinerary
                    })
                })
            .catch(err => {
                    aquaplotStore.dispatch({
                        type: ItineraryAdded.FAILED,
                        payload: err
                    })
                })
    }
  
  }


export function* removeItinerary({ payload: uuid }) {
    const {
        firestore: { userDoc },
        userItineraries: { itinerariesMetadata: meta },
    } = yield select(state => state);

    
    const updatedMeta = Object.values(meta)
        .filter(obj => obj.uuid !== uuid)
        .reduce((acc, val) => Object.assign({}, acc, { [val.uuid]: val }), {});
    // console.log('remove', uuid, updatedMeta)
    
    userDoc
        .collection(`itineraries`)
        .doc('metadata')
        .set(updatedMeta)
        .then(() => {
                aquaplotStore.dispatch({
                    type: ItineraryRemoved.SUCCEEDED,
                    payload: uuid
                })
            })
        .catch(err => {
                aquaplotStore.dispatch({
                    type: ItineraryRemoved.FAILED,
                    payload: err
                })
            })
}


