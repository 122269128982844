import {
    compose,
    withHandlers
} from 'recompose';

const Handlers = compose(
    withHandlers({
        getTitle: ({title}) => (obj) => title ? title(obj) : obj.title,
        getSubTitle: ({subtitle}) => (obj) => subtitle ? subtitle(obj) : obj.subtitle,
    })
);

export default Handlers;