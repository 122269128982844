import React, { PureComponent } from 'react';
import ValueSection from './ValueSection';
import NameField from './NameField';
import LocodeField from './LocodeField';

export default class NameSection extends PureComponent {
  render() {
    const { 
      name, 
      onNameChange,
      locode,
      onLocodeChange 
    } = this.props;

    return (
      <ValueSection header="Description">
        <NameField name={name} onNameChange={onNameChange} />
        <LocodeField locode={locode} onLocodeChange={onLocodeChange} />
      </ValueSection>
    );
  }
}
