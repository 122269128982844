import React, { PureComponent } from 'react';
import ValueField from './ValueField';

export default class NameField extends PureComponent {
  render() {
    const { name, onNameChange } = this.props;

    return (
      <ValueField label="Name" value={name} unit="" onChange={onNameChange} />
    );
  }
}
