import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {
    CircleSpan
} from './styledComponents';

const Pure = ({
    node
}) => {
    return (
        <Tooltip title={node}>
            <CircleSpan>?</CircleSpan>
        </Tooltip>
    )
}

export default Pure;