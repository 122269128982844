import styled from 'styled-components';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { components } from 'react-select';

export const StyledExpansionPanelSummary = styled(ExpansionPanelSummary)`
    & > div > div {
        flex-grow: 1;
    }
`

export const StyledExpansionPanelDetails = styled(ExpansionPanelDetails)`
    && {
        justify-content: flex-end;
    }
`

export const StyledOption = styled(Grid)`
    && {
        padding: 10px;
        color: black;
        display: flex;
        min-height: 36px;
    }
`

export const StyledOptionTitle = styled(Typography)`
    &&{
        padding-right: 10px;
        text-transform: capitalize;
    }
`

export const StyledOptionSubtitle = styled(Typography)`
    && {
        color: #706F75;
        font-variant-caps: all-petite-caps;
    }
`

export const StyledValueContainer = styled(components.ValueContainer)`
    && {
        flex-wrap: nowrap;
        min-height: 60px;
    }
`

export const StyledToolbarGrid = styled(Grid)`
    && {
        flex: 0 0 auto;
    }
`