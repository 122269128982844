import {
  FETCH_USER_VESSELS_REQUESTED,
  FETCH_USER_VESSELS_SUCCEEDED,
  FETCH_USER_VESSELS_FAILED,
} from '../../types';
import groupBy from 'lodash/groupBy';

const initialVessels = {
  loading: true,
  listenerInstantiated: false,
  vessels: {},
  error: undefined,
  groupedVessels: {}
};

const groupVessels = vessels => {
  let groupedVessels = {};
  groupedVessels['all'] = Object.keys(vessels).map(imo => vessels[imo]);
  groupedVessels = Object.assign(groupedVessels, groupBy(vessels, 'type'));
  return groupedVessels;
};

export function userVesselsReducer(state = initialVessels, action) {
  switch (action.type) {
    case FETCH_USER_VESSELS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_VESSELS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: undefined,
        vessels: action.payload,
        listenerInstantiated: true,
        groupedVessels: groupVessels(action.payload),
      };
    case FETCH_USER_VESSELS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
