import { SagaReduxAction } from './helper';

export const cookieAuth = new SagaReduxAction('AUTH_VIA_COOKIE');
export const urlAuth = new SagaReduxAction('AUTH_VIA_URL', token => token); 
export const emailAuth = new SagaReduxAction('AUTH_VIA_EMAIL', 
    (usr, pwd, remember_me) => 
    Object.assign({ 
        usr: usr, 
        pwd: pwd, 
        remember_me: remember_me 
    }));

export const anyAuth = new SagaReduxAction('AUTH_ANY', authMode => authMode);

export const firebaseAuth = new SagaReduxAction('AUTH_FIREBASE'); 