import React from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { clearSnackbar } from './../../store/redux/actions';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const AppSnackbar = ({
  snack: {
    snack_open, 
    snack_message, 
    snack_autohide,
    snack_with_close_action,
    snack_with_confirm_to_close,
    snack_with_page_refresh_to_close,
    snack_installingWorker
  },
  clear
}) => {
  const preventAutoHide = snack_with_confirm_to_close || snack_with_close_action || snack_with_page_refresh_to_close;
  return (
    <Snackbar
      open={snack_open}
      message={snack_message}
      autoHideDuration={preventAutoHide ? null : snack_autohide}
      onClose={clear}
      action={[
        snack_with_confirm_to_close && <Button variant="outlined" key="ok" color="secondary" size="small" onClick={clear}>
          OK
        </Button>,
        snack_with_close_action && <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={clear}
        >
          <CloseIcon />
        </IconButton>,
        snack_with_page_refresh_to_close && <Button variant="outlined" key="Refresh" color="secondary" size="small" onClick={() => {
          if(snack_installingWorker){
            snack_installingWorker.postMessage('skipWaiting');
          }
          window.location.reload();
          clear();
        }}>Refresh</Button>
      ]}
    />
  );
}

const mapStateToProps = state => {
  return {
    snack: state.snack
  };
};

const mapDispatchToProps = dispatch => {
    return {
        clear: () => dispatch(clearSnackbar.call())
    }
}

const AppSnackbarContainer = connect(mapStateToProps, mapDispatchToProps)(AppSnackbar);

export default AppSnackbarContainer;
