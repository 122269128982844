import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Select, { createFilter } from 'react-select';
import {
    BorderGrid,
} from './../styledComponents';
import {
    StyledOption,
    StyledOptionTitle,
    StyledOptionSubtitle,
    StyledValueContainer,
    StyledToolbarGrid
} from './styledComponents';

const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: 'any',
};

const Option = (buildLink, noSubtitle) => ({data, innerProps, innerRef }) => {
    return (
      <Link to={buildLink(data)}>
        <div ref={innerRef} {...innerProps}>
        <StyledOption container>
            <Grid item xs={noSubtitle ? 12 : 6}>
                <StyledOptionTitle noWrap>{data.title}</StyledOptionTitle>
            </Grid>
            {
                noSubtitle || 
                <Grid item xs={6}>
                    <StyledOptionSubtitle noWrap>{data.subtitle}</StyledOptionSubtitle>
                </Grid>
            }
        </StyledOption>
        </div>
      </Link>
    );
  };

const ValueContainer = ({ children, ...props }) => (
    <StyledValueContainer {...props}>
        {children}
    </StyledValueContainer>
);

const SingleValue = (noSubtitle) => (props) => {
    const {
        data,
    } = props;
    // console.log(props)
    const { title, subtitle } = data;
    return (
        <StyledOption container>
            <Grid item xs={12}>
                <StyledOptionTitle noWrap>{title}</StyledOptionTitle>
            </Grid>
            {
                noSubtitle ||
                <Grid item xs={12}>
                    <StyledOptionSubtitle noWrap>{subtitle}</StyledOptionSubtitle>
                </Grid>
            }
        </StyledOption>
    );
  };

const Pure = ({
    mobileProps: {
        selectOptions,
        buildLink,
        onClear,
        noSubtitle
    },
    selected,
    setSelected,
    getTitle,
    getSubTitle
}) => {
    // console.log(selectedItinerary)
    return (
        <StyledToolbarGrid container>
            <BorderGrid
                justify="space-between"
                alignItems="center"
                container
                padding={"0px"}
                graybg="true"
                item
            >
                <Grid item xs={12} container>
                    <Grid item xs={12}>
                        <Select
                            name="mobile-select"
                            value={selected}
                            onChange={(data) => {
                                setSelected(data);
                                if(!data) onClear();
                            }}
                            multi={false}
                            isClearable={true}
                            isSearchable={true}
                            options={selectOptions.map(option => {
                                const { title, subtitle, ...rest} = option;
                                return Object.assign({
                                    title: getTitle(option),
                                    subtitle: getSubTitle(option),
                                }, rest);
                            })}
                            getOptionLabel={({title, subtitle}) => {
                                return (title + (noSubtitle ? '' : ' ' + subtitle)).replace(/\s{2,}/g, ' '); 
                            }}
                            getOptionValue={({title, subtitle}) => {
                                return (title + (noSubtitle ? '' : ' ' + subtitle)).replace(/\s{2,}/g, ' '); 
                            }}
                            filterOption={createFilter(filterConfig)}
                            components={{ 
                                Option: Option(buildLink, noSubtitle), 
                                SingleValue: SingleValue(noSubtitle),
                                ValueContainer
                            }}
                        />
                    </Grid>
                </Grid>
            </BorderGrid>
        </StyledToolbarGrid>
    )
}

export default Pure;