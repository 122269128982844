import { aquaplotStore } from './../../index';
import {
    FETCH_USER_FLEETS_SUCCEEDED,
    FETCH_USER_FLEETS_FAILED,
} from '../../types';

const fleetListener = doc => {
    if (doc.exists) {
        aquaplotStore.dispatch({
            type: FETCH_USER_FLEETS_SUCCEEDED,
            payload: doc.data().fleets,
        });
    } else {
        aquaplotStore.dispatch({
            type: FETCH_USER_FLEETS_FAILED,
            payload: 'No such document!',
        });
    }
}

const fleetErrorHandler = error => {
    console.log('Error getting document:', error);
    aquaplotStore.dispatch({
        type: FETCH_USER_FLEETS_FAILED,
        payload: `Error getting document: ${error}`,
    });
}

export function* bootstrapFleets(userDoc){
    if (process.env.REACT_APP_SHOW_DEBUG === 'true') console.log('bootstrap fleets')
    yield userDoc.collection('fleets')
    .doc('metadata')
    .onSnapshot(fleetListener, fleetErrorHandler);
}