import React from 'react';
import AquaplotApiContext from './../Context';

const EndpointProvider = ({
    children,
    endpoints
}) => {
    return (
        <AquaplotApiContext.Provider value={endpoints}>
            { children }
        </AquaplotApiContext.Provider>
    )
}

export default EndpointProvider;