import React, { PureComponent } from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Colors } from '../../../aquaplot/colors';

const StyledButton = styled(IconButton)`
  && {
    pointer-events: all;
    color: ${({iconcolor}) => iconcolor || Colors.aqp_deep_sea};
  }
`

export default class MapButton extends PureComponent {
  clickHandler = (e, callback) => {
    e.stopPropagation();
    callback(e);
  };

  render() {
    const { icon, callback, tooltip, color } = this.props;

    return (
      <Tooltip title={tooltip || ''} placement="left" enterDelay={1000}>
        <StyledButton 
          onClickCapture={callback ? e => this.clickHandler(e, callback) : undefined}
          iconcolor={color}
        >
          {icon}
        </StyledButton>
      </Tooltip>
    );
  }
}
