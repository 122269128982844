import { takeLatest, takeEvery, select, call } from 'redux-saga/effects';
import {
  UserProfile,
  RemoveEmailFromUserProfile,
  AddEmailToUserProfile,
  ChangeUserProfile,
  anyAuth
} from './../redux/actions';
import { functionWithApiEndpoints } from './../withApiEndpoints';
import { singleAsync } from './saga-helper.js'
import axios from 'axios';

const axiosConfig = {
  mode: 'no-cors',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

/* ****************************************
  FETCH USER PROFILE
 * ****************************************
*/
const fetchUserProfile = functionWithApiEndpoints(({ accountEndpoint }) => {
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  return axios.get(`${accountEndpoint}/account/profile`, axiosConfig)
    .then(response => response.data);
});

function* getUserProfile() {
  yield singleAsync(fetchUserProfile, UserProfile.SUCCEEDED, UserProfile.FAILED);
}

function* getUserProfileAfterInitialAuth(){
  const userLoaded = yield select(state => state.user.userLoaded);
  if(userLoaded) return;
  yield call(getUserProfile);
}

/* ****************************************
  REMOVE EMAIL FROM USER PROFILE
 * ****************************************
*/
const deleteEmailFromUserProfile = functionWithApiEndpoints(({ accountEndpoint, email }) => {
  console.log(email)
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  return axios.delete(`${accountEndpoint}/account/profile/${email}`, axiosConfig)
    .then(response => response.data);
});

function* removeEmailFromUserProfile(action) {
  yield singleAsync(
    deleteEmailFromUserProfile, 
    RemoveEmailFromUserProfile.SUCCEEDED, 
    RemoveEmailFromUserProfile.FAILED,
    action.payload);
}

/* ****************************************
  ADD EMAIL TO USER PROFILE
 * ****************************************
*/
const createEmailForUserProfile = functionWithApiEndpoints(({ accountEndpoint, email }) => {
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  return axios.post(`${accountEndpoint}/account/profile/${email}`, {}, axiosConfig)
    .then(response => response.data);
});

function* addEmailToUserProfile(action) {
  yield singleAsync(
    createEmailForUserProfile, 
    AddEmailToUserProfile.SUCCEEDED, 
    AddEmailToUserProfile.FAILED,
    action.payload);
}

/* ****************************************
  CHANGE USER PROFILE
 * ****************************************
*/
const updateUserProfile = functionWithApiEndpoints(({ accountEndpoint, userProfile }) => {
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  // console.log(userProfile)
  return axios.post(`${accountEndpoint}/account/profile_edit`, userProfile, axiosConfig)
    .then(response => response.data);
});

function* changeUserProfile(action) {
  yield singleAsync(
    updateUserProfile, 
    ChangeUserProfile.SUCCEEDED, 
    ChangeUserProfile.FAILED,
    action.payload);
}

/* ****************************************
  USER SAGAS
 * ****************************************
*/

export default function* UserProfileSaga() {
  yield takeLatest(UserProfile.REQUESTED, getUserProfile);
  yield takeEvery(anyAuth.SUCCEEDED, getUserProfileAfterInitialAuth);
  yield takeEvery(RemoveEmailFromUserProfile.REQUESTED, removeEmailFromUserProfile);
  yield takeEvery(AddEmailToUserProfile.REQUESTED, addEmailToUserProfile);
  yield takeEvery(ChangeUserProfile.REQUESTED, changeUserProfile);
}