import React, { PureComponent } from 'react';
import ValueSection from './ValueSection';
import TypeField from './TypeField';

export default class TypeSection extends PureComponent {
  render() {
    const { 
        type,
        onTypeChange
    } = this.props;

    return (
      <ValueSection header="Type">
        <TypeField 
            type={type} 
            onTypeChange={onTypeChange}
        />
      </ValueSection>
    );
  }
}
