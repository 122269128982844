import * as actions from './../actions';
import { update } from './helper';

const initialNavigation = {
  appletName: '',
  linkPaths: [],
};

function navigationReducer(navigationState = initialNavigation, { type, payload }) {
  switch (type) {
    case actions.setNavigationLinkPaths.type:
      return update(navigationState, {
        linkPaths: payload,
      });

    default:
      return navigationState;
  }
}

export default navigationReducer;
