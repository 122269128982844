import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom'; 
import Grid from '@material-ui/core/Grid';

export const SelectContainer = styled((props) => (
    <Grid 
        item 
        xs={12} 
        md={3} 
        container
        alignItems="flex-start"
        direction="column"
        {...props}
    />
))`
    && {
        background-color: ${props => props.theme.aqp_bg_lighter_gray};
        max-height: 100%;
        overflow: auto;
        flex-wrap: nowrap;
    }
`

export const StyledLink = styled(NavLink)`
  && {
    width: 100%;
  }

  &.active{
    border-left: 5px solid ${props => props.theme.aqp_deep_sea};
  }
`

