import { SagaReduxAction, ReduxAction } from './helper';

export const Deviation = new SagaReduxAction('DEVIATION', 
    (collectionPath, docIds, portDenormalizedLng) => ({
        collectionPath,
        docIds,
        portDenormalizedLng
    })
);

export const clearDeviation = new ReduxAction('DEVIATION_CLEAR');