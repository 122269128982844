import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

export const StyledGrid = styled(Grid)`
  padding: 2%;
  background-color: #fafafa;
  margin-bottom: 4% !important;
`;

export const ContactGridContainer = styled(Grid)`
  height: 100%;
`;

export const GridContainer = styled(ContactGridContainer)`
  height: auto;
  overflow-y: auto;
`;

export const StyledHeading = styled(Typography)`
  color: #093352 !important;
  margin: 3% 0% 1% 0 !important;
`;

export const StyledTextField = styled(TextField)`
  margin-bottom: 4% !important;
`;

export const StyledButton = styled(Button)`
  align-self: flex-end;
  margin-bottom: 4% !important;
  width: 150px;
`;
