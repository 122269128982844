import { takeLatest, takeEvery, select, all, put, call, take, actionChannel, /*delay*/ } from 'redux-saga/effects'
import { buffers } from 'redux-saga'
import { 
  Routes, 
  requestSingleRoute,
  addWaypoint,
  updateWaypoint,
  updateWaypointProperties,
  sortWaypoints,
  removeWaypoint,
  SETTINGS_CHANNEL_SET,
  SETTINGS_CHANNEL_CLOSE,
  addNogoArea,
  deleteNogoArea,
  setEca,
  setHra,
  setJwc,
  updateRouteOptionsObject,
  appBootstrapped
} from './../redux/actions'
import { /*getRouteCall*/ postRouteCall as RouteCall } from './aqp-api-helper.js';
import { /*multipleAsync, */ singleAsync } from './saga-helper.js'

function* updateRouteOptions(){
  const {
    nogoAreas,
    channels,
    eca,
    hra,
    jwc
  } = yield select(state => state.routing);

  const options = {
    eca: eca ? 'minimize' : 'ignore',
    hra: hra && !jwc ? 'minimize' : 'ignore',
    jwc: jwc ? 'minimize' : 'ignore',
    nogoBlocks: nogoAreas.map(({latlngs}) => ({ coords: latlngs })),
    autovalidate: 'true',
    ...channels.reduce((acc, { slug , open }) => ({ ...acc, [slug]: `${open}` }), {})
  };

  yield put(updateRouteOptionsObject.call(options));
}

const optionsTriggers = [
  SETTINGS_CHANNEL_CLOSE,
  SETTINGS_CHANNEL_SET,
  addNogoArea.type,
  deleteNogoArea.type,
  setEca.type,
  setHra.type,
  setJwc.type,
  appBootstrapped.type
];

export function* routeOptionsSaga() {
  yield takeLatest(optionsTriggers, updateRouteOptions);
}

const route_is_stale = (optionsObject) => (segment) => {
  const is_stale = JSON.stringify(optionsObject) !== JSON.stringify(segment.optionsObject);
  // console.log(optionsObject, segment.optionsObject, is_stale)
  return is_stale;
}

function* getRoutes(action) {
  // console.log(action.type)
  const routingState = yield select(state => state.routing);
  const { segments, autocalculate, optionsObject } = routingState;
  if(!autocalculate) return;

  const stalenessCheck = route_is_stale(optionsObject);
  // console.log('getRoutes triggered')
  const enhancedSegments = segments.map(segment => 
    Object.assign(
      {}, 
      segment, 
      stalenessCheck(segment) ? { route: null } : {}, 
      {
        optionsObject
      }
    )
  );
  const remaining = enhancedSegments.filter((seg) => !seg.route && !seg.error);
  // console.log(remaining)

  yield all(remaining.map(seg => put({type: requestSingleRoute.REQUESTED, payload: seg})));
}

const triggers = [
  Routes.REQUESTED,
  addWaypoint.type,
  updateWaypoint.type,
  sortWaypoints.type,
  removeWaypoint.type,
  updateRouteOptionsObject.type
];

export default function* routeSaga() {
  yield takeLatest(triggers, getRoutes);
}

export function* singleRouteSaga() {
  const requestChan = yield actionChannel(requestSingleRoute.REQUESTED, buffers.sliding(20));
  while (true) {
    const { payload } = yield take(requestChan)

    // const t = performance.now();
    yield call(getRoute, payload)
    // const byMilliseconds = Math.max(0, 100 - (performance.now() - t));
    // yield delay(byMilliseconds)
  }
}

function* getRoute(p){
  const success_transform = (route) => [ 
    { 
      route: route, 
      optionsObject: p.optionsObject,
    } 
  ];
  yield singleAsync(RouteCall, Routes.SUCCEEDED, Routes.FAILED, p, 0, success_transform);  
}

const propertiesToUpdate = ({ eca, eca_name, validatedLatlng, timezone_of_validated, eez_country_code, sea_name }) => ({
  eca,
  eca_name,
  latlng: validatedLatlng,
  timezone: timezone_of_validated,
  eez_country_code: eez_country_code,
  sea_name: sea_name
})

const overwriteName = (id, waypoints, sea_name) => {
  const wp = waypoints.filter(wp => wp.uuid === id);
  return sea_name !== '' && wp.length === 1 && wp[0].name === 'Custom';
}

function* handleAutovalidate({payload}){
  const waypoints = yield select(state => state.routing.waypoints);

  const actions = payload
    .filter(({route: { features }}) => features[0].properties.validation)
    .map(({route: { id, features }}) => {
      const validation = features[0].properties.validation;
      const ids = id.split('~');
      // console.log(overwriteName(ids[0], waypoints), overwriteName(ids[1], waypoints))
      return [
        updateWaypointProperties.call(Object.assign(
          {
            uuid: ids[0]
          }, 
          propertiesToUpdate(validation.from),
          overwriteName(ids[0], waypoints, validation.from.sea_name) ? {name: validation.from.sea_name} : {}
        )),
        updateWaypointProperties.call(Object.assign(
          {
            uuid: ids[1]
          }, 
          propertiesToUpdate(validation.to),
          overwriteName(ids[1], waypoints, validation.to.sea_name) ? {name: validation.to.sea_name} : {}
        ))
      ]
    })
    .reduce((acc, arr) => acc.concat(arr), []);

  yield all(actions.map(action => put(action)));
}

export function* handleAutovalidateSaga(){
  yield takeEvery(Routes.SUCCEEDED, handleAutovalidate);
}