export const validate = values => {
  let errors = {};
  if (!values.title) {
    errors.title = 'Required';
  }

  if (!values.comment) {
    errors.comment = 'Required';
  }
  return errors;
};
