import { connect } from 'react-redux';
import Pure from './pure';
import Handlers from './handlers';

const mapStateToProps = state => {
  return {
    userVessels: state.userVessels,
  };
};

const VesselsTab = Handlers(Pure);
export default connect(
  mapStateToProps
)(VesselsTab);
