/* 
    From react-sortable-hoc: https://github.com/clauderic/react-sortable-hoc/blob/master/src/utils.js
*/
export const arrayMove = (arr, previousIndex, newIndex) => {
    const array = arr.slice(0);
    if (newIndex >= array.length) {
      let k = newIndex - array.length;
      while (k-- + 1) {
        array.push(undefined);
      }
    }
    array.splice(newIndex, 0, array.splice(previousIndex, 1)[0]);
    return array;
}