import styled from 'styled-components';
import Select from 'react-select';

export const StyledSelect = styled(Select)`
    && {
        min-width: 250px;
    }
`

export const Wrapper = styled.div`
    display: flex;
`

export const IconWrapper = styled.div`
    display: flex;
`