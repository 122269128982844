import React from 'react';
import {
    compose,
    lifecycle,
    withState,
    branch,
    withHandlers,
    renderNothing
} from 'recompose';
import withLeaflet from './../../../../common/Leaflet/withLeaflet';
import ReactDOMServer from 'react-dom/server';
import ReactDOM from 'react-dom';
import { render } from 'react-dom'
import L, { divIcon, DomEvent } from 'leaflet';
import UUID from 'uuid-js';

import { AnchorDiv } from './styledComponents'

const options = {
    iconSize: [0, 0],
    popupAnchor:  [0, -20],
};

const getHtml = (id) => ReactDOMServer.renderToString(<AnchorDiv id={id} />);
  
const getDivIcon = (divIcon, id) => divIcon(Object.assign({}, options, {html: getHtml(id)}))


const LeafletMarkerHandlers = compose(
    withLeaflet,
    // connect(mapStateToProps, null),
    withState('marker', 'setMarkerReference', { m: null, map: null, component: null }),
    withHandlers({
        handleOnClick: props => (e) => {
            const { onClick } = props;
            DomEvent.stopPropagation(e);
            // console.log(e)
            onClick({ latlng: e.latlng});
        },
        handleOnDragEnd: props => (e) => {
            const { onDragEnd } = props;
            DomEvent.stopPropagation(e);
            onDragEnd({ distance: e.distance, latlng: e.target._latlng });
        },
        handleMouseOver: props => (e) => {
            const { onMouseOver } = props;
            DomEvent.stopPropagation(e);
            // console.log(e)
            onMouseOver({ latlng: e.latlng});
        },
        handleMouseOut: props => (e) => {
            const { onMouseOut } = props;
            DomEvent.stopPropagation(e);
            // console.log(e)
            onMouseOut({ latlng: e.latlng});
        },
    }),
    withHandlers({ 
        handleMarkerCreation: props => () => {
            const {  
                onClick,
                onDragEnd, 
                handleOnClick,
                handleOnDragEnd,
                latlng, 
                draggable, 
                leafletMap,
                setMarkerReference,
                markerComponent,
                onMouseOut,
                handleMouseOut,
                onMouseOver, 
                handleMouseOver
            } = props;
            //console.log('handling marker creation', isWindyMap)
            
            const id = `marker-anchor-${UUID.create().toString()}`;

            const options = {
                draggable: draggable,
                icon: getDivIcon(divIcon, id)
            };
    
            const marker = new L.Marker(latlng, options);
            marker.addTo(leafletMap);
            const element = document.getElementById(id);
            setMarkerReference({ 
                m: marker, 
                map: leafletMap, 
                component: markerComponent,
                element
            });
            
            //register listeners on marker if provided
            onClick && marker.on('click', handleOnClick);
            onDragEnd && marker.on('dragend', handleOnDragEnd);
            onMouseOver && marker.on('mouseover', handleMouseOver);
            onMouseOut && marker.on('mouseout', handleMouseOut);
            
            element && render(markerComponent, element);
        },
        handleMarkerDestruction: props => () => {
            const { marker: { map, m, element } } = props;
            // console.log('destruction', element)
            map && m && map.removeLayer(m);
            element && ReactDOM.unmountComponentAtNode(element);
        }
    }),
    lifecycle({
        componentDidMount(){
            const { 
                leafletMap, 
                handleMarkerCreation
            } = this.props;

            if(leafletMap){
                handleMarkerCreation();
            }
            
        },
        componentWillUnmount(){
            this.props.handleMarkerDestruction();
        },
        componentDidUpdate(){
            //console.log('did update')
            const { 
                marker: { 
                    map: prevMap, 
                    m,
                    component
                }, 
                latlng, 
                leafletMap, 
                handleMarkerCreation,
                handleMarkerDestruction,
                markerComponent
            } = this.props;

            if(m){
                if(leafletMap !== prevMap || markerComponent !== component){
                    handleMarkerDestruction();
                    leafletMap && handleMarkerCreation();
                }
                else{
                    m.update(latlng);
                }
            }
            else{
                leafletMap && handleMarkerCreation();
            }
        }
    }),
    branch(() => true, renderNothing)
);

export default LeafletMarkerHandlers;
  