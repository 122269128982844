import * as actions from './../actions';
import { update } from './helper';

import { fromLocalStorage, toLocalStorage } from './../../helper';

const refreshDev = () => {
  const default_from_cache = fromLocalStorage('fromCache');
  return {
    fromCache: default_from_cache,
    apiEndpoint: (default_from_cache && fromLocalStorage('apiEndpoint')) || process.env.REACT_APP_API_ENDPOINT,
    accountEndpoint: (default_from_cache && fromLocalStorage('accountEndpoint')) || process.env.REACT_APP_ACCOUNT_ENDPOINT,
  }
}

export default function devReducer(devState = refreshDev(), action) {
  switch (action.type) {
    case actions.resetDevSettings.type:
      return update(devState, refreshDev());

    case actions.setApiEndpoint.type:
      toLocalStorage('apiEndpoint', action.payload);
      return update(devState, refreshDev());

    case actions.setAccountEndpoint.type:
      toLocalStorage('accountEndpoint', action.payload);
      return update(devState, refreshDev());

    case actions.toggleDevFromCache.type:
      const fromCache = !devState.fromCache;
      toLocalStorage('fromCache', fromCache);
      return update(devState, refreshDev());

    default:
      return devState;
  }
}
