import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    StyledFab
} from './../styledComponents';

const DeletePure = ({
    active,
    activateDeleteMode
}) => {
    return (
        <StyledFab 
            size="small"
            color={active ? "primary" : "default"} 
            aria-label="Delete" 
            onClick={activateDeleteMode}
        >
            <DeleteIcon />
        </StyledFab>
    )
}

export default DeletePure;