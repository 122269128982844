import {
    compose,
    withHandlers
} from 'recompose';
import { connect } from 'react-redux';
import {
    setActiveBaselayer,
    toggleMapSettingsZoomControl,
    toggleMapSettingsEcaAreas,
    toggleMapSettingsHighRiskAreas,
    toggleMapSettingsDrawCustomArea,
    toggleRequireDoubleConfirmation,
    toggleMapSettingsJwcAreas
  } from '../../../store/redux/actions';

const mapStateToProps = state => {
    return {
      activeBaselayer: state.map.activeBaseLayer,
      layers: state.map.layers,
      showZoomControl: state.map.showZoomControl,
      showEcaAreas: state.map.showEcaAreas,
      showHighRiskAreas: state.map.showHighRiskAreas,
      showJwcAreas: state.map.showJwcAreas,
      showCustomAreaControl: state.map.showCustomAreaControl,
      requireDoubleConfirm: state.map.requireDoubleConfirm,
      attribution: state.map.activeBaseLayer.attr
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      setActiveBaselayer: layer => dispatch(setActiveBaselayer.call(layer)),
      toggleMapSettingsZoomControl: () =>
        dispatch(toggleMapSettingsZoomControl.call()),
      toggleMapSettingsEcaAreas: () => dispatch(toggleMapSettingsEcaAreas.call()),
      toggleMapSettingsHighRiskAreas: () =>
        dispatch(toggleMapSettingsHighRiskAreas.call()),
      toggleMapSettingsDrawCustomArea: () => dispatch(toggleMapSettingsDrawCustomArea.call()),
      toggleRequireDoubleConfirmation: () => dispatch(toggleRequireDoubleConfirmation.call()),
      toggleMapSettingsJwcAreas: () => dispatch(toggleMapSettingsJwcAreas.call())
    };
  };

const Handlers = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        handleChange: ({
            layers, 
            setActiveBaselayer
        }) => event => {
            const selected = layers.filter(
              layer => layer.uuid === event.target.value
            )[0];
            setActiveBaselayer(selected);
        },
    })
);

export default Handlers;