import { put, select, takeLatest } from 'redux-saga/effects';
import {
  updateBunkerPrices,
  updateBunkerPorts
} from './../redux/actions';


const isEven = v => v % 2 === 0;

const median = (sortedArray) => {
    return isEven(sortedArray.length)
        ? (sortedArray[(sortedArray.length / 2) - 1] + sortedArray[sortedArray.length / 2]) / 2
        : sortedArray[Math.ceil(sortedArray.length / 2) - 1];
}

const quartiles = (sortedArray) => {
    if(sortedArray.length === 1){
        return {
            firstQuartile: sortedArray[0],
            secondQuartile: sortedArray[0],
            thirdQuartile: sortedArray[0] 
        }
    }
    const secondQuartile = median(sortedArray);
    return {
        firstQuartile: median(sortedArray.filter(v => v <= secondQuartile)),
        secondQuartile: secondQuartile,
        thirdQuartile: median(sortedArray.filter(v => v >= secondQuartile)),
    }
}

function* getBunkerPorts(){
    const {
        rowData,
    } = yield select(state => ({
        rowData: state.bunker.rowData,
    }));

    const ports = rowData
        .map(({lat, lng, portId, port, region, timeZoneOffset}) => ({lat, lng, portId, port, region, timeZoneOffset}))
        .filter(({portId: pId}, index, self) => self.findIndex(({portId}) => portId === pId) === index);

    const grades = rowData
        .map(({ grade }) => grade)
        .filter((v, i, a) => a.indexOf(v) === i); // make unique (only first occurence remains)

    const statsByGrade = grades
        .map(g => ({
            values: rowData.filter(({ grade }) => grade === g).sort((a,b) => a.price - b.price),
            grade: g
        }))
        .filter(({ values }) => values.length > 0)
        .map(({ grade, values }) => ({
            grade: grade,
            min: values[0].price,
            max: values[values.length - 1].price,
            avg: values.map(({ price }) => price).reduce((acc, val, i) => acc + val, 0) / values.length,
            ...(quartiles(values.map(({ price }) => price)))
        }))

    const pricesByPort = ports.map(port => ({
        ...port,
        prices: rowData
            .filter(({ portId }) => portId === port.portId)
            .reduce((acc, val) => ({ ...acc, [`${val.grade}`]: val.price}) ,{})
    }));

    // console.log(pricesByPort, statsByGrade)

    yield put({
        type: updateBunkerPorts.type,
        payload: {
            allPricesByPort: pricesByPort,
            allStatsByGrade: statsByGrade,
        }
    })
}

export function* bunkerPortsSaga() {
    yield takeLatest(updateBunkerPrices.type, getBunkerPorts);
}