// Vessels related requests to firestore for a user

export const getUserVessel = (userDoc, imo) => {
  return userDoc
    .collection('vessels')
    .doc(imo)
    .get()
    .then(doc => doc.exists ? doc.data() : null)
    .catch(function(error) {
      console.log('Error getting document:', error);
    });
};

export const fetchVessel = (imo, db) => {
  return db
    .collection('vessels')
    .doc(`IMO${imo}`)
    .get()
    .then(doc => doc.exists ? doc.data() : null)
    .catch(function(error) {
      if (process.env.REACT_APP_SHOW_DEBUG === 'true')
        console.log('Error getting document:', error);
    });
};

export const addVessel = (userDoc, vessel, metadata) => {
  return userDoc
    .collection('vessels')
    .doc(`IMO${vessel.general.imo}`)
    .set(vessel)
    .then(() => {
      return updateMetaData(userDoc, 'vessels', metadata);
    })
    .catch(error => {
      if (process.env.REACT_APP_SHOW_DEBUG === 'true')
        console.log('Error getting document:', error);
      throw error;
    });
};

export const addItinerary = (userDoc, itinerary) => {
  try {
    const data = Object.assign({
      data: itinerary,
      version: 1
    });
    const id = itinerary.uuid;
    // console.log(data, id)
    userDoc
      .collection(`itineraries`)
      .doc(id)
      .set(data)

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
} 

export const deleteItinerary = (userDoc, uuid) => {
  return userDoc
    .collection('itineraries')
    .doc(uuid)
    .delete()
    .catch(error => {
        console.error("Error removing document: ", error);
    });
}

export const addLocations = (userDoc, locations) => {
  return userDoc
    .collection('locations')
    .doc('metadata')
    .update({ locations })
    .catch(error => {
      if (process.env.REACT_APP_SHOW_DEBUG === 'true')
        console.log('Error while updating document:', error);
    });
}

export const updateMetaData = (userDoc, type, metadata) => {
  return userDoc
    .collection(`${type}`)
    .doc('metadata')
    .update(metadata)
    .then(() => {
      return setTimeout(() => null, 30000);
      // console.log('update metadata');
      // return;
    })
    .catch(error => {
      if (process.env.REACT_APP_SHOW_DEBUG === 'true')
        console.log('Error while updating document:', error);
      throw error;
    });
};
