import {
    compose,
    withProps
} from 'recompose';

const Handlers = compose(
    withProps(() => ({
        columnDefinitions: [
            { headerName: "Name", field: "name", sortable: true, filter: true, resizable: true }, 
            { headerName: "Latitude", field: "lat", width: 100 }, 
            { headerName: "Longitude", field: "lng", width: 100 },
          ]
    }))
);

export default Handlers;