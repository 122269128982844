import React from 'react';
import { toggleActive } from './../actions';
import { update } from './helper';
import Faq from './../../../applets/Faq';
import Snackbar from './../../../applets/Snackbar';
import LeafletMap from './../../../applets/LeafletMap';
import Matrix from './../../../applets/Matrix';

import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import LocationSearching from '@material-ui/icons/LocationSearching';
import MapIcon from '@material-ui/icons/Map';
import ContactSupport from '@material-ui/icons/ContactSupport';
import WbSunny from '@material-ui/icons/WbSunny';
import FolderShared from '@material-ui/icons/FolderShared';
import Opacity from '@material-ui/icons/Opacity';
import FileCopy from '@material-ui/icons/FileCopy';
// import VoyageIcon from '@material-ui/icons/Grain';

// const decodeJwtPayload = (jwt) => JSON.parse(window.atob(jwt.split('.')[1]))

// const hasPrivilege = (token, key) => {
//   const tokenObj = decodeJwtPayload(token);
//   // console.log(token, key, tokenObj);
//   return tokenObj[key];
// }

const initialApplets = {
  applets: [
    {
      urlPath: '/app/route/:mode?',
      url: '/app/route',
      mountPath: 'Route',
      icon: <FormatListNumbered />,
      title: 'Route',
      card: {
        title: 'Route',
        price: {
          hasFreeTier: true,
          hasPremiumFeatures: true,
        },
        supplier: 'aquaplot.com',
        description: {
          short: 'Distance calculation between ports and other locations.',
          extended: '',
        },
      },
      hasMap: true,
      defaultToolbar: true,
      isActive: () => true,
      singlePage: (params) => {
        return !params || !params.mode || params.mode !== "bunker-search";
      },
    },
    {
      urlPath: '/app/tracking',
      url: '/app/tracking',
      mountPath: 'AisTracking',
      icon: <LocationSearching />,
      title: 'AIS',
      card: {
        title: 'AIS Tracking',
        price: {
          hasFreeTier: false,
          hasPremiumFeatures: true,
        },
        supplier: 'aquaplot.com',
        description: {
          short: 'Track ships using terrestrial and satellite AIS data.',
          extended: '',
        },
      },
      hasMap: true,
      defaultToolbar: false,
      isActive: () => true,
    },
    {
      urlPath: '/app/map',
      url: '/app/map',
      component: LeafletMap,
      mountPath: 'LeafletMap',
      icon: <MapIcon />,
      title: 'Map',
      card: {
        title: 'Map',
        price: {
          hasFreeTier: false,
          hasPremiumFeatures: true,
        },
        supplier: 'aquaplot.com',
        description: {
          short: "View everything that's going on",
          extended: '',
        },
      },
      hasMap: false,
      defaultToolbar: false,
      mobileOnly: true,
      isActive: ({ mobile }) => mobile,
      renderAlways: true,
    },
    {
      urlPath: '/app/faq',
      component: Faq,
      url: '/app/faq',
      mountPath: 'Faq',
      icon: <ContactSupport />,
      title: 'FAQ',
      card: null,
      hasMap: false,
      defaultToolbar: false,
      mobileOnly: false,
      isActive: () => false,
      renderAnyways: true,
      renderAlways: false,
    },
    {
      urlPath: '/app/distance-matrix',
      component: Matrix,
      url: '/app/distance-matrix',
      mountPath: 'Matrix',
      icon: <FileCopy />,
      title: 'Distance Matrix',
      card: null,
      hasMap: false,
      defaultToolbar: false,
      mobileOnly: false,
      isActive: () => false,
      hideInTopBar: true
    },
    {
      urlPath: '/app',
      url: '/app',
      component: Snackbar,
      mountPath: 'Snackbar',
      icon: <ContactSupport />,
      title: 'Snackbar',
      card: null,
      hasMap: false,
      defaultToolbar: false,
      mobileOnly: false,
      isActive: () => false,
      renderAnyways: true,
      renderAlways: true,
    },
    {
      urlPath: '/app/weather',
      url: '/app/weather',
      mountPath: 'WindyApp',
      icon: <WbSunny />,
      title: 'Weather',
      card: {
        title: 'Weather',
        price: {
          hasFreeTier: true,
          hasPremiumFeatures: false,
        },
        supplier: 'aquaplot.com & windy.com',
        description: {
          short: 'View current weather conditions.',
          extended: '',
        },
      },
      hasMap: false,
      defaultToolbar: false,
      isActive: () => true,
    },
    {
      urlPath: '/app/data/:tab?',
      url: '/app/data',
      mountPath: 'DataApplet',
      icon: <FolderShared />,
      title: 'My Data',
      isBeta: true,
      card: {
        title: 'Your Data',
        price: {
          hasFreeTier: false,
          hasPremiumFeatures: true,
        },
        supplier: 'aquaplot.com',
        description: {
          short: 'Find, Share and edit vessel data.',
          extended: '',
        },
      },
      hasMap: false,
      defaultToolbar: false,
      isActive: () => true,
      globalRoutes: [
        {
          path: 'addVessel',
          component: 'testing',
        },
      ],
    },
    {
      urlPath: '/app/bunker',
      url: '/app/bunker',
      mountPath: 'Bunker',
      icon: <Opacity />,
      title: 'Bunker',
      isBeta: false,
      isNew: true,
      card: {
        title: 'Bunker Prices',
        price: {
          hasFreeTier: true,
          hasPremiumFeatures: true,
        },
        supplier: 'bunker-ex.com',
        description: {
          short: 'Show prices for 400+ ports worldwide. Updated thousands of times a day.',
          extended: '',
        },
      },
      hasMap: false,
      defaultToolbar: false,
      isActive: ({ aqpToken }) => aqpToken,
    },
    // {
    //   urlPath: '/app/voyage/:tab?',
    //   url: '/app/voyage',
    //   mountPath: 'Voyage',
    //   icon: <VoyageIcon />,
    //   title: 'Voyage',
    //   isBeta: true,
    //   card: {
    //     title: 'Voyage Calculation',
    //     price: {
    //       hasFreeTier: false,
    //       hasPremiumFeatures: true,
    //     },
    //     supplier: 'aquaplot.com',
    //     description: {
    //       short: 'Calculate the voyage estimate.',
    //       extended: '',
    //     },
    //   },
    //   hasMap: false,
    //   defaultToolbar: false,
    //   isActive: () => true,
    // },
  ],
};

function appletsReducer(appletsState = initialApplets, action) {
  switch (action.type) {
    case toggleActive.type:
      const urlPath = action.payload;
      return update(appletsState, {
        applets: appletsState.applets.map(applet =>
          applet.urlPath === urlPath
            ? Object.assign({}, applet, { active: !applet.active })
            : applet
        ),
      });

    default:
      return appletsState;
  }
}

export default appletsReducer;
