import React from 'react';

import {
    CategoryTab,
    Title,
    SubTitle
} from './../../styledComponents';
import {
    SelectContainer,
    StyledLink,
} from './styledComponents';
import Grid from '@material-ui/core/Grid';

const Pure = ({
    items,
    pendingItems,
    buildLink,
    getTitle, 
    getSubTitle
}) => {
    return (
        <SelectContainer>
            {items.map((linkItem, index) => (
              <StyledLink 
                to={buildLink(linkItem)}
                key={`link-${index}`}
              >
                <CategoryTab
                  graybg="true"
                  container
                  alignItems="center"
                  item
                  xs={12}
                >
                  <Grid item xs={12}>
                    <Title color="primary">{getTitle(linkItem)}</Title>
                  </Grid>
                  <Grid item xs={12}>
                    <SubTitle>{getSubTitle(linkItem)}</SubTitle>
                  </Grid>
                </CategoryTab>
              </StyledLink>
            ))}
            {
              pendingItems.map((item, index) => (
                <StyledLink 
                  to={buildLink(item)}
                  key={`link-${index}`}
                >
                  <CategoryTab
                    graybg="true"
                    container
                    alignItems="center"
                    item
                    xs={12}
                  >
                    <Grid item xs={12}>
                      <Title color="primary">{getTitle(item)}</Title>
                    </Grid>
                    <Grid item xs={12}>
                      <SubTitle>Please wait...</SubTitle>
                    </Grid>
                  </CategoryTab>
                </StyledLink>
              ))
            }
        </SelectContainer>
    )
}

export default Pure;