import React, { PureComponent } from 'react';
import ValueField from './ValueField';

import { normalizeLongitude } from './../../store/sagas/aqp-api-helper.js';

export default class LongitudeField extends PureComponent {
  render() {
    const { lng } = this.props;

    const lng_string = normalizeLongitude(lng)
      .toString()
      .substring(0, lng.toString().indexOf('.') + 4);

    return (
      <ValueField
        label="Longitude"
        value={lng_string}
        unit="(Decimal Degrees)"
      />
    );
  }
}
