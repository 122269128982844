import axios from 'axios';
import { functionWithApiEndpoints } from './../store/withApiEndpoints';

const axiosConfig = {
  mode: 'no-cors',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

export const fetchFaqs = functionWithApiEndpoints(({ accountEndpoint }) => {
    return axios.get(`${accountEndpoint}/faqs_json`, axiosConfig)
        .then((response) => response.data);
});