import {
    compose,
    withProps
} from 'recompose';
import {
    ActionButton,
    WarningButton,
  } from '../../../styledComponents';

const Handlers = compose(
withProps(({
    variant,
    component,
    wrapper
}) => ({
    ButtonVariant: variant === 'warning' ? WarningButton : ActionButton,
    ButtonComponent: component || 'button',
    Wrapper: wrapper || 'div'
}))
);

export default Handlers;