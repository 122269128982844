import * as credentialsSagas from './credentials';
import * as locationsSagas from './locations';
import * as validationSagas from './validate';
import * as routeSagas from './route';
import * as ecaSagas from './eca';
import * as hraSagas from './hra';
import * as jwcSagas from './jwc';
import * as healthSagas from './health';
import * as aisSagas from './ais';
import * as countriesSagas from './countries';
import * as authSagas from './auth';
import * as firebaseSagas from './firebase';
import * as userSagas from './user';
import * as channelSagas from './channels';
import * as bufferSagas from './bufferAroundRoute';
import * as deviationSagas from './deviations';
import * as bunkerSagas from './bunkerPorts';
const unpack = sagas => Object.values(sagas);
const flatten = (a, b) => a.concat(b);

const all = [
  credentialsSagas,
  locationsSagas,
  validationSagas,
  routeSagas,
  ecaSagas,
  hraSagas,
  jwcSagas,
  countriesSagas,
  healthSagas,
  aisSagas,
  authSagas,
  firebaseSagas,
  userSagas,
  channelSagas,
  bufferSagas,
  deviationSagas,
  bunkerSagas
]
  .map(unpack)
  .reduce(flatten);

export default all;
