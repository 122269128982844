import {
    compose,
} from 'recompose';

import { connect } from 'react-redux';

const mapStateToProps = state => {
    return {
        leafletObj: state.map.leafletObj
    }
}

const Handlers = compose(
    connect(mapStateToProps, null),
);

export default Handlers;