import React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { StlyedFormControl } from './style';

export const RenderSelectField = ({
  input,
  label,
  disabled,
  meta: { error, touched },
  children,
  required,
}) => (
  <StlyedFormControl
    error={error && touched ? true : false}
    disabled={disabled ? true : false}
  >
    <InputLabel required={required} htmlFor={input.name}>
      {label}
    </InputLabel>
    <Select autoWidth id={input.name} {...input} onChange={input.onChange}>
      {children}
    </Select>
    {error &&
      touched && (
        <FormHelperText error={error ? true : false}>{error}</FormHelperText>
      )}
  </StlyedFormControl>
);
