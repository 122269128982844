import styled from 'styled-components';

export const StateTag = styled.div`
    z-index: 800;
    background-color: #043153;
    position: absolute;
    left: 50%;
    transform: ${({active}) => active === 'true' ? `translate(-50%, -0%)` : `translate(-50%, -1000%)`};
    padding: 4px 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: white;
    transition: 300ms ease-in-out all;
    font-weight: 300;
    font-size: small;
`