import React from 'react';
import Dialog from './Dialog';

const Pure = ({
    dialogIsOpen,
    setDialogIsOpen,
    latlngs
}) => {
    return (
        <Dialog
            isOpen={dialogIsOpen}
            setIsOpen={setDialogIsOpen}
            latlngs={latlngs}
        />
    )
}

export default Pure;