import React from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';

const Circle = styled.div`
    height: ${props => props.highlighted === 'true' ? '12px' : '7px'};
    width: ${props => props.highlighted === 'true' ? '12px' : '7px'};
    border-radius: 10px;
    background-color: ${props => props.backgroundColor || 'red'};
    transform: translate(-50%, -50%);
`

const CirclePure = ({
    tooltip,
    enterDelay,
    highlighted,
    forceTooltipOpen,
    backgroundColor
}) => {
    return (
        <Tooltip 
            title={tooltip}
            enterDelay={enterDelay !== undefined ? enterDelay : 0}
            open={forceTooltipOpen}
        >
            <Circle 
                highlighted={highlighted ? 'true' : 'false'} 
                backgroundColor={backgroundColor}
            />
        </Tooltip>
    )
}

export default CirclePure;