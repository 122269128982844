import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import Select from '@material-ui/core/Select';

export const StyledSelect = styled(({ ...other }) => (
  <Select
    {...other}
    classes={{
      select: 'disabled-select',
    }}
  />
))`
  && .disabled-select {
    padding-right: ${props => (props.disabled ? 0 : '')};
  }
`;

export const StyledPlaceHolder = styled(Typography)`
  position: absolute;
  left: 2;
  font-size: 16;
`;

export const StlyedFormControl = styled(FormControl)`
  && {
    min-width: 80%;
    width: 100%;
  }
`;
