import React, { PureComponent } from 'react';
import ValueSection from './ValueSection';
import LongitudeField from './LongitudeField';
import LatitudeField from './LatitudeField';

export default class CoordinatesSection extends PureComponent {
  render() {
    const { latlng } = this.props;
    const { lat, lng } = latlng;

    return (
      <ValueSection header="Coordinates">
        <LatitudeField lat={lat} />
        <LongitudeField lng={lng} />
      </ValueSection>
    );
  }
}
