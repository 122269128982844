import React, { Component, lazy, Suspense } from 'react';
import { render } from 'react-dom';
import AqpProvider from './store/index';
import { MuiThemeProvider as CoreThemeProvider } from '@material-ui/core/styles';
import { coreTheme, scTheme } from './aquaplot/theme';
import LoadingScreen from './app/LoadingScreen';
import registerServiceWorker from './registerServiceWorker.js';
import { aquaplotStore } from './store/index';
import { updateSnackbarMessage } from './store/redux/actions'; 
import { ThemeProvider } from 'styled-components';
const ExplorerApp = lazy(() => import('./app'));
// import 'typeface-roboto'

class App extends Component {

  render() {
    return (
      <AqpProvider>
        <CoreThemeProvider theme={coreTheme}>
          <ThemeProvider theme={scTheme}>
            <Suspense fallback={<LoadingScreen />}>
                <ExplorerApp />
            </Suspense>
          </ThemeProvider>
        </CoreThemeProvider>
      </AqpProvider>
    );
  }
}

render(<App />, document.getElementById('root'));

if (process.env.REACT_APP_SERVICE_WORKER === 'true') {
  if (process.env.REACT_APP_SHOW_DEBUG === 'true') {
    console.log('registering service worker');
  }
  registerServiceWorker({
    onUpdate: (installingWorker) => {
      setTimeout(() => {
        aquaplotStore.dispatch(updateSnackbarMessage.call('Update available.', {
          with_page_refresh_to_close: true,
          installingWorker: installingWorker
        }));
      }, 5000);
      
      console.log('on update service worker called')
    }
  });
}
