import React from 'react';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import {
  StyledExpansionPanel,
  StyledPanelDetails,
} from './styledComponents';
import ReactMarkdown from 'react-markdown';

const SingleFaq = ({ faq, expanded, setExpanded }) => {
  return (
    <div>
      <StyledExpansionPanel
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
          <div>
            <Typography variant="body2">{faq.question}</Typography>
          </div>
        </ExpansionPanelSummary>
        <StyledPanelDetails>
          <ReactMarkdown source={faq.answer} />
        </StyledPanelDetails>
      </StyledExpansionPanel>
      <Divider />
    </div>
  );
};

export default SingleFaq;
