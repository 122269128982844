import {
    compose,
} from 'recompose';

import { connect } from 'react-redux';

const mapStateToProps = state => {
    return {
        endpoints: {
            accountEndpoint: state.dev.accountEndpoint,
            apiEndpoint: state.dev.apiEndpoint,
            aqpToken: state.user.aqpToken,
        }   
    }
}

const Handlers = compose(
    connect(mapStateToProps, null),
);

export default Handlers;