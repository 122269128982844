import React, { PureComponent } from 'react';
import Typography from '@material-ui/core/Typography';

const style = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  section: {
    position: 'relative',
    marginBottom: 10,
  },
  header: {
    paddingLeft: 0,
    lineHeight: '24px',
    fontWeight: 400,
    marginBottom: 5,
    paddingBottom: 12,
  },
  overlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255, 0.7)',
    zIndex: 100,
    borderRadius: 10,
  },
};

export default class ValueSection extends PureComponent {
  render() {
    const { header, children, overlay, renderOverlay } = this.props;

    return (
      <div style={style.section}>
        <Typography variant={'subtitle1'} style={style.header}>
          {header}
        </Typography>
        <div style={style.container}>
          {overlay && <div style={style.overlay}>{renderOverlay}</div>}
          {children}
        </div>
      </div>
    );
  }
}
