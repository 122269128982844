import React, { useState } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import {
  StyledDownloadIcon,
  StyledCancelIcon,
  OuterWrapper,
  ContentGrid,
  StyledAddButton
} from './styledComponents';
import CreateMatrixDialog from './CreateMatrixDialog';
import MatrixObject from './MatrixObject';
import MatrixNodesImport from './MatrixNodesImport';
import CardsWrapper from './common/CardsWrapper';
import Toolbar from './common/Toolbar';
import TabSelect from './common/TabSelect';
import NoContent from './common/NoContent';
import BottomNavigation from './common/BottomNavigation';
import { Colors } from './../../aquaplot/colors';
import UrlTriggeredDrawer from './../../common/UrlTriggeredDrawer';


const ACTIONS = (matrix) => ({
  view: [
    {
      label: 'Export',
      tooltip: 'Export Distance Matrix',
      icon: <StyledDownloadIcon />,
      component: Link,
      to: 'export',
      disabled: matrix === undefined || matrix.exportAvailable !== true
    },
    {
      label: 'Close',
      tooltip: 'Close Distance Matrix',
      icon: <StyledCancelIcon />,
      component: Link,
      to: '/app/distance-matrix'
    }
  ],
  export: [
    {
      label: 'Cancel',
      component: Link,
      to: 'view'
    },
    {
      onClick: () => console.log('download'),
      label: 'Download CSV',
      component: Link,
      to: 'view'
    }
  ]
});

const MODES = {
  view: {
    label: null
  },
  download: {
    label: 'Export',
    color: Colors.aqp_warning
  },
};

const MatrixPure = ({
  match: { path },
  matrixLink,
  matrices,
  createNewMatrix,
  pendingMatrices
}) => {
  const [showAddDialog, setShowAddDialog] = useState(false);

  return (
    <OuterWrapper>
      <Route 
        exact
        path={`${path}/:matrixId?/:mode(view|export)?`}
        render={({history, match: { params: { matrixId, mode } }}) => {
          const matrix = matrices.find(m => m.id === matrixId);
          return (
            <Toolbar 
              title={'Matrices'}
              titleAction={
                  <StyledAddButton onClick={() => setShowAddDialog(true)} />
              }
              actions={ACTIONS(matrix)}
              modes={MODES}
              mode={mode}
              mobileProps={{
                selectOptions: matrices,
                buildLink: (m) => matrixLink(m.id),
                onClear: () => history.push(matrixLink()),
                noSubtitle: false,
                title: (m) => m.name,
                subtitle: m => `${m.nodes.length} waypoints`
              }}
              active={matrix !== undefined ? matrix : null}
            />
          );
        }}
      />
      <ContentGrid container>
        <Hidden smDown>
          <TabSelect 
            title={(matrix) => matrix.name}
            subtitle={matrix => `${matrix.nodes.length} waypoints`}
            items={matrices}
            pendingItems={pendingMatrices}
            buildLink={(matrix) => matrixLink(matrix.id)}
          />
        </Hidden>
        <CardsWrapper>
          <Switch>
            <Redirect 
              exact
              path={`${path}/:matrixId/`}
              to={`${path}/:matrixId/view`}
            />
            <Route 
              exact
              path={`${path}/:matrixId/:mode(view|export)`}
              render={({match: { params: { matrixId, mode } }}) => {
                const matrix = matrices.find(m => m.id === matrixId);
                if(matrix === undefined){
                  // might be pending 
                  const pending = pendingMatrices.find(m => m.id === matrixId);
                  if(pending === undefined){
                    return <Redirect to={`${path}`}/>;
                  }
                  return <MatrixObject matrix={pending}/>;
                }
                return (
                  <React.Fragment>
                    <MatrixObject matrix={matrix} />
                    <UrlTriggeredDrawer
                      width={'1200px'}
                      urlTrigger={'addNodes'}
                      renderContent={() => {
                          return (
                            <MatrixNodesImport />
                          );
                      }}
                      heading={'Add Waypoints'}
                    />
                  </React.Fragment>
                );
              }}
            />
            <Route 
              render={() => <NoContent msg='Select or create a distance matrix.' />}
            />
          </Switch>
        </CardsWrapper>
      </ContentGrid>
      <Hidden mdUp>
        <Route 
          exact
          path={`${path}/:matrixId/:mode(view|export)`}
          render={({match: { params: { matrixId, mode } }}) => {
            return <BottomNavigation activeActions={ACTIONS()[mode]}/>;
          }}
        />
      </Hidden>
      <CreateMatrixDialog 
        open={showAddDialog}
        cancel={() => setShowAddDialog(false)}
        createNewMatrix={createNewMatrix}
      />
    </OuterWrapper>
  );
};

export default MatrixPure;
