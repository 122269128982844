import React from 'react';
import { Link } from 'react-router-dom';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const ResponsiveDialogPure = props => {
  const {
    isOpen,
    setIsOpen,
    handleAgree,
    handleClose,
    actionText,
    title,
    contentText,
    fullScreen,
    renderContent,
    formId,
    buttonVariant,
    cancel,
    link,
    linkText,
    actionTextDisabled
  } = props;
  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={() =>
        handleClose ? handleClose() : setIsOpen ? setIsOpen(false) : cancel()
      }
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
    >
      <DialogTitle disableTypography={true}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography color="primary" variant="h6">
              {title}
            </Typography>
          </Grid>
          {!cancel && (
            <Grid item>
              <IconButton
                onClick={() => (handleClose ? handleClose() : setIsOpen(false))}
              >
                <Close />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
        {renderContent ? renderContent() : null}
      </DialogContent>
      <DialogActions>
        {cancel && (
          <Button
            type="submit"
            form={formId}
            variant={'text'}
            color="primary"
            onClick={cancel}
          >
            Cancel
          </Button>
        )}
        {link && (
          <Button
            component={Link}
            to={link}
            variant={buttonVariant ? buttonVariant : 'contained'}
            color="primary"
            autoFocus
            onClick={handleAgree}
          >
            {linkText}
          </Button>
        )}
        {actionText && (
          <Button
            type="submit"
            form={formId}
            variant={buttonVariant ? buttonVariant : 'contained'}
            color="primary"
            onClick={handleAgree}
            autoFocus
            disabled={actionTextDisabled}
          >
            {actionText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default withMobileDialog()(ResponsiveDialogPure);
