import React from 'react';
import Grid from '@material-ui/core/Grid';
import ToolbarButton from './../Button';
import Tag from './../../../../../common/TopTag';

import {
    BorderGrid,
    ToolbarTitle
} from './../styledComponents';

import {
    StyledToolbarGrid
} from './styledComponents';

const Pure = ({
    title,
    activeActions,
    titleAction,
    showModeTag,
    modeTag,
    modeColor
}) => {
    return (
        <StyledToolbarGrid container>
            <BorderGrid
                graybg="true"
                justify="space-between"
                alignItems="center"
                container
                item
                md={3}
                xs={12}
            >
                <Grid item>
                    <ToolbarTitle>{ title }</ToolbarTitle>
                </Grid>
                <Grid item>
                    { titleAction }
                </Grid>
            </BorderGrid>
            <BorderGrid
                alignItems="center"
                container
                justify="flex-end"
                item
                xs={12}
                md={9}
            >
                {
                    showModeTag &&
                    <Tag backgroundColor={modeColor}>{modeTag}</Tag>
                }
                {
                    activeActions.map((action, idx) => (
                        <Grid item key={`action-${idx}`}>
                            <ToolbarButton
                                { ...action }
                                noLabel={false}
                            />
                        </Grid>
                    ))
                }
            </BorderGrid>
        </StyledToolbarGrid>
    )
}

export default Pure;