import { put, select, call, takeLatest } from 'redux-saga/effects';
import {
    Deviation,
} from './../redux/actions';
import { featureCollection } from '@turf/helpers';

async function fetchDeviation(db, collectionPath, docIds){
    const promises = docIds.map(
        id => db
            .collection(collectionPath)
            .doc(id)
            .get()
            .then(doc => ({
                ...doc.data(),
                docId: id
            }))
    );
    
    return await Promise.all(promises);
}

function* requestDeviation(action){
    const {
        collectionPath,
        docIds,
        portDenormalizedLng
    } = action.payload;
    // console.log('requestDeviation')
    const { db } = yield select(state => ({
        db: state.firestore.db
    }));

    const features = yield call(fetchDeviation, db, collectionPath, docIds);
    const parsed = features.map(f => ({
        ...f,
        geometry: JSON.parse(f.geometry)
    }));
    parsed.forEach(f => {
        var coords = f.geometry.coordinates;
        const match = f.docId.match(new RegExp(`(.*)-([AB])-DISTANCES-(\\d+)-(\\d+)`));
        const isLegToDeviationPort = match[2] === 'A';
        const referenceLng = isLegToDeviationPort 
            ? coords[coords.length - 1][0]
            : coords[0][0];
        const delta = portDenormalizedLng - referenceLng;
        // console.log(portDenormalizedLng, referenceLng, delta);
        if(delta !== 0){
            f.geometry.coordinates = coords.map(arr => [arr[0] + delta, arr[1]]);
        }
    })

    yield put({
        type: Deviation.SUCCEEDED,
        payload: {
            deviationFeatureCollection: featureCollection(parsed)
        }
    });
}

export function* deviationSaga() {
    yield takeLatest(Deviation.REQUESTED, requestDeviation);
}
