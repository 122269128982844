import React, { PureComponent } from 'react';
import ValueField from './ValueField';

export default class LocodeField extends PureComponent {
  render() {
    const { locode, onLocodeChange } = this.props;

    return (
      <ValueField 
        label="Locode" 
        value={locode || "unknown / NA"} 
        unit="" 
        onChange={onLocodeChange}
      />
    );
  }
}
