import styled from 'styled-components';
import { Colors } from './../../../../aquaplot/colors';

export const OuterWrapper = styled.div`
    z-index: 601;
    position: relative;
`
const innerSize = 14;

export const InnerWrapper = styled.div`
    pointer-events: none;
    position: absolute;
    text-align: center;
    bottom: 15px;
    color: ${({color}) => color || Colors.aqp_white};
    background-color: ${({backgroundColor}) => backgroundColor || Colors.aqp_deep_sea};
    width: ${() => innerSize}px;
    height: ${() => innerSize}px;
    left: ${() => -innerSize/2}px;
    z-index: 602;

    & > .styledIcon{
        width: ${() => innerSize}px;
        height: ${() => innerSize}px;
        color: ${({color}) => color || Colors.aqp_white};
    }
`