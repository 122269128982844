import {
  FETCH_USER_FLEETS_REQUESTED,
  FETCH_USER_FLEETS_SUCCEEDED,
  FETCH_USER_FLEETS_FAILED,
} from '../../types';

const initialFleets = {
  loading: true,
  fleets: {},
  listenerInstantiated: false,
  error: undefined,
};

export function userFleetsReducer(state = initialFleets, action) {
  switch (action.type) {
    case FETCH_USER_FLEETS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_FLEETS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: undefined,
        listenerInstantiated: true,
        fleets: action.payload,
      };
    case FETCH_USER_FLEETS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
