import {
    compose,
    branch,
    renderNothing
} from 'recompose';

const Handlers = compose(
    branch(({show}) => !show, renderNothing)
);

export default Handlers;