import {
    compose,
    branch,
    renderComponent
} from 'recompose';
import { connect } from 'react-redux';
import Desktop from './Desktop';
import Mobile from './Mobile';

const mapStateToProps = state => {
    return {
        mobile: state.ui.mobile
    }
}

const Handlers = compose(
    connect(mapStateToProps),
    branch(({mobile}) => mobile, renderComponent(Mobile)),
    branch(() => true, renderComponent(Desktop)),
);

export default Handlers;