//@flow
import { functionWithApiEndpoints } from './../withApiEndpoints';
import Version from './../../config/explorer-version.json';
import axios from 'axios';

const api_headers = aqpToken => {
  return {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Aqp-Token': aqpToken,
    'Aqp-Explorer-Version': Version.version
  };
};

const apiGetObject = aqpToken => {
  return {
    method: 'GET',
    headers: api_headers(aqpToken),
  };
};

const apiPostObject = aqpToken => {
  return {
    method: 'POST',
    headers: api_headers(aqpToken),
    body: '',
  };
};

export const normalizeLongitude = lng => {
  while (lng < -180.0) lng += 360.0;
  while (lng > 180.0) lng -= 360.0;
  return lng;
};

/*
  /credentials
*/
export const authViaCookieCall = functionWithApiEndpoints(({ accountEndpoint }) => {
  const url = `${accountEndpoint}/auth_with_cookie`;

  return axios
    .post(url, '', {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
    })
    .then(res => res.data)
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
  
    // return fetch(url, {
    //   method: 'POST',
    //   credentials: 'include',
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //   },
    //   body: '',
    // })
    //   .then(res => res.json())
    //   .catch(reason => {
    //     return { status: 'error', reason: reason };
    //   });
});

export const postCredentialsCall = functionWithApiEndpoints((params) => {
  const { usr, pwd, remember_me, accountEndpoint } = params;
  const url = `${accountEndpoint}/credentials`;

  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({ usr: usr, pwd: pwd, remember_me: remember_me }),
  })
    .then(res => res.json())
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
});

export const postCredentialsCallNoCors = functionWithApiEndpoints((params) => {
  const { usr, pwd, accountEndpoint } = params;
  const url = `${accountEndpoint}/credentials`;

  const payload = `usr=${encodeURIComponent(usr)}&pwd=${encodeURIComponent(
    pwd
  )}&remember_me=true`;
  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: payload,
  })
    .then(res => res.json())
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
});

export const deleteCredentialsCall = functionWithApiEndpoints(({
  accountEndpoint
}) => {
  const url = `${accountEndpoint}/credentials/signout`;

  return fetch(url, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: '',
  })
    .then(res => res.json())
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
});

/*
  /users
*/
export const postAccountCreateCall = functionWithApiEndpoints(({ email, accountEndpoint }) => {
  let url = `${accountEndpoint}/users`;

  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({ email: email, tos: true, remote: true }),
  })
    .then(res => res.json())
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
});

/*
  /account
*/
export const postAccountVerificationCall = functionWithApiEndpoints(({
  email,
  code,
  pwd,
  accountEndpoint,
}) => {
  let url = `${accountEndpoint}/account/${code}/confirm`;

  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({ email: email, password: pwd }),
  })
    .then(res => res.json())
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
});

export const postAccountResendConfirmationCodeCall = functionWithApiEndpoints(({
  email,
  pwd,
  accountEndpoint,
}) => {
  let url = `${accountEndpoint}/account/please/resend_confirmation_code`;

  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({ email: email, password: pwd }),
  })
    .then(res => res.json())
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
});

export const putAccountSetPasswordCall = functionWithApiEndpoints(({
  user,
  password,
  current_password,
  accountEndpoint,
}) => {
  let url = `${accountEndpoint}/set-account-password`;

  return fetch(url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({
      user: user,
      password: password,
      current_password: current_password,
    }),
  })
    .then(res => res.json())
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
});

/*
  /locations 
*/

export const getAllLocationsCall = functionWithApiEndpoints((params) => {
  const { aqpToken, apiEndpoint } = params;

  const url = `${apiEndpoint}/locations`;
  return fetch(url, apiGetObject(aqpToken))
    .then(res => res.json())
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
});

export const getLocationsCall = functionWithApiEndpoints((params) => {
  const { name, aqpToken, apiEndpoint } = params;
  if (name.length === 1) {
    return [];
  }
  const url = `${apiEndpoint}/locations/${name}`;
  return fetch(url, apiGetObject(aqpToken))
    .then(res => res.json())
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
});

/*
  /route
*/

export const postRouteCall = functionWithApiEndpoints((params) => {
  const {
    id,
    aqpToken,
    apiEndpoint,
    from,
    to,
    optionsObject,
  } = params;

  const path = 'route';
  const url = `${apiEndpoint}/${path}`;
  const body = {
    request: {
      from: {
        lng: normalizeLongitude(from.lng),
        lat: from.lat,
      },
      to: {
        lng: normalizeLongitude(to.lng),
        lat: to.lat,
      },
      options: optionsObject
    }
  };

  const postObject = {
    ...apiPostObject(aqpToken), 
    body: JSON.stringify(body) 
  };
  return fetch(url, postObject)
    .then(res => res.json())
    .then(res => {
      if (res.features) {
        res.features[0].properties['id'] = id;
      }
      return { 
        ...res,
        id: id 
      };
    })
    .catch(reason => ({ status: 'error', reason: reason }));
});

/*
  /validate
*/
export const getValidateCall = functionWithApiEndpoints(({ aqpToken, apiEndpoint, latlng }) => {
  const path = 'validate/' + normalizeLongitude(latlng.lng) + '/' + latlng.lat;
  const url = `${apiEndpoint}/${path}`;
  return fetch(url, apiGetObject(aqpToken))
    .then(res => res.json())
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
});

export const postValidateCall = functionWithApiEndpoints((params) => {
  const { aqpToken, apiEndpoint, latlng } = params;
  const path = 'validate';
  const url = `${apiEndpoint}/${path}`;
  const body = JSON.stringify({
    requests: [
      {
        lng: normalizeLongitude(latlng.lng),
        lat: latlng.lat,
      },
    ]
  });
  const postObject = Object.assign({}, apiPostObject(aqpToken), { body: body });
  return fetch(url, postObject)
    .then(res => res.json())
    .then(res => res[0])
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
});

export const postMultipleValidateCall = functionWithApiEndpoints((params) => {
  const { aqpToken, apiEndpoint, latlngs } = params;
  const path = 'validate';
  const url = `${apiEndpoint}/${path}`;
  const body = JSON.stringify({
    requests: latlngs.map(latlng => ({
      lng: normalizeLongitude(latlng.lng),
      lat: latlng.lat,
    }))
  });
  const postObject = Object.assign({}, apiPostObject(aqpToken), { body: body });
  return fetch(url, postObject)
    .then(res => res.json())
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
});

/*
  /eca
*/

export const getEcaAllCall = functionWithApiEndpoints((params) => {
  const { aqpToken, apiEndpoint } = params;
  const path = 'eca/all';
  const url = `${apiEndpoint}/${path}`;
  return fetch(url, apiGetObject(aqpToken))
    .then(res => res.json())
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
});

/*
  /hra
*/

export const getHraAllCall = functionWithApiEndpoints((params) => {
  const { aqpToken, apiEndpoint } = params;
  const path = 'hra/all';
  const url = `${apiEndpoint}/${path}`;
  return fetch(url, apiGetObject(aqpToken))
    .then(res => res.json())
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
});

/*
  /jwc
*/

export const getJwcAllCall = functionWithApiEndpoints((params) => {
  const { aqpToken, apiEndpoint } = params;
  const path = 'jwc/all';
  const url = `${apiEndpoint}/${path}`;
  return fetch(url, apiGetObject(aqpToken))
    .then(res => res.json())
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
});

/*
  / AIS 
*/

export const refreshAis = functionWithApiEndpoints(({
  aqpToken,
  apiEndpoint,
  imoNumbers
}) => {
  const postObject = Object.assign(
    {}, 
    apiPostObject(aqpToken), 
    { 
      body: JSON.stringify({
        imoNumbers: imoNumbers.map(n => `${n}`)
      })
    }
  );
  const url = `${apiEndpoint}/ais/refresh/imo`;
  return fetch(url, postObject)
    .then(res => res.json())
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
});

export const getLatestAisCall = functionWithApiEndpoints((params) => {
  const { aqpToken, apiEndpoint, imo } = params;
  const path = `vessels/imo/${imo}/latest`;
  const url = `${apiEndpoint}/${path}`;
  return fetch(url, apiGetObject(aqpToken))
    .then(res => res.json())
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
});

/*
  /bunker deviations
*/

export const createBunkerPlan = functionWithApiEndpoints(({
  aqpToken,
  apiEndpoint,
  bunkerPorts,
  baseRequest,
  requestUuid
}) => {
  const postObject = {
    ...apiPostObject(aqpToken),
    body: JSON.stringify({
      request: Object.assign({
        baseRequest: {
          ...baseRequest,
          options: {
            ...baseRequest.options,
            autovalidate: 'true'
          }
        },
        bunkerPorts: bunkerPorts
      }, requestUuid ? { uuid: requestUuid } : {})
    })
  };
  const url = `${apiEndpoint}/bunker/plan/create`;
  return fetch(url, postObject)
    .then(res => res.json())
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
});

/*
  /health
*/

export const accountHealth = functionWithApiEndpoints((params) => {
  const { accountEndpoint } = params;
  const path = 'health';
  const url = `${accountEndpoint}/${path}`;
  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  })
    .then(res => res.json())
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
});

export const apiHealth = functionWithApiEndpoints((params) => {
  const { apiEndpoint } = params;
  const path = 'health';
  const url = `${apiEndpoint}/${path}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then(res => res.json())
    .catch(reason => {
      return { status: 'error', reason: reason };
    });
});
