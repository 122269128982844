import { aquaplotStore } from './../../index';
import {
    FETCH_USER_CARGO_SUCCEEDED,
    FETCH_USER_CARGO_FAILED,
  } from '../../types';

const cargoListener = doc => {
    if (doc.exists) {
        aquaplotStore.dispatch({
            type: FETCH_USER_CARGO_SUCCEEDED,
            payload: doc.data().cargos,
        });
    } else {
        aquaplotStore.dispatch({
            type: FETCH_USER_CARGO_FAILED,
            payload: 'No such document!',
        });
    }
}

const cargoErrorHandler = error => {
    if (process.env.REACT_APP_SHOW_DEBUG === 'true') {
        console.log('Error getting document:', error);
        aquaplotStore.dispatch({
          type: FETCH_USER_CARGO_FAILED,
          payload: `Error getting document: ${error}`,
        });
    }
}

export function* bootstrapCargo(userDoc){
    if (process.env.REACT_APP_SHOW_DEBUG === 'true') console.log('bootstrap cargo')
    yield userDoc.collection('cargos')
    .doc('metadata')
    .onSnapshot(cargoListener, cargoErrorHandler);
}