import React from 'react';
import {
  StyledGrid,
  StyledHeading,
  ContactGridContainer,
  StyledButton,
} from './style';
import { StyledForm } from '../../account/team/CompanyInfo/styledComponents';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Field } from 'redux-form';
import { RenderTextField } from '../FormFields';


const ContactUsPure = props => {
  const { handleSubmit, pristine, submitting, invalid, submitForm } = props;

  
  
  return (
    <ContactGridContainer
      container
      direction="column"
      spacing={6}
      wrap="nowrap"
    >
      <Grid item>
        <StyledHeading variant={'h6'}>Contact Us</StyledHeading>
      </Grid>
      <StyledGrid item>
        <Typography>
          Need help? Contact us through the form below or send us a mail at support@aquaplot.com
        </Typography>
      </StyledGrid>
      <StyledGrid item>
        <StyledForm onSubmit={handleSubmit(values => submitForm(values))}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                autoComplete="subject"
                required
                label="Subject"
                placeholder="Subject"
                type="text"
                name="title"
                component={RenderTextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                autoComplete="message"
                required
                label="Your Message"
                placeholder="Enter Message"
                type="text"
                name="comment"
                component={RenderTextField}
                multiline
                rows={5}
                rowsMax={15}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                disabled={pristine || submitting || invalid}
                type={'submit'}
                variant="contained"
                color="primary"
              >
                Send
              </StyledButton>
            </Grid>
          </Grid>
        </StyledForm>
      </StyledGrid>
    </ContactGridContainer>
  );
};

export default ContactUsPure;
