import styled from 'styled-components';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';


export const StyledExpansionPanel = styled(ExpansionPanel)`
  border: 0;
  box-shadow: none !important;
  background-color: transparent !important;
  &:before {
    background-color: transparent !important;
  }
  > div {
    padding: 0px;
  }
`;

export const StyledPanelDetails = styled(ExpansionPanelDetails)`
  padding: 0px !important;
  font-size: small;
`;