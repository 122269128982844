import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export const RenderCheckbox = ({ 
  input, 
  label, 
  labelstyle, 
  ...other 
}) => (
  <FormControlLabel
    style={labelstyle}
    control={
      <Checkbox
        checked={input.value ? true : false}
        onChange={input.onChange}
        value={input.name}
        {...other}
      />
    }
    label={label}
  />
);
