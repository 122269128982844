import { ReduxAction, SagaReduxAction } from './helper';

export const connectivityLost = new ReduxAction(
    'CONNECTIVITY_LOST',
    null,
    'No connection. Looks like you are offline.'
  );
  export const connectivityEstablished = new ReduxAction(
    'CONNECTIVITY_ESTABLISHED',
    null,
    'Connected again.'
  );
  
  export const toggleDevFromCache = new ReduxAction(
    'SETTINGS_DEV_FROM_CACHE_TOGGLE'
  );
  
  export const accountHealthcheck = new SagaReduxAction('HEALTHCHECK_ACCOUNT');
export const apiHealthcheck = new SagaReduxAction('HEALTHCHECK_API');

export const toggleNavbar = new ReduxAction('TOGGLE_NAVBAR');
  
  export const resetDevSettings = new ReduxAction('DEV_SETTINGS_RESET');
  export const setApiEndpoint = new ReduxAction(
    'DEV_SETTINGS_API_ENDPOINT',
    value => value
  );
  export const setAccountEndpoint = new ReduxAction(
    'DEV_SETTINGS_ACCOUNT_ENDPOINT',
    value => value
  );

  export const switchToMobileView = new ReduxAction('UI_VIEW_MOBILE');
  export const switchToDesktopView = new ReduxAction('UI_VIEW_DESKTOP');

  export const setNavigationLinkPaths = new ReduxAction("NAVIGATION_LINK_PATHS_SET", links => links);

  export const appBootstrapped = new ReduxAction("APP_BOOTSTRAP");