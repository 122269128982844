import {
    compose,
    withProps,
    withHandlers,
    withState
} from 'recompose';

const Handlers = compose(
    withProps(({
        actions,
        mode,
        // modes
    }) => ({
        activeActions: (actions && actions[mode]) || [],
        // showModeTag: modes && modes[mode] && modes[mode].label ,
    })),
    withHandlers({
        getTitle: ({mobileProps: {title}}) => (obj) => title ? title(obj) : obj.title,
        getSubTitle: ({mobileProps: {subtitle}}) => (obj) => subtitle ? subtitle(obj) : obj.subtitle,
    }),
    withState(
        'selected', 
        'setSelected', 
        ({active, getTitle, getSubTitle}) => (active ? ({
            title: getTitle(active),
            subtitle: getSubTitle(active)
        }) : null)
    ),
    // withProps(({
    //     showModeTag,
    //     mode,
    //     modes
    // }) => ({
    //     modeTag: showModeTag ? modes[mode].label : '',
    //     modeColor: showModeTag && modes[mode].color ? modes[mode].color : 'gray'
    // }))
);

export default Handlers;