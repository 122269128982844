import React from 'react';

import { StateTag } from './styledComponents'; 
import CustomAreaAdd from './../CustomAreaButton/StatePicker/Add';
import CustomAreaDelete from './../CustomAreaButton/StatePicker/Delete';
import CustomAreaExit from './../CustomAreaButton/StatePicker/Exit';

const Pure = ({
    showDrawingTools
}) => {
    return (
        <StateTag active={showDrawingTools ? 'true' : 'false'}>
            <div style={{position: 'relative'}}>
                Drawing Mode
                <div style={{position: 'absolute', paddingTop: 12, display: 'flex', left: '50%', transform: 'translate(-50%,0%)'}}>
                    <CustomAreaAdd />
                    <CustomAreaDelete />
                    <CustomAreaExit />
                </div>
            </div>
        </StateTag>
    )
}

export default Pure;