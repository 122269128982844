import {
    compose,
    withState,
    withHandlers,
} from 'recompose';

const Handlers = compose(
    withState('sliderToggle', 'setSliderToggle', false),
    withHandlers({
        toggleSlider: ({
            sliderToggle,
            setSliderToggle
        }) => () => {
            setSliderToggle(!sliderToggle);
        }
    })
);

export default Handlers;