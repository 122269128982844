import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DomainIcon from '@material-ui/icons/Domain';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MapIcon from '@material-ui/icons/Map';
import EmailIcon from '@material-ui/icons/Email';
import ReceiptIcon from '@material-ui/icons/Receipt';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
`;

export const OuterDiv = styled.div`
  display: flex;
  width: 80%;
  margin: 5% auto;
  flex-wrap: wrap;
`;

export const WhyCompanyInfoDiv = styled.div`
  flex: 1 1 50%;
  box-sizing: border-box;
  background: #f7f7f7;
  padding: 20px;
`;

export const ActionsDiv = styled(OuterDiv)`
  justify-content: flex-end;
  padding-bottom: 10px;
`;

export const StyledUl = styled.ul`
  list-style: none;
`;

export const StyledLi = styled.li`
  &::before {
    content: '•';
    color: red; /* or whatever color you prefer */
  }
`;

export const StyledBullet = styled.span`
  width: 9px !important;
  height: 9px !important;
  border: 1px solid #093352;
  border-radius: 9px;
  margin: 0;
  opacity: 0.5;
  background-color: #093352;
`;

export const StyledButton = styled(Button)`
  width: 135px;
`;

export const GridContainer = styled(Grid)`
  margin-bottom: 8% !important;
`;

export const StyledDomainIcon = styled(DomainIcon)`
  color: #9a9a9a;
`

export const StyledMyLocationIcon = styled(MyLocationIcon)`
  color: #9a9a9a;
`

export const StyledLocationOnIcon = styled(LocationOnIcon)`
  color: #9a9a9a;
`

export const StyledMapIcon = styled(MapIcon)`
  color: #9a9a9a;
`

export const StyledEmailIcon = styled(EmailIcon)`
  color: #9a9a9a;
`

export const StyledReceiptIcon = styled(ReceiptIcon)`
  color: #9a9a9a;
`