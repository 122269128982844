import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ToggleOn from '@material-ui/icons/ToggleOn';
import ToggleOff from '@material-ui/icons/ToggleOff';
import Slider from './Slider';
import Explainer from './../../Explainer';

import {
    StyledLabel,
    StyledInput,
    StyledFormHelperText
} from './styledComponents';

const Pure = ({
    label, 
    value, 
    type, 
    unit, 
    placeholder,
    darkmode,
    withSlider,
    margin,
    onChange,
    sliderToggle,
    setSliderToggle,
    toggleSlider,
    onChangeSlider,
    min,
    max,
    step,
    explainer,
    ...restProps
}) =>  {
  return(
      <FormControl margin={margin ? margin : "normal"}>
        <StyledLabel htmlFor={label} darkmode={darkmode ? 'true' : 'false'}>
          {label}
          {explainer ? <Explainer node={explainer} /> : null}
        </StyledLabel>
        <StyledInput
          darkmode={darkmode ? 'true' : 'false'}
          disableUnderline
          placeholder={placeholder}
          id={label}
          type={type && !Array.isArray(value) ? type : 'text'}
          value={Array.isArray(value) 
            ? value.join(' - ')
            : value}
          min={min}
          max={max}
          step={step}
          onChange={onChange}
          endAdornment={ withSlider ?
            <InputAdornment position="end">
              <IconButton aria-label="Toggle password visibility" onClick={toggleSlider}>
                {sliderToggle ? <ToggleOn /> : <ToggleOff />}
              </IconButton>
            </InputAdornment>
            : null
          }
          {...restProps}
        />
        {
            unit ? <StyledFormHelperText darkmode={darkmode ? 'true' : 'false'}>{unit}</StyledFormHelperText> : null
        }
        <Slider
            show={sliderToggle}
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={onChangeSlider}
        />
      </FormControl>
    );
}

export default Pure;