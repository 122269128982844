import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import CloseButton from './CloseButton';
import { 
  StyledDrawer, 
  StyledDrawerContent, 
  StyledTitle 
} from './styledComponents';

const Drawer = ({
  callback,
  destination,
  classes,
  isOpen,
  mobile,
  renderContent,
  renderActions,
  onClose,
  heading,
  padding,
  width,
  top,
  darkmode
}) => {

  return (
    <StyledDrawer
      top={top ? 'true' : 'false'}
      width={width}
      mobile={mobile}
      anchor="right"
      open={isOpen}
      onClose={callback || onClose}
    >
      <div tabIndex={0} role="button" className={classes.drawerContainer}>
        <Toolbar className={classes.drawerHeader} variant="dense">
          <StyledTitle variant="h6" color="inherit">
            {heading}
          </StyledTitle>
          <div>
            {renderActions ? renderActions() : 
              <CloseButton 
                destination={destination}
                onClick={onClose} 
              />}
          </div>
        </Toolbar>
        <StyledDrawerContent darkmode={darkmode ? 'true' : 'false'} padding={padding}>
          {renderContent()}
        </StyledDrawerContent>
      </div>
    </StyledDrawer>)
};

export default Drawer;
